import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getTableConfigurations } from '../../../../redux/actions/table-configurations';

import AdminTablePreferencesTable from '../../../../components/Tables/AdminTablePreferencesTable';

const AdministrationTables = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTableConfigurations());
  }, []);

  return (
    <div className="administration-tables-page">
      <AdminTablePreferencesTable {...props} />
    </div>
  );
};

export default AdministrationTables;
