import { memo } from 'react';

import { Radio } from 'antd';

const PrevNextButtons = ({ className, prevId, nextId, handlePrev, handleNext }) => {
  return (
    <Radio.Group buttonStyle="solid" size="large" className={`"d-flex flex-nowrap" ${className}`} optionType="button">
      <Radio.Button disabled={!prevId} onClick={handlePrev}>
        Prev
      </Radio.Button>
      <Radio.Button disabled={!nextId} onClick={handleNext}>
        Next
      </Radio.Button>
    </Radio.Group>
  );
};

export default memo(PrevNextButtons);
