import { memo } from 'react';
import { Circle, Marker, Polygon, Polyline } from 'react-leaflet';

import dayjs from 'dayjs';
import L from 'leaflet';

import { DATE_FORMAT } from '../../constants/common';
import { GEOMETRY_TYPE } from '../../constants/map';

import MapTrackingFeatureTooltip from './MapTrackingFeatureTooltip';

const IconMarkerDark = new L.Icon({
  iconUrl: require('../../assets/images/marker-pin-dark-small.png'),
  iconRetinaUrl: require('../../assets/images/marker-pin-dark-small.png'),
  iconAnchor: L.Browser.retina ? [10, 10] : [5, 5]
});

const IconMarkerAlert = new L.Icon({
  iconUrl: require('../../assets/images/marker-pin-alert-16px.png'),
  iconRetinaUrl: require('../../assets/images/marker-pin-alert-16px.png'),
  iconAnchor: L.Browser.retina ? [10, 10] : [5, 5]
});

const IconMarkerStart = new L.Icon({
  iconUrl: require('../../assets/images/start-pin.png'),
  iconRetinaUrl: require('../../assets/images/start-pin.png'),
  iconAnchor: L.Browser.retina ? [10, 10] : [5, 5],
  className: 'rg-icon-position'
});

const IconMarkerEnd = new L.Icon({
  iconUrl: require('../../assets/images/end-pin.png'),
  iconRetinaUrl: require('../../assets/images/end-pin.png'),
  iconAnchor: L.Browser.retina ? [10, 10] : [5, 5],
  className: 'rg-icon-position'
});

function MapTrackingFeature({ type, geometry, color, properties, setAnimal }) {
  const tooltipData = [
    { id: 'livestock_id', title: 'Livestock ID', value: properties?.identifier },
    { id: 'eartag_management_id', title: 'Mgmt Tag ID', value: properties?.eartag_management_id },
    {
      id: 'last_reading',
      title: 'Last Reading',
      value: properties?.time ? dayjs(properties.time).format(DATE_FORMAT.DATETIME) : null
    }
  ];

  const circleOptions = {
    color: '#ffffff',
    fillColor: '#000000',
    fillOpacity: 0,
    opacity: 0,
    radius: 1,
    weight: 0.5
  };

  const navigateToAnimalTrackingPage = () => {
    setAnimal(properties.animal_id);
  };

  let LINE;

  if (type === GEOMETRY_TYPE.POLYGON) {
    LINE = <Polygon positions={geometry} color={color} opacity="0.5" fillOpacity="0.5" />;
  }

  if (type === GEOMETRY_TYPE.LINE) {
    LINE = <Polyline color={color} positions={geometry} />;
  }

  if (type === GEOMETRY_TYPE.POINT) {
    if (properties.animal_id) {
      LINE = (
        <Circle {...circleOptions} center={geometry} eventHandlers={{ click: navigateToAnimalTrackingPage }}>
          <Marker
            draggable={false}
            icon={properties.hasAlerts ? IconMarkerAlert : IconMarkerDark}
            position={geometry}
            eventHandlers={{ click: navigateToAnimalTrackingPage }}>
            <MapTrackingFeatureTooltip data={tooltipData} />
          </Marker>
        </Circle>
      );
    } else {
      LINE = (
        <Circle {...circleOptions} center={geometry}>
          <Marker
            icon={properties.hasAlerts ? IconMarkerAlert : IconMarkerDark}
            draggable={false}
            position={geometry}
          />
        </Circle>
      );

      // SHOW START & END ICONS //
      if (properties.customIcon) {
        const isEnd = properties.customIcon === 'end';
        const icon = isEnd ? IconMarkerStart : IconMarkerEnd;
        const zIndex = isEnd ? 1 : 2;

        LINE = (
          <Circle {...circleOptions} center={geometry}>
            <Marker icon={icon} zIndexOffset={zIndex} draggable={false} position={geometry} opacity={0.4} />
            <Marker icon={icon} zIndexOffset={zIndex} draggable={true} position={geometry} />
          </Circle>
        );
      }

      if (properties.last_reading) {
        LINE = (
          <Circle {...circleOptions} center={geometry}>
            <Marker
              icon={properties.hasAlerts ? IconMarkerAlert : IconMarkerDark}
              draggable={false}
              position={geometry}>
              <MapTrackingFeatureTooltip data={tooltipData} />
            </Marker>
          </Circle>
        );
      }
    }
  }

  return LINE;
}

export default memo(MapTrackingFeature);
