import { memo } from 'react';

import { Flex, Radio } from 'antd';

function EditButtons({ handleCancel, handleReturn }) {
  return (
    <Flex className="full-screen-buttons" justify="center">
      <Radio.Group buttonStyle="solid" size="large" optionType="button">
        <Radio.Button className="btn-cancel" onClick={handleCancel}>
          Cancel
        </Radio.Button>
        <Radio.Button onClick={handleReturn}>Return to edit page</Radio.Button>
      </Radio.Group>
    </Flex>
  );
}

export default memo(EditButtons);
