import React, { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Card, Col, Row } from 'reactstrap';

import { Button, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { bulkUpdateTableConfigurations } from '../../../redux/actions/table-configurations';

import AppDropdown from '../../../views/ui-components/AppDropdown';

import AppTable from '../../AppTable';
import { COLUMN_SIZE } from '../../AppTable/constants';
import EditableTableColumnsModal from './EditableTableColumnsModal';

const AdminTablePreferencesTable = () => {
  const dispatch = useDispatch();

  const { configurations, loading } = useSelector(state => state.tableConfigurations);

  const [editableTableIds, setEditableTableIds] = useState([]);
  const [selectedTableIds, setSelectedTableIds] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [editingColumnsTableId, setEditingColumnsTableId] = useState(null);

  const handleInputChange = (id, field, value) => {
    setEditedData(prev => ({
      ...prev,
      [id]: { ...prev[id], [field]: value }
    }));
  };

  const handleSave = () => {
    const updates = editableTableIds.map(id => {
      const tableConfig = configurations.find(table => table.id === id);

      return {
        id,
        title: editedData[id]?.title || tableConfig.title,
        description: editedData[id]?.description || tableConfig.description
      };
    });

    dispatch(bulkUpdateTableConfigurations(updates));

    setEditableTableIds([]);
    setEditedData({});
  };

  const handleCancel = () => {
    setEditableTableIds([]);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (_, table) =>
        editableTableIds.includes(table.id) ? (
          <Input
            value={editedData[table.id]?.title ?? table.title}
            maxLength={255}
            onChange={e => handleInputChange(table.id, 'title', e.target.value)}
          />
        ) : (
          table.title
        )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (_, table) =>
        editableTableIds.includes(table.id) ? (
          <TextArea
            value={editedData[table.id]?.description ?? table.description}
            maxLength={1024}
            autoSize={{ minRows: 3, maxRows: 6 }}
            onChange={e => handleInputChange(table.id, 'description', e.target.value)}
          />
        ) : (
          table.description
        )
    },
    {
      title: 'Columns',
      width: COLUMN_SIZE.XL,
      render: (_, table) => (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation();
            setEditingColumnsTableId(table.id);
          }}>
          Edit columns
        </Button>
      )
    }
  ];

  const actions = useMemo(
    () => [
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!selectedTableIds.length}
            onClick={() => {
              setEditableTableIds([...selectedTableIds]);
            }}>
            Edit Tables
          </Button>
        )
      }
    ],
    [selectedTableIds]
  );

  const onCloseEditColumnsModal = () => {
    setEditingColumnsTableId(null);
  };

  return (
    <Row>
      <Col xs="12" md="12" lg="12">
        <Card>
          <Row className="pad-10">
            <Col xs="12" md="12" lg="6">
              <Row>
                <Col xs="12" md="12" lg="6" className="listing-heading">
                  <h4 className="mb-0">Tables</h4>
                  <Breadcrumb>
                    <BreadcrumbItem>List of tables</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col xs="12" md="12" lg="6"></Col>
              </Row>
            </Col>
            <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
              {editableTableIds.length ? (
                <>
                  <Button type="primary" className="float-right" onClick={handleSave}>
                    Save
                  </Button>
                  <Button variant="outlined" className="ml-2 float-right" onClick={handleCancel}>
                    Cancel
                  </Button>
                </>
              ) : (
                <AppDropdown label="Actions" items={actions} />
              )}
            </Col>
          </Row>

          <Col xs="12" md="12" lg="12" className="allow-overflow">
            <AppTable
              baseColumns={columns}
              headerClass="pb-2"
              dataSource={configurations}
              loading={loading}
              searchable={false}
              settings={false}
              handleOnSelectRecords={setSelectedTableIds}
            />
          </Col>
        </Card>
      </Col>
      <EditableTableColumnsModal tableId={editingColumnsTableId} onClose={onCloseEditColumnsModal} />
    </Row>
  );
};

export default memo(AdminTablePreferencesTable);
