import { Suspense, lazy, memo } from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Tabs } from 'antd';

import { hasPermission } from '../../../helpers/user';

import { PERMISSIONS } from '../../../constants/permissions';
import { FEATURE_LIST } from '../../../constants/subscription';

import FeatureProvider from '../../../components/FeatureProvider';
import LoadingBar from '../../../components/LoadingBar';

const Alerts = lazy(() => import('../../../components/Alerts'));
const BreedingTable = lazy(() => import('../../../components/Breeding'));
const Calendar = lazy(() => import('../../../components/Calendar'));
const MeasurementsTable = lazy(() => import('../../../components/Measurements'));
const Tasks = lazy(() => import('../../../components/Tasks'));
const TreatmentsTable = lazy(() => import('../../../components/Treatments'));

const DEFAULT_TAB = 'calendar';

function AnimalHistory({ animalId, permissions }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get('tab');
  const items = [
    {
      key: 'calendar',
      label: 'Calendar',
      children: (
        <Suspense fallback={<LoadingBar />}>
          <Calendar
            step={60}
            queryParameters={{
              relationId: animalId,
              relationType: 'livestock',
              withDetails: true
            }}
          />
        </Suspense>
      )
    },
    {
      key: 'tasks',
      label: 'Tasks',
      permissions: [PERMISSIONS.TASKS.VIEW],
      children: (
        <Suspense fallback={<LoadingBar />}>
          <Tasks
            settings={true}
            queryParameters={{
              relationId: animalId,
              relationType: 'livestock',
              withDetails: true
            }}
          />
        </Suspense>
      )
    },
    {
      key: 'measures',
      label: 'Measures',
      permissions: [PERMISSIONS.LIVESTOCK_MEASURES.VIEW_OWN],
      children: (
        <Suspense fallback={<LoadingBar />}>
          <FeatureProvider name={FEATURE_LIST.MEASURES_TAB}>
            <MeasurementsTable searchable={false} />
          </FeatureProvider>
        </Suspense>
      )
    },
    {
      key: 'breeding',
      label: 'Breeding',
      permissions: [PERMISSIONS.LIVESTOCK_BREEDING.VIEW_OWN],
      children: (
        <Suspense fallback={<LoadingBar />}>
          <FeatureProvider name={FEATURE_LIST.BREEDING_TAB}>
            <BreedingTable searchable={false} />
          </FeatureProvider>
        </Suspense>
      )
    },
    {
      key: 'treatments',
      label: 'Treatments',
      permissions: [PERMISSIONS.LIVESTOCK_TREATMENT.VIEW_OWN],
      children: (
        <Suspense fallback={<LoadingBar />}>
          <FeatureProvider name={FEATURE_LIST.TREATMENT_TAB}>
            <TreatmentsTable searchable={false} />
          </FeatureProvider>
        </Suspense>
      )
    },
    {
      key: 'notifications',
      label: 'Notifications',
      permissions: [PERMISSIONS.NOTIFICATIONS.VIEW_OWN],
      children: (
        <Suspense fallback={<LoadingBar />}>
          <FeatureProvider name={FEATURE_LIST.NOTIFICATION_LIST}>
            <Alerts type="full" columnType={2} query={{ animal_ids: [animalId] }} />
          </FeatureProvider>
        </Suspense>
      )
    }
  ];

  const allowedItems = items.filter(item => (item.permissions ? hasPermission(item.permissions, permissions) : item));

  return (
    <Card title="Livestock History">
      <Tabs defaultActiveKey={tab || DEFAULT_TAB} items={allowedItems} />
    </Card>
  );
}

export default memo(AnimalHistory);
