import React, { memo, useEffect, useState } from 'react';
import { GetCity, GetCountries, GetState } from 'react-country-state-city';

import { Form, Select } from 'antd';

function CountryCitySelect({ defaultCountry, defaultCity, handleChangeCity }) {
  const [form] = Form.useForm();

  const [cities, setCities] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetCountries().then(result => {
      const countriesOptions = result.map(country => ({ label: country.name, value: country.id }));
      setCountries(countriesOptions);

      if (defaultCountry) {
        const country = countriesOptions.find(country => country.label === defaultCountry);
        form.setFieldValue('country', country);

        handleChangeCountry(country.value);
      }
    });
  }, []);

  const handleChangeCountry = async countryId => {
    setLoading(true);

    try {
      const states = await GetState(parseInt(countryId));
      const statesWithCities = states.filter(state => state.hasCities === true);

      if (statesWithCities.length === 0) {
        setCities([]);
        setCityOptions([]);
        return;
      }

      const cityPromises = statesWithCities.map(state => GetCity(parseInt(countryId), parseInt(state.id)));
      const results = await Promise.allSettled(cityPromises);
      const allCities = results
        .filter(result => result.status === 'fulfilled' && Array.isArray(result.value))
        .flatMap(result => result.value);
      const sortedCities = allCities.sort((a, b) => a.name.localeCompare(b.name));

      if (defaultCity) {
        const city = sortedCities.find(city => city.name === defaultCity);

        if (city) form.setFieldValue('city', { label: city.name, value: city.id });
      }

      setCities(sortedCities);
      setCityOptions(sortedCities.map(city => ({ value: city.name, label: city.name })));
    } catch (error) {
      console.error('Error fetching cities:', error);
    }

    setLoading(false);
  };

  const handleChange = cityName => {
    const city = cities.find(city => city.name === cityName);
    handleChangeCity(city);
  };

  return (
    <Form
      labelWrap
      form={form}
      className="farm-search-form"
      layout="horizontal"
      name="address-serch"
      labelCol={{ span: 3 }}>
      <Form.Item name="country" labelAlign="left" label="Country" colon={false}>
        <Select
          showSearch
          optionFilterProp="label"
          disabled={loading}
          placeholder="Select country"
          options={countries}
          onChange={handleChangeCountry}
        />
      </Form.Item>

      <Form.Item name="city" labelAlign="left" label="Closest town or city" colon={false}>
        <Select
          showSearch
          optionFilterProp="label"
          disabled={loading || cities?.length < 0}
          loading={loading}
          placeholder="Select city"
          options={cityOptions}
          onChange={handleChange}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </Form>
  );
}

export default memo(CountryCitySelect);
