import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Button, Flex, Typography } from 'antd';
import isEmpty from 'lodash.isempty';

import axios from '../../../axios';

import messages from '../../../constants/messages';

import { fetchFarm } from '../../../redux/actions/farm';
import { deleteGeofence, fetchGeofence, updateGeofence } from '../../../redux/actions/geofence';
import { fetchLabels } from '../../../redux/actions/label';

import AppModal from '../../../components/AppModal';
import CancelSaveButtons from '../../../components/CancelSaveButtons';
import GeofenceEditForm from '../../../components/Geofence/GeofenceEditForm';
import LoadingBar from '../../../components/LoadingBar';
import MapGeofence from '../../../components/Maps/MapGeofence';
import { DEFAULT_SHAPE_COLOR, TYPE } from '../../../components/Maps/constants';
import ChooseGroupsModal from '../../../components/Modals/ChooseGroupsModal';
import PrevNextButtons from '../../../components/PrevNextButtons';
import { errorToastHandler, successToastHandler, warnToastHandler } from '../../../components/action_notifier';

import AppBreadcrumb from '../../ui-components/AppBreadcrumb';
import AppDropdown from '../../ui-components/AppDropdown';

import GeofenceDetails from './GeofenceDetails';
import GeofenceHistory from './GeofenceHistory';

const { Title } = Typography;

export default function Geofence({ history }) {
  const { id } = useParams();
  const geofenceId = +id;
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [submittable, setSubmittable] = useState(false);
  const { geofence, loading } = useSelector(state => state.geofence);
  const { data: labels } = useSelector(state => state.label);
  const { farm } = useSelector(state => state.farm);
  const [state, setState] = useState({
    actions: [],
    chooseGroupModal: false,
    color: DEFAULT_SHAPE_COLOR,
    delete_modal: false,
    editing: false,
    farm: {},
    geofence: {
      id: null,
      farm_id: null,
      name: '',
      size: 0,
      animals_count: 0,
      description: '',
      circumference: 0,
      days_grazed: 0,
      position: [24.984627, 31.717946],
      geo_json: {}
    },
    geofence_edit: {
      id: null,
      farm_id: null,
      name: '',
      size: 0,
      animals_count: 0,
      description: '',
      circumference: 0,
      days_grazed: 0,
      position: [24.984627, 31.717946],
      geo_json: {}
    },
    groupOptions: [],
    isOpenConfirmationModal: false,
    savedGroups: [],
    selectedGroups: [],
    selected_geofences: [],
    isNew: false
  });

  useEffect(() => {
    getGeofence();
    getLabels();
    initActions();
  }, []);

  useEffect(() => {
    getGeofence();
    getLabels();
  }, [geofenceId]);

  useEffect(() => {
    if (geofence) {
      initActions();
      getFarm();
      setState(prevState => ({
        ...prevState,
        color: geofence.color,
        geofence: structuredClone(geofence),
        geofence_edit: structuredClone(geofence),
        savedGroups: geofence?.labels?.length ? geofence?.labels?.map(l => ({ label: l?.name, value: l?.id })) : null
      }));
    }
  }, [geofence]);

  useEffect(() => {
    if (farm && geofence) {
      setState(prevState => ({
        ...prevState,
        color: geofence.color,
        geofence: {
          ...geofence,
          features: farm.geofences,
          sites: farm.sites
        },
        geofence_edit: {
          ...geofence,
          features: farm.geofences,
          sites: farm.sites
        },
        farm: structuredClone(farm),
        selectedGroups: geofence?.labels?.length
          ? geofence?.labels?.map(l => ({ label: l?.name, value: l?.id }))
          : null,
        savedGroups: geofence?.labels?.length ? geofence?.labels?.map(l => ({ label: l?.name, value: l?.id })) : null
      }));
    }
  }, [farm]);

  useEffect(() => {
    if (labels) {
      setState(prevState => ({
        ...prevState,
        groupOptions: labels
          .filter(label => label.farm?.id === state.geofence.farm_id)
          .map(x => ({ value: x.id, label: x.name, color: x.colour }))
      }));
    }
  }, [labels]);

  const changeColor = useCallback(
    color => {
      setState(prevState => ({ ...prevState, color }));
    },
    [state.color]
  );

  const toggleGroupModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      selectedGroups: state.savedGroups,
      chooseGroupModal: !prevState.chooseGroupModal
    }));
  }, [state.chooseGroupModal]);

  const toggleDeleteModal = useCallback(() => {
    setState(prevState => ({ ...prevState, delete_modal: !prevState.delete_modal }));
  }, [state.delete_modal]);

  const toggleEditMode = useCallback(() => {
    setState(prevState => ({ ...prevState, editing: !prevState.editing }));
  }, [state.editing]);

  const toggleConfirmationModal = useCallback(() => {
    setState(prevState => ({ ...prevState, isOpenConfirmationModal: !prevState.isOpenConfirmationModal }));
  }, [state.isOpenConfirmationModal]);

  const navigateToThePrevPage = useCallback(() => {
    history.push(`/geofence/${state.geofence.prev_id}`);
  }, [state.geofence.prev_id]);

  const navigateToTheNextPage = useCallback(() => {
    history.push(`/geofence/${state.geofence.next_id}`);
  }, [state.geofence.next_id]);

  const handleGeofenceGroupUpdate = useCallback(() => {
    updateGeofenceGroup();
    toggleConfirmationModal();
  }, [state.selectedGroups]);

  const handleGeofenceGeoJsonChange = useCallback(
    geo_json => {
      setState(prevState => ({ ...prevState, geofence_edit: { ...prevState.geofence_edit, geo_json } }));
    },
    [state.geofence_edit.geo_json]
  );

  const deleteGeoJson = () => {
    setState(prevState => ({ ...prevState, geofence_edit: { ...prevState.geofence_edit, geo_json: {} } }));
  };

  async function getFarm() {
    try {
      await dispatch(fetchFarm(geofence?.farm_id)).unwrap();
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
    }
  }

  async function getLabels() {
    try {
      await dispatch(fetchLabels()).unwrap();
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
    }
  }

  async function getGeofence() {
    try {
      await dispatch(fetchGeofence(geofenceId)).unwrap();
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
    }
  }

  async function deleteCurrentGeofence() {
    try {
      await dispatch(deleteGeofence(geofenceId)).unwrap();
      successToastHandler(messages.ARCHIVED);
      history.push('/geofences');
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_DELETE_DATA);
    }
  }

  async function updateCurrentGeofence() {
    const geofenceNewValues = await formRef.current?.validateFields();
    const name = geofenceNewValues?.name;
    const description = geofenceNewValues?.description;

    // EVENT TRIGGER FOR MAP 'SAVE' ACTION //
    // if (document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top')) {
    //   document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top li a').click();
    // }
    //
    // // WAIT FOR 200 MILISECONDS TO GET DATA //
    // await setTimeout(() => {}, 200);

    try {
      if (!geofenceId) return;

      const updatedGeofence = state.isNew ? state.geofence : { ...state.geofence_edit, name, description };

      if (isEmpty(updatedGeofence.geo_json)) {
        warnToastHandler(messages.DATA_REQUIRED);

        return;
      }

      await dispatch(updateGeofence({ id: geofenceId, data: { ...updatedGeofence, color: state.color } })).unwrap();

      setState(prevState => ({ ...prevState, editing: false }));
      successToastHandler(messages.UPDATED);
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_UPDATE_DATA);
    }
  }

  async function updateGeofenceGroup() {
    const groupIds = state.selectedGroups.map(g => g.value);

    if (groupIds?.length) {
      try {
        await axios.put('geofences/updateGroupGeofence/' + geofenceId, { group_ids: groupIds });
        getGeofence();
      } catch (error) {
        console.error(error);
        errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_UPDATE_DATA);
      }
    }

    toggleGroupModal();
  }

  function initActions() {
    setState(prevState => ({
      ...prevState,
      actions: [
        {
          label: (
            <Button color="default" size="small" variant="link" onClick={toggleEditMode}>
              Edit geofence
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={geofence?.is_master}
              onClick={toggleGroupModal}>
              Edit groups in geofence
            </Button>
          )
        },
        {
          label: (
            <Button color="default" size="small" variant="link" onClick={toggleDeleteModal}>
              Archive geofence
            </Button>
          )
        }
      ]
    }));
  }

  const onSelectGroup = value => {
    setState(prevState => ({ ...prevState, selectedGroups: [...value] }));
  };

  const setGeofence = value => {
    setState(prevState => ({
      ...prevState,
      geofence: {
        ...prevState.geofence,
        geo_json: value
      },
      isNew: true
    }));
  };

  const breadcrumbItems = [
    {
      title: <Link to="/maps?tab=geofence">List of Geofences</Link>
    },
    {
      title: `${state.editing ? 'Edit ' : ''}Geofence ${state.geofence.name}`
    }
  ];

  return (
    <>
      {loading ? (
        <LoadingBar />
      ) : (
        <>
          <Flex gap="small" vertical={false} justify="space-between" wrap>
            <Flex vertical={true}>
              <Title level={4}>
                {state.editing && 'Edit '}Geofence - {state.geofence.name}
              </Title>

              <AppBreadcrumb items={breadcrumbItems} />
            </Flex>
            <Flex gap="small">
              {!state.editing && <AppDropdown label="Actions" items={state.actions} />}
              {state.editing && (
                <CancelSaveButtons
                  disabled={!submittable}
                  handleCancel={toggleEditMode}
                  handleSave={updateCurrentGeofence}
                />
              )}

              <PrevNextButtons
                nextId={state.geofence.next_id}
                prevId={state.geofence.prev_id}
                handlePrev={navigateToThePrevPage}
                handleNext={navigateToTheNextPage}
              />
            </Flex>
          </Flex>

          <MapGeofence
            color={state.color}
            editing={state.editing}
            geofence={state.editing ? state.geofence_edit : state.geofence}
            isNew={false}
            type={TYPE.GEOFENCE}
            handleDelete={deleteGeoJson}
            onEdit={handleGeofenceGeoJsonChange}
            handleCreate={setGeofence}
            handleCancelEditing={toggleEditMode}
            handleSaveEditing={updateCurrentGeofence}
          />

          {state.editing && (
            <GeofenceEditForm
              circumference={state.geofence?.circumference}
              color={state.color}
              defaultName={state.geofence_edit?.name}
              description={state.geofence_edit?.description}
              farmId={state.geofence?.farm?.name}
              formRef={formRef}
              isMaster={state.geofence?.is_master}
              name={state.geofence?.name}
              size={state.geofence?.size}
              handleColorChange={changeColor}
              setSubmittable={setSubmittable}
            />
          )}

          {!state.editing && (
            <GeofenceDetails geofence={state.geofence} description={state.geofence_edit.description} />
          )}

          {state.editing && (
            <Flex justify="end" className="mb-2">
              <CancelSaveButtons
                disabled={!submittable}
                handleCancel={toggleEditMode}
                handleSave={updateCurrentGeofence}
              />
            </Flex>
          )}

          <GeofenceHistory geofenceId={geofenceId} />

          <ChooseGroupsModal
            isOpen={state.chooseGroupModal}
            options={state.groupOptions}
            values={state.selectedGroups}
            handleGroupsChange={onSelectGroup}
            onCancel={toggleGroupModal}
            onSave={toggleConfirmationModal}
          />

          <AppModal
            isOpen={state.delete_modal}
            confirmButtonColor="danger"
            confirmButtonText="Archive"
            title="Archive geofence"
            handleCancel={toggleDeleteModal}
            handleConfirm={deleteCurrentGeofence}>
            <div className="py-4">Are you sure you want to archive this geofence? This action cannot be undone.</div>
          </AppModal>

          <AppModal
            isOpen={state.isOpenConfirmationModal}
            confirmButtonText="Yes"
            handleCancel={toggleConfirmationModal}
            handleConfirm={handleGeofenceGroupUpdate}>
            <div className="py-4">
              One or more groups are already assigned to a different geofence. Do you want to update the geofence for
              these groups?
            </div>
          </AppModal>
        </>
      )}
    </>
  );
}
