import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Flex, Typography } from 'antd';
import moment from 'moment';

import axios from '../../axios';

import messages from '../../constants/messages';

import AppModal from '../../components/AppModal';
import CancelSaveButtons from '../../components/CancelSaveButtons';
import { errorToastHandler } from '../../components/action_notifier';
import AnimalCard from '../../components/animals/animalCard';

import AppBreadcrumb from '../ui-components/AppBreadcrumb';

const { Title } = Typography;

class LivestockNew extends React.Component {
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      cancel_modal: false,
      animal: {},
      animal_edit: {},
      specie_opts: [
        { value: 'Bovine', label: 'Bovine' },
        { value: 'Ovine', label: 'Ovine' },
        { value: 'Caprine', label: 'Caprine' }
      ], // TODO: load list of species from api
      // TODO: load list of breeds from api
      sex_opts: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' }
      ],
      breed_opts: [],
      farm_opts: [],
      farms: [],
      selected_sex: [],
      selected_farm: [],
      selected_specie: [],
      selected_breed: [],
      selected_geofences: [],
      dropdownOpen: false,
      activeTab: '1',
      selected_labels: [],
      labels_opts: [],
      type_opts: [],
      selected_type: {},
      geofence_opts: []
    };
    this.onChange = this.onChange.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getLabels = this.getLabels.bind(this);
    this.handleFenceChange = this.handleFenceChange.bind(this);
    this.onLivestockChangeDate = this.onLivestockChangeDate.bind(this);

    this.child = React.createRef();
  }
  async getLabels() {
    let response = await axios.get('labels');
    if (response.status === 200) {
      this.setState({
        label_opts: response.data.map(x => {
          return { value: x.id, label: x.name, color: x.colour };
        })
      });
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  onChange = (value, field) => {
    this.setState(state => ({
      ...state,
      [field]: value
    }));
  };
  componentDidMount() {
    this.getBreeds();
    this.getTypes();
    this.getFarms();
  }
  capitalize(string) {
    if (string === null) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  toggleModal(modal) {
    // RG - OVERWRITE THE 'animal_edit' VARIABLE //
    let livestockData = this.child.current.getLivestockData();
    this.clean(livestockData);
    if (this.isEmpty(livestockData)) {
      this.props.history.push(`/animals`);
    } else {
      this.setState(state => ({
        ...state,
        [modal]: !state[modal]
      }));
    }
  }
  async addLivestock() {
    try {
      let livestockData = this.child.current.getLivestockData();
      if (livestockData.breed && livestockData.breed.id) {
        livestockData.breed = livestockData.breed.display_name;
      }
      livestockData.geofence_ids = livestockData.geofences
        ? livestockData.geofences.map(x => {
            return x.id;
          })
        : '';
      livestockData.label_ids = livestockData.labels
        ? livestockData.labels.map(x => {
            return x.id;
          })
        : [];
      livestockData.group_ids = livestockData.label_ids ? livestockData.label_ids : [];
      livestockData.sire_id = Number(livestockData.sire_id);
      livestockData.dam_id = Number(livestockData.dam_id);
      livestockData.photo = null;
      if (livestockData.new_photo) {
        livestockData.photo = livestockData.new_photo;
      }
      if (
        !livestockData.eartag_management_id ||
        livestockData.eartag_management_id === '' ||
        !livestockData.farm_id ||
        livestockData.farm_id === '' ||
        !livestockData.specie ||
        livestockData.specie === '' ||
        !livestockData.sex ||
        livestockData.sex === ''
      ) {
        errorToastHandler(messages.FILL_MANDATORY_FIELDS);
        return false;
      } else {
        let response = await axios.post('animals', livestockData);
        if (response.status === 200 && response.data.id) {
          this.props.history.push('/animal/' + response.data.id);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getFarms() {
    let response = await axios.get('farms?with_details=true', {
      query: this.state.query || null
    });
    if (response.status === 200) {
      let mapped_farms = [];
      for (let farm of response.data) {
        mapped_farms.push({
          value: farm.id,
          label: farm.name,
          meta: {
            features: farm.geofences
          }
        });
      }
      this.setState(
        {
          farms: response.data,
          farm_opts: mapped_farms
        },
        e => this.getLabels()
      );
    }
  }

  onEditAnimalChange = (value, field) => {
    let nt = this.state.animal_edit;
    if (field === 'dob_at') {
      value = moment(value).format();
    }
    nt[field] = value;
    this.setState(state => ({
      ...state,
      animal_edit: nt
    }));
  };
  async onEditAnimalFileChange(e) {
    let photo = await this.getBase64(e.target.files[0]);
    this.setState({
      animal_edit: {
        ...this.state.animal_edit,
        photo: photo
      }
    });
  }
  _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      let document = '';
      let reader = new FileReader();
      document = reader.readAsArrayBuffer(file);
      reader.onload = function () {
        document = reader.result;
        let photo = `data:${file.type}; base64, ${this._arrayBufferToBase64(reader.result)}`;
        resolve(photo);
      }.bind(this);
      reader.onerror = function (error) {};
      return document;
    });
  }
  async getBreeds() {
    let response = await axios.get('breeds');
    if (response.status === 200) {
      this.setState({
        breed_opts: response.data.map(x => {
          return { value: x.code, label: x.display_name };
        })
      });
    }
  }
  async getFences() {
    let response = await axios.get('geofences');
    if (response.status === 200) {
      this.setState({
        geofence_opts: response.data.map(x => {
          return { value: x.id, label: x.name };
        })
      });
    }
  }
  async getTypes() {
    let response = await axios.get('/animals/stock-types');
    if (response.status === 200) {
      this.setState({
        type_opts: response.data
      });
    }
  }
  handleSpecieChange = selectedOption => {
    this.setState({
      selected_specie: selectedOption,
      new_animal: { ...this.state.new_animal, specie: selectedOption.value },
      animal_edit: { ...this.state.animal_edit, specie: selectedOption.value }
    });
  };
  handleSexChange = selectedOption => {
    this.setState({
      selected_sex: selectedOption,
      new_animal: { ...this.state.new_animal, sex: selectedOption.value },
      animal_edit: { ...this.state.animal_edit, sex: selectedOption.value }
    });
  };
  handleBreedChange = selectedOption => {
    this.setState({
      selected_breed: selectedOption,
      new_animal: { ...this.state.new_animal, breed: selectedOption.value }
    });
  };
  handleFarmChange = selectedOption => {
    this.setState({
      selected_farm: selectedOption,
      selected_geofences: [],
      new_animal: { ...this.state.new_animal, farm_id: selectedOption.value },
      animal_edit: { ...this.state.animal_edit, farm_id: selectedOption.value }
    });
  };
  handleLabelChange = selectedOption => {
    this.setState({
      selected_labels: selectedOption,
      new_animal: {
        ...this.state.new_animal,
        label_ids: selectedOption.map(x => x.value)
      },
      animal_edit: {
        ...this.state.animal_edit,
        label_ids: selectedOption.map(x => x.value)
      }
    });
  };
  handleFenceChange = selectedOption => {
    this.setState({
      selected_geofences: selectedOption,
      new_animal: {
        ...this.state.new_animal,
        geofence_ids: selectedOption.value
      },
      animal_edit: {
        ...this.state.animal_edit,
        geofence_ids: selectedOption.value
      }
    });
  };
  handleTypeChange = selectedOption => {
    this.setState({
      selected_type: selectedOption,
      new_animal: {
        ...this.state.new_animal,
        stock_type: selectedOption.value
      },
      animal_edit: {
        ...this.state.animal_edit,
        stock_type: selectedOption.value
      }
    });
  };
  onLivestockChangeDate(val, vl) {
    this.setState(state => ({
      animal: {
        ...state.animal,
        dob_at: moment(val, 'MMMM d, yyyy h:mm aa').format(),
        dob_at_holder: new Date(val)
      },
      animal_edit: {
        ...state.animal_edit,
        dob_at: moment(val, 'MMMM d, yyyy h:mm aa').format(),
        dob_at_holder: new Date(val)
      }
    }));
  }

  toggleButton() {
    this.setState(state => ({
      dropdownOpen: !state.dropdownOpen
    }));
  }

  render() {
    const breadcrumbItems = [
      {
        title: <Link to="/animals">List of Livestock</Link>
      },
      {
        title: 'Create New Livestock'
      }
    ];

    return (
      <>
        <Flex gap="small" vertical={false} justify="space-between" wrap>
          <Flex vertical={true}>
            <Title level={4}>Livestock {this.state.animal.identifier || this.state.animal.eartag_official_id}</Title>

            <AppBreadcrumb items={breadcrumbItems} />
          </Flex>
          <Flex>
            <CancelSaveButtons handleCancel={() => this.toggleModal('cancel_modal')} handleSave={this.addLivestock} />
          </Flex>
        </Flex>

        <AnimalCard
          subscription={this.props.subscription}
          animal={this.state.animal_edit}
          history={this.props.history}
          onChange={this.onChange}
          toggleModal={this.toggleModal}
          editMode={true}
          ref={this.child}
          actionType={this.props.match.params.type}
          page={'newAnimal'}
        />

        <Flex justify="end" className="mb-2">
          <CancelSaveButtons handleCancel={() => this.toggleModal('cancel_modal')} handleSave={this.addLivestock} />
        </Flex>

        <AppModal
          isOpen={this.state.cancel_modal}
          confirmButtonColor="danger"
          confirmButtonText="Discard"
          title=""
          handleCancel={() => this.toggleModal('cancel_modal')}
          handleConfirm={() => this.props.history.push('/animals')}>
          <div className="py-4">You have unsaved changes. Are you sure you want to discard these changes?</div>
        </AppModal>
      </>
    );
  }
}
export default connect(state => state)(LivestockNew);
