import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from 'antd';

import { sortNumbers, sortStrings } from '../../helpers/filter';

import { BLANK_FILTER_TEXT } from '../../constants/livestock';

import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';

import RulePriority from './RulePriority';

const { Title } = Typography;

const rulePriorityIndex = {
  high: 2,
  medium: 1,
  low: 0,
  priority: 2,
  normal: 1,
  record: 0
};

const commonColumnProperties = {
  ellipsis: true,
  sortDirections: ['ascend', 'descend']
};

function TopRules({ rules, isRulesLoading, rulePrioritiesFilters, ruleStatusesFilters, handleRuleSnooze }) {
  const rulesColumns = useMemo(
    () => [
      {
        ...commonColumnProperties,
        title: 'Rule ID',
        dataIndex: 'rulenamId',
        width: COLUMN_SIZE.MD,
        render: (value, record) => <Link to={`rule/${record.id}`}>{record.identifier}</Link>,
        sorter: (a, b) => sortStrings(a.identifier, b.identifier)
      },
      {
        ...commonColumnProperties,
        title: 'Notification Count',
        dataIndex: 'triggers_count',
        width: COLUMN_SIZE.MD,
        sorter: (a, b) => sortNumbers(a.triggers_count, b.triggers_count)
      },
      {
        ...commonColumnProperties,
        title: 'Rule Priority',
        dataIndex: 'rpriority',
        filters: rulePrioritiesFilters,
        width: COLUMN_SIZE.MD,
        onFilter: (value, record) =>
          value === BLANK_FILTER_TEXT ? !record?.data.action.value : record?.data.action.value === value,
        render: (value, record) => <RulePriority priority={record.data.action.value || ''}></RulePriority>,
        sorter: (a, b) => sortNumbers(rulePriorityIndex[a.data.action.value], rulePriorityIndex[b.data.action.value])
      },
      {
        ...commonColumnProperties,
        title: 'Rule Status',
        dataIndex: 'rulestatusid',
        filters: ruleStatusesFilters,
        width: COLUMN_SIZE.LG,
        onFilter: (value, record) =>
          value === BLANK_FILTER_TEXT ? !record?.snooze_status : record?.snooze_status === value,
        render: (value, record) => (record.is_snoozed ? 'Snoozed' : 'Active'),
        sorter: (a, b) => {
          const strA = a.is_snoozed ? 'Snoozed' : 'Active';
          const strB = b.is_snoozed ? 'Snoozed' : 'Active';

          return sortStrings(strA, strB);
        }
      },
      {
        title: 'Snooze',
        dataIndex: 'actions',
        align: 'center',
        ellipsis: true,
        fixed: 'right',
        noPadding: true,
        width: COLUMN_SIZE.XS,
        render: (value, record) => (
          <i
            style={{ cursor: 'pointer' }}
            onClick={() => handleRuleSnooze(record.id, record.is_snoozed ? 'snoozed' : 'snoozable')}
            className={`rule_not-action mdi mdi-alarm ${record.is_snoozed ? 'snoozed' : 'snoozable'}`}></i>
        )
      }
    ],
    [rules]
  );

  return (
    <>
      <div className="heading-with-icons">
        <Title level={4}>Top 5 Rules</Title>
        <div className="close-full-btns">
          <i className="fa fa-window-maximize" aria-hidden="true"></i>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>

      <div className="mx-2">
        <AppTable
          headerClass="py-2"
          baseColumns={rulesColumns}
          dataSource={rules}
          loading={isRulesLoading}
          pageSize={10}
          rowsSelectable={false}
          searchable={false}
          settings={false}
        />
      </div>
    </>
  );
}

export default memo(TopRules);
