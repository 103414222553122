import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Button, Flex, Typography } from 'antd';
import isEmpty from 'lodash.isempty';

import { hasPermission } from '../../../helpers/user';

import messages from '../../../constants/messages';
import { PERMISSIONS } from '../../../constants/permissions';
import { PLANS_TYPE } from '../../../constants/subscription';

import { deleteFarm, fetchFarm, updateFarm } from '../../../redux/actions/farm';

import AppModal from '../../../components/AppModal';
import CancelSaveButtons from '../../../components/CancelSaveButtons';
import LoadingBar from '../../../components/LoadingBar';
import Map from '../../../components/Maps/Map';
import { DEFAULT_SHAPE_COLOR, TYPE } from '../../../components/Maps/constants';
import PrevNextButtons from '../../../components/PrevNextButtons';
import { errorToastHandler, successToastHandler, warnToastHandler } from '../../../components/action_notifier';

import AppBreadcrumb from '../../ui-components/AppBreadcrumb';
import AppDropdown from '../../ui-components/AppDropdown';

import FarmEditForm from './FarmEditForm';
import FarmHistory from './FarmHistory';
import './styles.css';

const { Title } = Typography;

export default function Farm(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const formRef = useRef(null);
  const subscription = useSelector(state => state.subscription);
  const { farm, loading } = useSelector(state => state.farm);
  const user = useSelector(state => state.user);
  const [submittable, setSubmittable] = useState(false);
  const [state, setState] = useState({
    actions: [],
    activeTab: '1',
    color: DEFAULT_SHAPE_COLOR,
    delete_modal: false,
    editing: false,
    farm: {
      // days_grazed: "",
      address: '',
      animals_count: '',
      circumference: '',
      description: '',
      full_address: '',
      geofence_id: '',
      geofences: [],
      id: null,
      name: '',
      rules: [],
      size: ''
    },
    farmId: null,
    farm_edit: {
      // days_grazed: "",
      address: '',
      animals_count: '',
      circumference: '',
      description: '',
      full_address: '',
      geofence_id: '',
      geofences: [],
      id: null,
      name: '',
      rules: [],
      size: ''
    },
    selected_geofences: []
  });

  useEffect(() => {
    getFarm();
    initActions();
  }, []);

  useEffect(() => {
    if (farm) {
      const masterGeofence = farm?.geofences?.find(item => item.is_master);

      setState(prevState => ({
        ...prevState,
        farmId: farm?.id,
        color: masterGeofence?.color,
        farm: structuredClone(farm),
        farm_edit: structuredClone(farm),
        selected_geofences: farm.geofences
          .filter(gf => !gf.is_master)
          .map(gf => ({ value: gf.id, label: gf.name, geofence: gf }))
      }));
    }
  }, [farm]);

  const toggleModal = useCallback(
    modal => {
      setState(prevState => ({ ...prevState, [modal]: !prevState[modal] }));
    },
    [state.delete_modal]
  );

  const navigateToThePrevPage = useCallback(() => {
    props.history.push(`/farm/${state.farm?.prev_id}`);
  }, [state.farm?.prev_id]);

  const navigateToTheNextPage = useCallback(() => {
    props.history.push(`/farm/${state.farm?.next_id}`);
  }, [state.farm?.next_id]);

  const navigateToTrackingPage = useCallback(() => {
    props.history.push(`/tracking/${state.farm?.animals?.at(0)?.id}`);
  }, [state.farm?.animals]);

  const changeColor = useCallback(
    color => {
      setState(prevState => ({ ...prevState, color }));
    },
    [state.color]
  );

  async function getFarm() {
    if (params?.id) {
      try {
        await dispatch(fetchFarm(+params.id)).unwrap();
      } catch (error) {
        errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
      }
    }
  }

  async function saveFarm() {
    const values = await formRef.current?.validateFields();
    const address = {
      street: values.street,
      city: values.city,
      country: values.country,
      house_number: values.house_number,
      postal_code: values.postal_code
    };
    const farmId = +params.id;
    const farm = { ...state.farm_edit, ...values, address };

    // EVENT TRIGGER FOR MAP 'SAVE' ACTION //
    // if (document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top')) {
    //   document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top li a').click();
    // }
    //
    // // WAIT FOR 200 MILISECONDS TO GET DATA //
    // await setTimeout(() => {}, 300);

    try {
      if (isEmpty(farm.master_geofence?.geo_json)) {
        warnToastHandler(messages.DATA_REQUIRED);

        return;
      }

      await dispatch(
        updateFarm({
          id: farmId,
          data: {
            id: farm.id,
            address: farm.address,
            name: farm.name,
            description: farm.description,
            color: state.color,
            herd_numbers: farm.herd_number ? [farm.herd_number] : [],
            master_geofence: farm.master_geofence?.geo_json,
            geofence_ids: state.selected_geofences.map(sg => sg.value).filter(Boolean)
          }
        })
      ).unwrap();
      await getFarm();
      successToastHandler(messages.UPDATED);
      setState(prevState => ({ ...prevState, editing: false }));
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_UPDATE_DATA);
    }
  }

  async function removeFarm() {
    if (!params.id) return;

    try {
      await dispatch(deleteFarm(+params.id)).unwrap();
      successToastHandler(messages.ARCHIVED);
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_DELETE_DATA);
    }

    props.history.push('/farms');
  }

  function initActions() {
    const availableActions = [];

    if (hasPermission([PERMISSIONS.FARMS.UPDATE_OWN], user.permissions)) {
      availableActions.push({
        label: (
          <Button color="default" size="small" variant="link" onClick={() => onChange(true, 'editing')}>
            Edit farm
          </Button>
        )
      });
    }

    if (hasPermission([PERMISSIONS.FARMS.DELETE_OWN], user.permissions)) {
      availableActions.push({
        label: (
          <Button color="default" size="small" variant="link" onClick={() => toggleModal('delete_modal')}>
            Archive farm
          </Button>
        )
      });
    }

    setState(prevState => ({
      ...prevState,
      actions: availableActions
    }));
  }

  const onChange = (value, field) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const onCancelEdit = (value, field) => {
    setState(prevState => ({ ...prevState, farm_edit: prevState.farm, [field]: value }));
  };

  const handleMasterGeofenceGeoJsonChange = geo_json => {
    setState(prevState => ({
      ...prevState,
      farm_edit: {
        ...prevState.farm_edit,
        master_geofence: { ...prevState.farm.master_geofence, geo_json }
      },
      farm: { ...prevState.farm, master_geofence: { ...prevState.farm.master_geofence, geo_json } }
    }));
  };

  const deleteGeoJson = () => {
    setState(prevState => ({ ...prevState, farm_edit: { ...prevState.farm_edit, geo_json: {} } }));
  };

  const breadcrumbItems = [
    {
      title: <Link to="/maps?tab=farm">List of Farms</Link>
    },
    {
      title: `${state.editing ? 'Edit ' : ''}Farm ${state.farm?.name}`
    }
  ];

  return (
    <>
      {loading && !farm ? (
        <LoadingBar />
      ) : (
        <>
          <Flex gap="small" vertical={false} justify="space-between" wrap>
            <Flex vertical={true}>
              <Title level={4}>
                {state.editing && 'Edit '}Farm {state.farm?.name}
              </Title>

              <AppBreadcrumb items={breadcrumbItems} />
            </Flex>
            <Flex gap="small">
              {!state.editing && <AppDropdown label="Actions" items={state.actions} />}
              {state.editing && (
                <CancelSaveButtons
                  disabled={!submittable}
                  handleCancel={() => onCancelEdit(false, 'editing')}
                  handleSave={saveFarm}
                />
              )}

              <PrevNextButtons
                nextId={state.farm?.next_id}
                prevId={state.farm?.prev_id}
                handlePrev={navigateToThePrevPage}
                handleNext={navigateToTheNextPage}
              />
            </Flex>
          </Flex>

          <Map
            className={state.editing ? 'editing' : ''}
            color={state.color}
            editing={state.editing}
            farm={state.farm}
            isNew={false}
            sites={state.farm?.sites}
            type={TYPE.FARM}
            editFence={handleMasterGeofenceGeoJsonChange}
            handleCancelEditing={() => onCancelEdit(false, 'editing')}
            handleSaveEditing={saveFarm}
            handleDelete={deleteGeoJson}
            onCreateGeofence={handleMasterGeofenceGeoJsonChange}
            setAnimal={navigateToTrackingPage}
          />

          {farm && (
            <FarmEditForm
              color={state.color}
              editing={state.editing}
              farm={state.editing ? state.farm_edit : state.farm}
              formRef={formRef}
              handleColorChange={changeColor}
              setSubmittable={setSubmittable}
            />
          )}

          {state.editing && (
            <Flex justify="end" className="mb-2">
              <CancelSaveButtons
                disabled={!submittable}
                handleCancel={() => onCancelEdit(false, 'editing')}
                handleSave={saveFarm}
              />
            </Flex>
          )}

          {![PLANS_TYPE.ADVANCED, PLANS_TYPE.BASIC, PLANS_TYPE.TRIAL].includes(subscription.myPlan?.type) && (
            <FarmHistory farmId={+params.id} />
          )}
        </>
      )}

      <AppModal
        isOpen={state.delete_modal}
        confirmButtonColor="danger"
        confirmButtonText="Archive"
        title="Archive farm"
        handleCancel={() => toggleModal('delete_modal')}
        handleConfirm={removeFarm}>
        <div className="py-4">Are you sure you want to archive this farm? This action cannot be undone.</div>
      </AppModal>
    </>
  );
}
