import { memo } from 'react';

import { capitalize } from '../../helpers/common';

import AppTag from '../../components/AppTag';

function RulePriority(props) {
  let priority = props.priority;
  const colors = {
    priority: 'red',
    high: 'red',
    normal: '#FFBF00',
    medium: '#FFBF00',
    low: '#CCCCCC',
    record: '#CCCCCC'
  };

  if (priority) {
    priority = priority.toLowerCase();

    switch (priority) {
      case 'record':
        priority = 'low';
        break;
      case 'normal':
        priority = 'medium';
        break;
      case 'priority':
        priority = 'high';
        break;
    }

    priority = priority.toLowerCase();
  }

  return <AppTag style={{ background: colors[priority] }}>{capitalize(priority)}</AppTag>;
}

export default memo(RulePriority);
