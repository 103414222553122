import { Suspense, memo } from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Tabs } from 'antd';

import Alerts from '../../../components/Alerts';
import LoadingBar from '../../../components/LoadingBar';

const DEFAULT_TAB = 'notifications';

function FarmHistory({ farmId }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get('tab');
  const items = [
    {
      key: 'notifications',
      label: 'Notifications',
      children: (
        <Suspense fallback={<LoadingBar />}>
          <Alerts type="full" columnType={2} query={{ farm_ids: [farmId] }} />
        </Suspense>
      )
    }
  ];

  return (
    <Card title="History">
      <Tabs defaultActiveKey={tab || DEFAULT_TAB} items={items} />
    </Card>
  );
}

export default memo(FarmHistory);
