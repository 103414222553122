import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import dayjs from 'dayjs';

import { ROLES } from '../../constants/role';

import { createInvitation } from '../../redux/actions/invitations';

import { successToastHandler } from '../action_notifier';

const CreateInvitationModal = ({ visible = false, closeModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { loading } = useSelector(state => state.invitation);
  const { list: farms } = useSelector(state => state.farm);

  const [isFormValid, setIsFormValid] = useState(false);

  const handleInvite = useCallback(async () => {
    try {
      const values = await form.validateFields();
      values.expires_date = values.expires_date && values.expires_date.format('YYYY-MM-DD');

      const { message } = await dispatch(createInvitation(values));
      closeModal();

      successToastHandler(message);
    } catch (error) {
      closeModal();
    } finally {
      setIsFormValid(false);
      form.resetFields();
    }
  }, [form]);

  return (
    <Modal title="Add user" open={visible} onCancel={closeModal} footer={() => null}>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() =>
          setIsFormValid(form.isFieldsTouched(true) && form.getFieldsError().every(({ errors }) => !errors.length))
        }>
        <Form.Item
          label="Email"
          name="invitee_email"
          rules={[{ required: true, type: 'email', message: 'Please enter a valid email!' }]}>
          <Input placeholder="Enter email" />
        </Form.Item>

        <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select a role!' }]}>
          <Select placeholder="Select role">
            <Select.Option value={ROLES.OWNER.value}>{ROLES.OWNER.label}</Select.Option>
            <Select.Option value={ROLES.VETERINARY.value}>{ROLES.VETERINARY.label}</Select.Option>
            <Select.Option value={ROLES.CONSULTANT.value}>{ROLES.CONSULTANT.label}</Select.Option>
            <Select.Option value={ROLES.EMPLOYEE.value}>{ROLES.EMPLOYEE.label}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Farm" name="farm_id" rules={[{ required: true, message: 'Please select a farm!' }]}>
          <Select placeholder="Select farm">
            {farms.map(farm => (
              <Select.Option value={farm.id} key={farm.id}>
                {farm.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Expiration Date"
          name="expires_date"
          rules={[{ required: true, message: 'Please select an expiration date!' }]}>
          <DatePicker style={{ width: '100%' }} disabledDate={current => current && current < dayjs().endOf('day')} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" onClick={handleInvite} loading={loading} block disabled={!isFormValid}>
            Send Invite
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateInvitationModal;
