import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { Tabs, Typography } from 'antd';

import { hasPermission } from '../../../helpers/user';

import { PERMISSIONS } from '../../../constants/permissions';

import { setSelectedIds, setTimeSlidersData, setTimeSlidersVisibility } from '../../../redux/reducers/animal';
import { setMapData } from '../../../redux/reducers/map';

import MapTracking from '../../../components/Maps/MapTracking';
import TimeSliders from '../../../components/TrackLivestock/TimeSliders';
import { SLIDER_DEFAULT_TIME_FRAME, SLIDER_DEFAULT_TIME_INTERVAL } from '../../../components/TrackLivestock/constants';

import { DEFAULT_TAB, TAB } from './constants';

const { Title, Text } = Typography;
const LivestockTable = lazy(() => import('../../../components/TrackLivestock/LivestockTable'));
const Farms = lazy(() => import('../../../views/pages/farms'));
const Geofences = lazy(() => import('../../../views/pages/geofences'));
const Sites = lazy(() => import('../../../views/pages/sites'));

export default function Maps({ history }) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get('tab');
  const { data } = useSelector(state => state.map);
  const { mapData: livestockMapData, selectedIds, isTimeSlidersVisible } = useSelector(state => state.animal);
  const { mapData: geofenceMapData } = useSelector(state => state.geofence);
  const user = useSelector(state => state.user);
  const { mapData: siteMapData } = useSelector(state => state.site);
  const { mapData: farmMapData } = useSelector(state => state.farm);
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);
  const [state, setState] = useState({
    animal: null,
    farm: null,
    filteredFarms: []
  });

  const tabItems = useMemo(() => {
    const items = [];

    if (hasPermission([PERMISSIONS.LIVESTOCK_ACTIVITY.VIEW], user.permissions)) {
      items.push({
        key: TAB.LIVESTOCK,
        label: 'Livestock',
        children: (
          <Suspense>
            <LivestockTable />
          </Suspense>
        )
      });
    }

    if (hasPermission([PERMISSIONS.GEOFENCES.VIEW_OWN], user.permissions)) {
      items.push({
        key: TAB.GEOFENCE,
        label: 'Geofence',
        children: (
          <Suspense>
            <Geofences />
          </Suspense>
        )
      });
    }

    if (hasPermission([PERMISSIONS.SITES.VIEW_OWN], user.permissions)) {
      items.push({
        key: TAB.SITE,
        label: 'Site',
        children: (
          <Suspense>
            <Sites />
          </Suspense>
        )
      });
    }

    if (hasPermission([PERMISSIONS.FARMS.VIEW], user.permissions)) {
      items.push({
        key: TAB.FARM,
        label: 'Farm',
        children: (
          <Suspense>
            <Farms />
          </Suspense>
        )
      });
    }

    return items;
  }, [user.permissions]);

  useEffect(() => {
    return () => {
      resetTimeSlidersData();
    };
  }, []);

  useEffect(() => {
    storeMapData();
  }, [geofenceMapData, siteMapData, farmMapData, livestockMapData]);

  useEffect(() => {
    setActiveTab(tabItems?.[0]?.key);
  }, [tabItems]);

  useEffect(() => {
    setActiveTab(tab || tabItems?.[0]?.key);
  }, [tab]);

  useEffect(() => {
    storeMapData();
  }, [activeTab]);

  function storeMapData() {
    switch (activeTab) {
      case TAB.GEOFENCE:
        dispatch(setMapData(geofenceMapData));
        break;
      case TAB.SITE:
        dispatch(setMapData(siteMapData));
        break;
      case TAB.FARM:
        dispatch(setMapData(farmMapData));
        break;
      case TAB.LIVESTOCK:
      default:
        dispatch(setMapData(livestockMapData));
        break;
    }
  }

  function setAnimal(id) {
    history.push(`/tracking/${id}`);
  }

  function handleTimeSlidersChange(value, field) {
    dispatch(setTimeSlidersData({ value, field }));
  }

  function handleTabChange(tab) {
    setActiveTab(tab);

    if (tab !== DEFAULT_TAB) resetTimeSlidersData();
  }

  function resetTimeSlidersData() {
    dispatch(setSelectedIds([]));
    dispatch(setTimeSlidersData({ field: 'time_frame', value: SLIDER_DEFAULT_TIME_FRAME }));
    dispatch(setTimeSlidersData({ field: 'time_interval', value: SLIDER_DEFAULT_TIME_INTERVAL }));
    dispatch(setTimeSlidersVisibility(false));
  }

  return (
    <div className="card p-2">
      <div>
        <Title level={4}>Maps</Title>
        <Breadcrumb>
          <BreadcrumbItem>
            <Text type="secondary">All maps and geo-locations</Text>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>

      <MapTracking
        activeTab={activeTab}
        farms={!state.animal ? data?.farms : null}
        key={JSON.stringify(data)}
        recordsWithGeoData={data?.recordsWithGeoData}
        tracking={state.animal ? data?.animal_tracking_filtered : data?.tracking}
        onAnimalClick={setAnimal}
      />

      {isTimeSlidersVisible && selectedIds?.length > 0 && <TimeSliders sliderHandler={handleTimeSlidersChange} />}

      <Tabs defaultActiveKey={tab || tabItems?.[0]?.key} items={tabItems} onChange={tab => handleTabChange(tab)} />
    </div>
  );
}
