import { memo } from 'react';

import { Radio } from 'antd';

import './styles.scss';

const CancelSaveButtons = ({ disabled = false, handleCancel, handleSave, handleSaveAndNavigate }) => {
  return (
    <Radio.Group buttonStyle="solid" size="large" optionType="button">
      <Radio.Button className="btn-cancel" onClick={handleCancel}>
        Cancel
      </Radio.Button>
      <Radio.Button disabled={disabled} onClick={handleSave}>
        Save
      </Radio.Button>
      {handleSaveAndNavigate && (
        <Radio.Button disabled={disabled} onClick={handleSaveAndNavigate}>
          Save and add another
        </Radio.Button>
      )}
    </Radio.Group>
  );
};

export default memo(CancelSaveButtons);
