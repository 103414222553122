import { REDUCERS } from '../../constants/reducers';

import { InvitationService } from '../../services';

export const setInvitations = value => ({
  type: REDUCERS.INVITATIONS.SET_INVITATIONS,
  value
});

export const setInvitationsLoading = value => ({
  type: REDUCERS.INVITATIONS.SET_INVITATIONS_LOADING,
  value
});

export const getInvitations = () => {
  return async dispatch => {
    dispatch(setInvitationsLoading(true));

    try {
      const { data } = await InvitationService.getInvitations();

      dispatch(setInvitations(data));

      return data;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setInvitationsLoading(false));
    }
  };
};

export const createInvitation = invitation => {
  return async dispatch => {
    dispatch(setInvitationsLoading(true));

    try {
      const { data } = await InvitationService.createInvitation(invitation);

      dispatch(getInvitations());

      return data;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setInvitationsLoading(false));
    }
  };
};

export const archiveInvitations = invitationIds => {
  return async (dispatch, getState) => {
    dispatch(setInvitationsLoading(true));

    try {
      await InvitationService.archiveInvitations(invitationIds);

      const { invitations } = getState().invitation;

      dispatch(
        setInvitations(
          invitations.map(invitation =>
            invitationIds?.includes(invitation.id) ? Object.assign(invitation, { is_active: false }) : invitation
          )
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setInvitationsLoading(false));
    }
  };
};

export const updateInvitation = (id, updatedInvitation) => {
  return async (dispatch, getState) => {
    dispatch(setInvitationsLoading(true));

    try {
      const { data } = await InvitationService.updateInvitation(id, updatedInvitation);

      const { invitations } = getState().invitation;

      dispatch(setInvitations(invitations.map(invitation => (invitation.id === id ? data : invitation))));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setInvitationsLoading(false));
    }
  };
};
