import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import debounce from 'lodash.debounce';

import axios from '../../axios';

import { capitalize } from '../../helpers/common';
import { getFilterOptions, sortNumbers, sortStrings } from '../../helpers/filter';
import { hasPermission } from '../../helpers/user';

import { KEY_PRESS_CODE, SEARCH_DEBOUNCE_DELAY } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';
import { PERMISSIONS } from '../../constants/permissions';
import { TABLE_CONFIG_COLUMNS, TABLE_CONFIG_KEYS } from '../../constants/table-configurations';

import { setSelectedFilters } from '../../redux/reducers/label';

import AppModal from '../../components/AppModal';
import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import AppTag from '../../components/AppTag';
import Filters from '../../components/filters';

function Labels(props) {
  const dispatch = useDispatch();
  const { selectedFilters } = useSelector(state => state.label);
  const user = useSelector(state => state.user);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [state, setState] = useState({
    actions: [],
    add_modal: false,
    columnFilterOptions: {
      farms: [],
      types: []
    },
    delete_modal: false,
    filters_open: false,
    filters: {
      farms: [],
      geofences: [],
      labels: [],
      animals: [],
      rules: []
    },
    isLoading: true,
    labels: [],
    livestock_opts: [],
    new_label: {
      name: '',
      colour: '',
      rules: [],
      animal_ids: []
    },
    query: '',
    selectAll: false,
    selected_labels: [],
    selected_livestock: [],
    tableData: []
  });

  useEffect(() => {
    getLivestock();
    getLabels();
    initActions();
  }, []);

  useEffect(() => {
    initActions();
  }, [state.selected_labels, selectedFilters]);

  useEffect(() => {
    getLabels();
  }, [state.filters]);

  function initActions() {
    const availableActions = [];

    if (hasPermission([PERMISSIONS.LABELS.CREATE], user.permissions)) {
      availableActions.push({
        label: (
          <Link className="app-link" to="group/new">
            Add new group
          </Link>
        )
      });
    }

    if (hasPermission([PERMISSIONS.LABELS.DELETE_OWN], user.permissions)) {
      availableActions.push({
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!state.selected_labels?.length}
            onClick={() => toggleModal('delete_modal')}>
            Archive group
          </Button>
        )
      });
    }

    if (hasPermission([PERMISSIONS.LABELS.VIEW_OWN], user.permissions)) {
      availableActions.push({
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!state.selected_labels?.length}
            onClick={viewCompoundGroup}>
            Create group collection
          </Button>
        )
      });
    }

    availableActions.push({
      label: (
        <Button
          color="default"
          size="small"
          variant="link"
          disabled={!Object.values(selectedFilters)?.length}
          onClick={clearFilters}>
          Clear all filters
        </Button>
      )
    });

    setState(prevState => ({
      ...prevState,
      actions: availableActions
    }));
  }

  function viewCompoundGroup() {
    props.history.push({
      pathname: 'group/new',
      collection: state.selected_labels
        .filter(id => {
          const label = state.labels.find(item => item.id === id);

          return label.main_type !== 'collection';
        })
        .map(id => {
          const label = state.labels.find(item => item.id === id);

          return {
            label: label.name,
            value: label.id
          };
        }),
      mainType: 'collection'
    });
  }

  const debounceSearch = useCallback(
    debounce(cb => {
      cb();
    }, SEARCH_DEBOUNCE_DELAY),
    []
  );

  const onChange = (value, field) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const handleKeyPress = charCode => {
    if (charCode === KEY_PRESS_CODE) {
      setState(prevState => ({ ...prevState, isLoading: true }));
      debounceSearch(getLabels);
    }
  };

  async function getLabels() {
    const response = await axios.get('labels', {
      params: {
        query: state.query,
        farm_ids: state.filters.farms?.map(x => x.value),
        label_ids: state.filters.labels?.map(x => x.value),
        animal_ids: state.filters.animals?.map(x => x.value),
        geofence_ids: state.filters.geofences?.map(x => x.value),
        rule_ids: state.filters.rules?.map(x => x.value)
      }
    });

    if (response.status === 200) {
      setState(prevState => ({
        ...prevState,
        labels: response.data,
        selectAll: false,
        tableData: response.data.slice(0, 25),
        selected_labels: [],
        isLoading: false,
        columnFilterOptions: {
          ...prevState.columnFilterOptions,
          farms: getFilterOptions(response?.data, 'farm', 'name'),
          types: getFilterOptions(response?.data, 'main_type')
        }
      }));
    }
  }

  function toggleModal(modal) {
    setState(prevState => ({ ...prevState, [modal]: !state[modal] }));
  }

  async function getLivestock() {
    const response = await axios.get('animals');
    const mapped_livestock = [];

    for (let live of response.data) {
      if (live.tag == null) continue;

      mapped_livestock.push({ value: live.id, label: live.eartag_official_id });
    }

    setState(prevState => ({ ...prevState, livestock_opts: mapped_livestock }));
  }

  async function deleteLabels() {
    const response = await axios.delete('labels/bulk', {
      data: { ids: state.selected_labels }
    });

    if (response.status === 200) {
      getLabels();
      toggleModal('delete_modal');
    }
  }

  function filtersUpdated(filters) {
    setState(prevState => ({ ...prevState, filters: { ...prevState.filters, ...filters } }));
  }

  function filtersClose() {
    setState(prevState => ({ ...prevState, filters_open: false }));
  }

  const clearFilters = () => {
    setFilteredInfo({});
    dispatch(setSelectedFilters({}));
  };

  const setFilters = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    dispatch(setSelectedFilters(filters));
  };

  const selectRecords = selected => {
    setState(prevState => ({
      ...prevState,
      selected_labels: selected,
      selectAll: prevState.rules?.length === selected.length
    }));
  };

  const commonColumnProperties = {
    ellipsis: true,
    sortDirections: ['ascend', 'descend']
  };

  const columns = [
    {
      ...commonColumnProperties,
      title: 'Group ID',
      dataIndex: 'identifier',
      configColumnKey: TABLE_CONFIG_COLUMNS.ID,
      filteredValue: filteredInfo.identifier || null,
      fixed: 'left',
      searchable: true,
      width: COLUMN_SIZE.MD,
      render: (value, record) => <Link to={`group/${record.id}`}>{record.identifier}</Link>,
      sorter: (a, b) => sortStrings(a.identifier, b.identifier)
    },
    {
      ...commonColumnProperties,
      title: 'Group Name',
      dataIndex: 'name',
      configColumnKey: TABLE_CONFIG_COLUMNS.NAME,
      filteredValue: filteredInfo.name || null,
      searchable: true,
      width: COLUMN_SIZE.XL,
      render: (value, record) => (
        <Link to={`group/${record.id}`}>
          <AppTag style={{ background: record.colour, color: 'white' }}>{record.name}</AppTag>
        </Link>
      ),
      sorter: (a, b) => sortStrings(a.name, b.name)
    },
    {
      ...commonColumnProperties,
      title: 'Group Type',
      dataIndex: 'main_type',
      configColumnKey: TABLE_CONFIG_COLUMNS.TYPE,
      filteredValue: filteredInfo.main_type || null,
      filters: state.columnFilterOptions.types,
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.main_type : record?.main_type === value),
      render: value => capitalize(value),
      sorter: (a, b) => sortStrings(a.main_type, b.main_type)
    },
    {
      ...commonColumnProperties,
      title: 'Farm',
      dataIndex: 'farm',
      configColumnKey: TABLE_CONFIG_COLUMNS.FARM,
      filteredValue: filteredInfo.farm || null,
      filters: state.columnFilterOptions.farms,
      width: COLUMN_SIZE.XXL,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.farm : record?.farm?.name === value),
      render: (value, record) => <Link to={`farm/${record.farm?.id}`}>{record?.farm?.name}</Link>,
      sorter: (a, b) => sortStrings(a.farm?.name, b.farm?.name)
    },
    {
      ...commonColumnProperties,
      title: '# of Livestock in Group',
      dataIndex: 'animals_count',
      configColumnKey: TABLE_CONFIG_COLUMNS.NUM_OF_LIVESTOCK,
      filteredValue: filteredInfo.animals_count || null,
      searchable: true,
      width: COLUMN_SIZE.SM,
      onFilter: (value, record) => record.animals_count === +value,
      sorter: (a, b) => sortNumbers(a.animals_count, b.animals_count)
    }
  ];

  return (
    <>
      <Filters
        open={state.filters_open}
        updateFilters={filtersUpdated}
        onClose={filtersClose}
        filter_types={['farm', 'animal', 'geofence']}
      />

      <div className="p-2 card">
        <AppTable
          headerClass="py-2"
          breadcrumb="List of Groups"
          tableConfigKey={TABLE_CONFIG_KEYS.GROUPS}
          searchPlaceholder="Search Groups"
          title="Groups"
          actions={state.actions}
          baseColumns={columns}
          dataSource={state.labels}
          filterable={false}
          loading={state.isLoading}
          searchDefaultValue={state.query}
          handleFilters={() => setState(prevState => ({ ...prevState, filters_open: !prevState.filters_open }))}
          handleOnChange={setFilters}
          handleOnSelectRecords={selectRecords}
          handleSearchChange={e => onChange(e.target.value, 'query')}
          handleSearchKeyPress={() => handleKeyPress(KEY_PRESS_CODE)}
        />
      </div>

      <AppModal
        confirmButtonColor="danger"
        confirmButtonText="Archive"
        isOpen={state.delete_modal}
        title="Archive groups"
        handleCancel={() => toggleModal('delete_modal')}
        handleConfirm={() => deleteLabels()}>
        <div className="py-4">Are you sure you want to archive selected groups? This action cannot be undone.</div>
      </AppModal>
    </>
  );
}

export default memo(Labels);
