import { PERMISSIONS } from '../constants/permissions.js';
import { USER_ROLE } from '../constants/user.js';

import General from '../views/dashboards/general.jsx';
import AdministrationGateways from '../views/pages/Administration/Gateways/index.jsx';
import AdministrationTables from '../views/pages/Administration/Tables/index.jsx';
import AdministrationTags from '../views/pages/Administration/Tags/index.jsx';
import AdministrationUsers from '../views/pages/Administration/Users/index.jsx';
import Animal from '../views/pages/Animal';
import Animals from '../views/pages/Animals';
import ContactUs from '../views/pages/ContactUs/index.jsx';
import EditGateway from '../views/pages/EditGateway/index.jsx';
import EditTag from '../views/pages/EditTag/index.jsx';
import Farm from '../views/pages/Farm';
import FarmNew from '../views/pages/FarmNew';
import Gateway from '../views/pages/Gateway';
import Geofence from '../views/pages/Geofence/index';
import GeofenceNew from '../views/pages/GeofenceNew/index';
import Label from '../views/pages/Group';
import Maps from '../views/pages/Maps';
import MyCalendar from '../views/pages/MyCalendar/index.jsx';
import MyTasks from '../views/pages/MyTasks/index.jsx';
import NewGateway from '../views/pages/NewGateway/index.jsx';
import NewUser from '../views/pages/NewUser/index.jsx';
//Sample Pages Dropdown
import Profile from '../views/pages/Profile';
import Rule from '../views/pages/Rule';
import Site from '../views/pages/Site';
import Tag from '../views/pages/Tag';
import AnimalNew from '../views/pages/animal_new.jsx';
import Breeding from '../views/pages/breeding.jsx';
import Devices from '../views/pages/devices';
import LabelNew from '../views/pages/label_new';
import Labels from '../views/pages/labels.jsx';
import Measurements from '../views/pages/measures.jsx';
import Notification from '../views/pages/notification.jsx';
import Notifications from '../views/pages/notifications.jsx';
import Rules from '../views/pages/rules.jsx';
import RuleBuilder from '../views/pages/rules_builder.jsx';
import Tracking from '../views/pages/tracking.jsx';
import Treatment from '../views/pages/treatment.jsx';

const ThemeRoutes = [
  {
    path: '/farm/:id',
    name: 'Farm',
    icon: 'mdi mdi-view-dashboard',
    features: ['farms'],
    component: Farm,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.FARMS.VIEW]
  },
  {
    path: '/site/:id',
    name: 'Site',
    icon: 'mdi mdi-view-dashboard',
    features: ['sites'],
    component: Site,
    display: false,
    protected: true,
    permissions: [PERMISSIONS.SITES.VIEW_OWN]
  },
  {
    path: '/farm_new',
    name: 'Farm',
    icon: 'mdi mdi-view-dashboard',
    features: ['farms'],
    component: FarmNew,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.FARMS.CREATE]
  },
  {
    path: '/geofence_new',
    name: 'GeofenceNew',
    icon: 'mdi mdi-view-dashboard',
    features: ['geofences'],
    component: GeofenceNew,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.GEOFENCES.CREATE]
  },
  {
    path: '/notification/:id',
    name: 'Notification',
    icon: 'mdi mdi-view-dashboard',
    features: ['notifications'],
    component: Notification,
    display: false,
    protected: true,
    permissions: [PERMISSIONS.NOTIFICATIONS.VIEW_OWN]
  },
  {
    path: '/group/new',
    name: 'Group',
    icon: 'mdi mdi-view-dashboard',
    features: ['groups'],
    component: LabelNew,
    display: false,
    protected: true,
    permissions: [PERMISSIONS.LABELS.CREATE]
  },
  {
    path: '/group/:id',
    name: 'Group',
    icon: 'mdi mdi-view-dashboard',
    features: ['groups'],
    component: Label,
    display: false,
    protected: true,
    permissions: [PERMISSIONS.LABELS.VIEW_OWN]
  },
  {
    path: '/rule/:id',
    name: 'Rule',
    icon: 'mdi mdi-view-dashboard',
    features: ['rules'],
    component: Rule,
    display: false,
    protected: true,
    permissions: [PERMISSIONS.RULES.VIEW_OWN]
  },
  {
    path: '/gateway/:id',
    name: 'Gateway',
    icon: 'mdi mdi-view-dashboard',
    features: ['gateways'],
    component: Gateway,
    display: false,
    protected: true,
    permissions: [PERMISSIONS.GATEWAYS.VIEW_OWN]
  },
  {
    path: '/tag/:id',
    name: 'Tag',
    icon: 'mdi mdi-view-dashboard',
    features: ['tags'],
    component: Tag,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.TAGS.VIEW_OWN]
  },
  {
    path: '/geofence/:id',
    name: 'Geofence',
    icon: 'mdi mdi-view-dashboard',
    features: ['geofences'],
    component: Geofence,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.GEOFENCES.VIEW_OWN]
  },
  {
    path: '/animal_new',
    name: 'LivestockNew',
    icon: 'mdi mdi-view-dashboard',
    features: ['livestock'],
    component: AnimalNew,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.ANIMALS.CREATE]
  },
  {
    path: '/animal/:id/:type',
    name: 'Livestock',
    icon: 'mdi mdi-view-dashboard',
    features: ['livestock'],
    component: Animal,
    protected: true,
    display: false
  },
  {
    path: '/animal/:id',
    name: 'Livestock',
    icon: 'mdi mdi-view-dashboard',
    features: ['livestock'],
    component: Animal,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.ANIMALS.VIEW_OWN]
  },

  /* DISPLAY MENU ITEMS */
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'stf stf-dashboard',
    features: ['weather', 'calendar', 'tasks'],
    component: General,
    protected: true
  },
  {
    path: '/maps',
    name: 'Maps',
    icon: 'stf stf-geofence',
    features: ['weather', 'calendar', 'tasks'],
    component: Maps,
    protected: true,
    permissions: [PERMISSIONS.FARMS.VIEW, PERMISSIONS.SITES.VIEW_OWN, PERMISSIONS.LIVESTOCK_ACTIVITY.VIEW]
  },
  {
    path: '/tracking/:id',
    name: 'Track Livestock',
    icon: 'stf stf-track',
    component: Tracking,
    features: ['track_livestock'],
    protected: true,
    display: false
  },
  {
    path: '/calendar',
    name: 'Calendar',
    features: ['calendar'],
    icon: 'icon-calender stf',
    protected: true,
    component: MyCalendar
  },
  {
    path: '/tasks',
    name: 'Tasks',
    features: ['tasks'],
    icon: 'icon-list stf',
    protected: true,
    component: MyTasks,
    permissions: [PERMISSIONS.TASKS.VIEW]
  },
  {
    path: '/notifications',
    name: 'Notifications',
    icon: 'stf stf-notifications',
    features: ['notifications'],
    component: Notifications,
    display: true,
    protected: true,
    permissions: [PERMISSIONS.NOTIFICATIONS.VIEW_OWN]
  },
  {
    collapse: true,
    path: '/animals',
    name: 'Livestock',
    icon: 'stf stf-livestock',
    features: ['livestock'],
    component: Animals,
    protected: true,
    display: true,
    permissions: [
      PERMISSIONS.ANIMALS.VIEW_OWN,
      PERMISSIONS.LIVESTOCK_MEASURES.VIEW_OWN,
      PERMISSIONS.LIVESTOCK_BREEDING.VIEW_OWN,
      PERMISSIONS.LIVESTOCK_TREATMENT.VIEW_OWN
    ],
    children: [
      {
        path: '/animals',
        name: 'Livestock',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        features: ['livestock'],
        protected: true,
        component: Animals,
        permissions: [PERMISSIONS.ANIMALS.VIEW_OWN]
      },
      {
        path: '/measures',
        name: 'Measures',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        features: ['measures'],
        protected: true,
        component: Measurements,
        permissions: [PERMISSIONS.LIVESTOCK_MEASURES.VIEW_OWN]
      },
      {
        path: '/breeding',
        name: 'Breeding',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        features: ['breeding'],
        protected: true,
        component: Breeding,
        permissions: [PERMISSIONS.LIVESTOCK_BREEDING.VIEW_OWN]
      },
      {
        path: '/treatments',
        name: 'Treatments',
        features: ['treatment'],
        icon: 'mdi mdi-adjust',
        protected: true,
        component: Treatment,
        permissions: [PERMISSIONS.LIVESTOCK_TREATMENT.VIEW_OWN]
      }
    ]
  },
  {
    path: '/groups',
    name: 'Groups',
    icon: 'stf stf-labels',
    features: ['groups'],
    component: Labels,
    display: true,
    protected: true,
    permissions: [PERMISSIONS.LABELS.VIEW_OWN]
  },
  {
    path: '/rules/builder/:id',
    name: 'RuleBuilder',
    features: ['rules'],
    icon: 'stf stf-rules',
    component: RuleBuilder,
    display: false,
    protected: true
  },
  {
    path: '/rules',
    name: 'Rules',
    features: ['rules'],
    icon: 'stf stf-rules',
    component: Rules,
    display: true,
    protected: true,
    permissions: [PERMISSIONS.RULES.VIEW_OWN]
  },
  {
    path: '/devices',
    name: 'Devices',
    features: ['tags', 'gateways'],
    icon: 'stf stf-tags',
    component: Devices,
    protected: true,
    permissions: [PERMISSIONS.TAGS.VIEW_OWN, PERMISSIONS.GATEWAYS.VIEW_OWN]
  },
  // Do not move rule builder as it must be the first to match

  // DO NOT DISPLAY
  {
    path: '/profile',
    name: 'Profile',
    icon: 'mdi mdi-view-dashboard',
    features: ['default'],
    component: Profile,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.USERS.VIEW_OWN]
  },
  {
    path: '/contact-us',
    name: 'Contact us',
    icon: 'mdi mdi-view-dashboard',
    features: ['default'],
    component: ContactUs,
    protected: true,
    display: false
  },
  {
    path: '/administration/tag/:id',
    name: 'Administration Tag',
    icon: 'mdi mdi-view-dashboard',
    features: ['default'],
    component: EditTag,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.TAGS.VIEW_ALL]
  },
  {
    path: '/administration/gateway/:id',
    name: 'Administration Gateway',
    icon: 'mdi mdi-view-dashboard',
    features: ['default'],
    component: EditGateway,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.GATEWAYS.VIEW_ALL]
  },
  {
    collapse: true,
    path: '/administration',
    name: 'Administration',
    icon: 'icon-wrench stf',
    features: ['default'],
    component: AdministrationUsers,
    protected: true,
    display: true,
    permissions: [PERMISSIONS.USERS.VIEW_ALL, PERMISSIONS.TAGS.VIEW_ALL, PERMISSIONS.GATEWAYS.VIEW_ALL],
    children: [
      {
        path: '/administration/users',
        name: 'Users',
        icon: 'mdi mdi-adjust',
        features: ['default'],
        protected: true,
        component: AdministrationUsers,
        permissions: [PERMISSIONS.USERS.VIEW_ALL]
      },
      {
        path: '/administration/tags',
        name: 'Tags',
        icon: 'mdi mdi-adjust',
        protected: true,
        features: ['default'],
        component: AdministrationTags,
        permissions: [PERMISSIONS.TAGS.VIEW_ALL]
      },
      {
        path: '/administration/gateways',
        name: 'Gateways',
        icon: 'mdi mdi-adjust',
        features: ['default'],
        protected: true,
        component: AdministrationGateways,
        permissions: [PERMISSIONS.GATEWAYS.VIEW_ALL]
      },
      {
        path: '/administration/tables',
        name: 'Tables',
        icon: 'mdi mdi-adjust',
        features: ['default'],
        protected: true,
        component: AdministrationTables
      }
    ]
  },
  {
    path: '/new-user',
    name: 'NewUser',
    icon: 'mdi mdi-view-dashboard',
    features: ['default'],
    component: NewUser,
    protected: true,
    display: false,
    permissions: [PERMISSIONS.USERS.CREATE]
  },
  {
    path: '/new-gateway',
    name: 'NewGateway',
    icon: 'mdi mdi-view-dashboard',
    component: NewGateway,
    features: ['default'],
    roles: [USER_ROLE.ADMIN],
    protected: true,
    display: false,
    permissions: [PERMISSIONS.GATEWAYS.CREATE]
  },
  {
    path: '/',
    pathTo: '/dashboard',
    name: 'Dashboard',
    features: ['default'],
    redirect: true
  }
];

export default ThemeRoutes;
