import React, { memo } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = ({
  isOpen,
  title,
  description,
  onCancel,
  onSubmit,
  cancelText = 'Cancel',
  submitText = 'Delete',
  submitButtonType = 'danger'
}) => {
  return (
    <div className="confirmation-modal">
      <Modal isOpen={isOpen}>
        <ModalBody>
          {!!title && (
            <>
              <br />
              <h5 className="text-center">
                <strong>{title}</strong>
              </h5>
              <br />
            </>
          )}

          {description}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            {cancelText}
          </Button>{' '}
          <Button color={submitButtonType} onClick={() => onSubmit()}>
            {submitText}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default memo(ConfirmationModal);
