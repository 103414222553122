import { createAsyncThunk } from '@reduxjs/toolkit';

import { AnimalService } from '../../services';

export const fetchAnimals = createAsyncThunk('animal/fetchAnimals', async (params, { rejectWithValue }) => {
  try {
    const { data } = await AnimalService.fetchAnimals(params);
    const payload = {
      data,
      sireAry: data
        .filter(s => s.sex === 'male')
        .map(x => ({
          value: x.id,
          label: x.identifier + ' (' + x.eartag_management_id + ')'
        })),
      calfAry: data.map(x => ({
        value: x.id,
        label: x.identifier + ' (' + x.eartag_management_id + ')'
      }))
    };

    return payload;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch animals');
  }
});

export const fetchAnimal = createAsyncThunk('animal/fetchAnimal', async (id, { rejectWithValue }) => {
  try {
    const { data } = await AnimalService.fetchAnimal(id);

    return data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch animal');
  }
});

export const fetchAnimalsTags = createAsyncThunk('animal/fetchAnimalsTags', async (params, { rejectWithValue }) => {
  try {
    const { data } = await AnimalService.fetchAnimalsTags(params);

    return data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch animals tags');
  }
});

export const fetchAnimalTags = createAsyncThunk(
  'animal/fetchAnimalTags',
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const { data } = await AnimalService.fetchAnimalTags(id, params);

      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch animal tags');
    }
  }
);
