import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Card, Flex, Typography } from 'antd';
import isEmpty from 'lodash.isempty';

import messages from '../../../constants/messages';

import { createFarm } from '../../../redux/actions/farm';

import CancelSaveButtons from '../../../components/CancelSaveButtons';
import Map from '../../../components/Maps/Map';
import { DEFAULT_SHAPE_COLOR, TYPE } from '../../../components/Maps/constants';
import { errorToastHandler, successToastHandler, warnToastHandler } from '../../../components/action_notifier';

import AppBreadcrumb from '../../ui-components/AppBreadcrumb';

import countries from '../../../countries.json';
import CountryCitySelect from './CountryCitySelect';
import FarmCreateForm from './FarmCreateForm';
import './styles.css';

const { Title, Text } = Typography;

function FarmNew(props) {
  const dispatch = useDispatch();
  const formCreateRef = useRef(null);
  const { user } = useSelector(state => state.user);
  const [submittable, setSubmittable] = useState(false);
  const [state, setState] = useState({
    color: DEFAULT_SHAPE_COLOR,
    countries: [],
    default_town: '',
    editing: false,
    farm: {
      address: {},
      circumference: '',
      days_grazed: '',
      description: '',
      farm_rules: [],
      full_address: '',
      geofence_id: '',
      geofences: [],
      herd_nr: '',
      id: null,
      master_geofence: null,
      name: '',
      size: ''
    },
    geofences: [],
    selected_country: {},
    selected_geofences: []
  });

  useEffect(() => {
    prepareInitData();
  }, []);

  function prepareInitData() {
    if (user?.address?.country || user?.address?.city) {
      setState(prevState => ({ ...prevState, default_town: user?.address?.city }));
    }

    let farm = {
      name: '',
      address: '',
      full_address: '',
      description: '',
      herd_number: 0,
      geofences: []
    };
    let cntrs = [];
    for (let cnt of countries) {
      cntrs.push({ value: cnt, label: cnt });
    }
    setState(prevState => ({
      ...prevState,
      countries: cntrs,
      farm: farm,
      selected_geofences: farm.geofences.map(gf => ({
        value: gf.id,
        label: gf.name,
        geofence: gf
      })),
      has_fence: false
    }));
  }

  const changeColor = useCallback(
    color => {
      setState(prevState => ({ ...prevState, color }));
    },
    [state.color]
  );

  function setMasterGeofenceGeoJson(geo_json) {
    setState(prevState => ({
      ...prevState,
      farm: {
        ...prevState.farm,
        master_geofence: { geo_json }
      },
      has_fence: true
    }));
  }

  async function handleCreateFarm() {
    const success = await createNewFarm();

    if (success) props.history.push('/maps?tab=farm');
  }

  async function createNewFarm() {
    if (!state.has_fence || isEmpty(state.farm.master_geofence?.geo_json)) {
      warnToastHandler(messages.MASTER_GEOFENCE_REQUIRED);
      return;
    }

    const values = await formCreateRef.current?.validateFields();
    const address = {
      street: values.street,
      city: values.city,
      country: values.country,
      house_number: values.house_number,
      postal_code: values.postal_code
    };
    const herdNumbers = state.farm.herd_number ? [state.farm.herd_number.toString()] : [];

    try {
      await dispatch(
        createFarm({
          address,
          color: state.color,
          description: values?.description,
          herd_numbers: herdNumbers,
          master_geofence: state.farm.master_geofence?.geo_json,
          name: values?.name
        })
      ).unwrap();

      successToastHandler(messages.CREATED);

      return true;
    } catch (error) {
      errorToastHandler(messages.FAILED_ON_CREATE_DATA('farm'));

      return false;
    }
  }

  const handleChangeCity = city => {
    setState(prevState => ({ ...prevState, default_town: city }));
  };

  const breadcrumbItems = [
    {
      title: <Link to="/maps?tab=farm">List of Farms</Link>
    },
    {
      title: 'Create New Farm'
    }
  ];

  return (
    <>
      <Flex gap="small" vertical={false} justify="space-between" wrap>
        <Flex vertical={true}>
          <Title level={4}>New Farm {state.farm.id}</Title>

          <AppBreadcrumb items={breadcrumbItems} />
        </Flex>
        <Flex>
          <CancelSaveButtons
            disabled={!submittable || !state.farm?.master_geofence}
            handleCancel={() => props.history.push('/maps?tab=farm')}
            handleSave={handleCreateFarm}
          />
        </Flex>
      </Flex>

      <Card className="mb-2">
        <Text type="secondary">Mark the size and borders of the farm.</Text>
        <CountryCitySelect
          defaultCountry={user?.address?.country}
          defaultCity={user?.address?.city}
          handleChangeCity={handleChangeCity}
        />

        {state.default_town && (
          <>
            <Map
              color={state.color}
              defaultPosition={
                typeof state.default_town === 'string'
                  ? `${user?.address?.country} ${state.default_town}`
                  : state.default_town
              }
              editing={true}
              farm={state.farm}
              isNew={true}
              type={TYPE.FARM}
              editFence={setMasterGeofenceGeoJson}
              handleCancelEditing={() => props.history.push('/maps?tab=farm')}
              handleSaveEditing={handleCreateFarm}
              onCreateGeofence={setMasterGeofenceGeoJson}
            />

            <FarmCreateForm
              address={user?.address}
              color={state.color}
              formRef={formCreateRef}
              handleColorChange={changeColor}
              setSubmittable={setSubmittable}
            />
          </>
        )}
      </Card>

      <Flex justify="end" className="mb-2">
        <CancelSaveButtons
          disabled={!submittable || !state.farm?.master_geofence}
          handleCancel={() => props.history.push('/maps?tab=farm')}
          handleSave={handleCreateFarm}
        />
      </Flex>
    </>
  );
}

export default memo(FarmNew);
