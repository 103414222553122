'use strict';

import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';

import { Button } from 'antd';
import moment from 'moment';

import axios from '../axios';

import { capitalize } from '../helpers/common';
import { getFilterOptions, sortDates, sortNumbers, sortStrings } from '../helpers/filter';

import { DATE_FORMAT } from '../constants/common';
import { BLANK_FILTER_TEXT } from '../constants/livestock';
import messages from '../constants/messages';
import { NOTIFICATION_STATUS } from '../constants/notifications';

import {
  getNotifications,
  setNotificationList,
  setNotificationState,
  setSelectedFilters
} from '../redux/actions/notification';

import AppTable from '../components/AppTable';
import { COLUMN_SIZE } from '../components/AppTable/constants';
import Priority from '../components/PriorityTag';
import { errorToastHandler } from '../components/action_notifier';

import { NotificationService } from '../services';

const commonColumnProperties = {
  ellipsis: true,
  sortDirections: ['ascend', 'descend']
};

function SimpleAlerts(props) {
  let rows = [];

  for (let d of props.data.slice(0, 10)) {
    rows.push(
      <li className="simple-alerts-item">
        <b>[{moment(d.created_at).format(DATE_FORMAT.DATE)}]</b> {d.message}
      </li>
    );
  }
  if (props.data.length === 0) {
    rows.push(<li>No alerts triggered.</li>);
  }
  return <ul className="simple-alerts">{rows}</ul>;
}

function TableAlerts(props) {
  const dispatch = useDispatch();

  const [filteredInfo, setFilteredInfo] = useState({});
  const [columnFilterOptions, setColumnFilterOptions] = useState({});
  const [actions, setActions] = useState([]);

  const notificationState = useSelector(state => state.notification);

  useEffect(() => {
    initActions();
  }, []);

  useEffect(() => {
    if (notificationState?.alerts) {
      setColumnFilterOptions({
        priority: getFilterOptions(notificationState?.alerts, 'priority'),
        status: getFilterOptions(notificationState?.alerts, 'status'),
        type: getFilterOptions(notificationState?.alerts, 'type')
      });

      initActions();
    }
  }, [notificationState]);

  function selectRecords(ids) {
    dispatch(setNotificationState({ selectedAlerts: ids }));
  }

  const COLUMNS1 = [
    {
      ...commonColumnProperties,
      title: 'Notification ID',
      dataIndex: 'identifier',
      filteredValue: filteredInfo.identifier || null,
      searchable: true,
      width: COLUMN_SIZE.SM,
      sorter: (a, b) => sortStrings(a.identifier, b.identifier),
      render: (value, record) => <Link to={`notification/${record.id}`}>{record.identifier}</Link>
    },
    {
      ...commonColumnProperties,
      title: 'Date',
      dataIndex: 'created_at',
      filteredValue: filteredInfo.created_at || null,
      searchable: true,
      width: COLUMN_SIZE.XL,
      render: (value, record) => moment(record.created_at).format(DATE_FORMAT.DATETIME),
      sorter: (a, b) => sortDates(a.created_at, b.created_at)
    },
    {
      ...commonColumnProperties,
      title: 'Notification',
      dataIndex: 'message',
      filteredValue: filteredInfo.message || null,
      searchable: true,
      width: COLUMN_SIZE.XXL,
      sorter: (a, b) => sortStrings(a.message, b.message)
    },
    {
      ...commonColumnProperties,
      title: 'Notification Type',
      dataIndex: 'type',
      filteredValue: filteredInfo.type || null,
      filters: columnFilterOptions.type,
      width: COLUMN_SIZE.LG,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.type : record?.type === value),
      render: value => capitalize(value),
      sorter: (a, b) => sortStrings(a.type, b.type)
    },
    {
      ...commonColumnProperties,
      title: 'Count',
      dataIndex: 'count',
      filteredValue: filteredInfo.count || null,
      width: COLUMN_SIZE.SM,
      sorter: (a, b) => sortNumbers(a.count, b.count)
    },
    {
      ...commonColumnProperties,
      title: 'Notification Priority',
      dataIndex: 'priority',
      filteredValue: filteredInfo.priority || null,
      filters: columnFilterOptions.priority,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.priority : record?.priority === value),
      render: (value, record) => <Priority priority={record.priority} isClear={record.cleared_at}></Priority>,
      sorter: (a, b) => sortNumbers(a.priority_index, b.priority_index)
    },
    {
      ...commonColumnProperties,
      title: 'Age',
      dataIndex: 'age',
      filteredValue: filteredInfo.age || null,
      searchable: true,
      width: COLUMN_SIZE.SM,
      onFilter: (value, record) => {
        const text = moment.duration(moment(record.created_at).diff(moment(new Date()))).humanize() + ' ago';
        return text.includes(value);
      },
      render: (value, record) =>
        moment.duration(moment(record.created_at).diff(moment(new Date()))).humanize() + ' ago',
      sorter: (a, b) => sortDates(a.created_at, b.created_at)
    },
    {
      title: 'Action Field',
      dataIndex: 'actions',
      width: COLUMN_SIZE.SM
    }
  ];

  const COLUMNS2 = [
    {
      ...commonColumnProperties,
      title: 'Notification ID',
      dataIndex: 'identifier',
      filteredValue: filteredInfo.identifier || null,
      searchable: true,
      width: COLUMN_SIZE.SM,
      sorter: (a, b) => sortStrings(a.identifier, b.identifier),
      render: (value, record) => <Link to={`notification/${record.id}`}>{record.identifier}</Link>
    },
    {
      ...commonColumnProperties,
      title: 'Notification Priority',
      dataIndex: 'priority',
      filteredValue: filteredInfo.priority || null,
      filters: columnFilterOptions.priority,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.priority : record?.priority === value),
      render: (value, record) => <Priority priority={record.priority} isClear={record.cleared_at}></Priority>,
      sorter: (a, b) => sortNumbers(a.priority_index, b.priority_index)
    },
    {
      ...commonColumnProperties,
      title: 'Notification Type',
      dataIndex: 'type',
      filteredValue: filteredInfo.type || null,
      filters: columnFilterOptions.type,
      width: COLUMN_SIZE.LG,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.type : record?.type === value),
      render: value => capitalize(value),
      sorter: (a, b) => sortStrings(a.type, b.type)
    },
    {
      ...commonColumnProperties,
      title: 'Notification Rule',
      dataIndex: 'ruleName',
      filteredValue: filteredInfo.ruleName || null,
      searchable: true,
      width: COLUMN_SIZE.XXL,
      onFilter: (value, record) => record.rule?.name?.toLowerCase().includes(value.toLowerCase()),
      render: (value, record) => record?.rule?.name,
      sorter: (a, b) => sortStrings(a?.rule?.name, b?.rule?.name)
    },
    {
      ...commonColumnProperties,
      title: 'Livestock Count',
      dataIndex: 'animals',
      filteredValue: filteredInfo.animals || null,
      searchable: true,
      width: COLUMN_SIZE.SM,
      onFilter: (value, record) => record.animals?.length === +value,
      render: (value, record) => record.animals?.length ?? 0,
      sorter: (a, b) => sortNumbers(a?.animals?.length, b?.animals?.length)
    },
    {
      ...commonColumnProperties,
      title: 'Notification Trigger Date',
      dataIndex: 'created_at',
      filteredValue: filteredInfo.created_at || null,
      searchable: true,
      width: COLUMN_SIZE.XL,
      render: (value, record) => moment(record.created_at).format(DATE_FORMAT.DATETIME),
      sorter: (a, b) => sortDates(a.created_at, b.created_at)
    },
    {
      ...commonColumnProperties,
      title: 'Notification Status',
      dataIndex: 'notificationStat',
      filteredValue: filteredInfo.notificationStat || null,
      filters: columnFilterOptions.status?.map(option => ({
        ...option,
        text: NOTIFICATION_STATUS[option.text.toLowerCase()]
      })),
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.status : record?.status === value),
      render: (value, record) => NOTIFICATION_STATUS[record.status],
      sorter: (a, b) => {
        const strA = NOTIFICATION_STATUS[a.status];
        const strB = NOTIFICATION_STATUS[b.status];

        return sortStrings(strA, strB);
      }
    },
    {
      title: 'Snooze',
      dataIndex: 'snooze',
      align: 'center',
      ellipsis: true,
      noPadding: true,
      width: COLUMN_SIZE.XS,
      render: (value, record) => (
        <i onClick={() => props?.onSnoozeClick(record.id)} className={'not-action mdi mdi-alarm ' + record.status}></i>
      )
    },
    {
      title: 'Clear',
      dataIndex: 'clear',
      align: 'center',
      ellipsis: true,
      noPadding: true,
      width: COLUMN_SIZE.XS,
      render: (value, record) => (
        <i
          onClick={() => props?.onClearClick(record.id)}
          className={'not-action clear mdi mdi-check-circle-outline ' + !!record.cleared_at}></i>
      )
    }
  ];

  const columns = props?.props?.columnType === 2 ? COLUMNS2 : COLUMNS1;

  const setFilters = (pagination, filters) => {
    setFilteredInfo(filters);
    dispatch(setSelectedFilters(filters));
  };

  const clearFilters = () => {
    setFilteredInfo({});
    dispatch(setSelectedFilters({}));
  };

  async function clearAllNotifications() {
    const response = await NotificationService.clearAllNotifications();

    if (response.status === 200) {
      dispatch(setNotificationList([]));
      dispatch(getNotifications());
    }
  }

  async function snoozeAllNotifications() {
    const response = await NotificationService.snoozeAllNotifications();

    if (response.status === 200) dispatch(getNotifications());
  }

  async function clearNotifications() {
    const selectedNotifs = notificationState?.selectedAlerts;

    if (selectedNotifs.length) {
      const response = await axios.put('notifications/clear', {
        ids: selectedNotifs
      });

      if (response.status === 200) {
        const updatedNotificationList = notificationState?.list?.filter(notif => !selectedNotifs.includes(notif.id));
        dispatch(setNotificationList(updatedNotificationList));
        // dispatch(setNotificationState({ selectedAlerts: [] }));
        dispatch(getNotifications());
      }
    }
  }

  async function snoozeNotifications() {
    if (notificationState?.selectedAlerts?.length) {
      const response = await axios.put('notifications/snooze', {
        ids: notificationState?.selectedAlerts,
        force: true,
        seconds: 86400
      });

      if (response.status === 200) {
        // dispatch(setNotificationState({ selectedAlerts: [] }));
        dispatch(getNotifications());
      }
    }
  }

  function initActions() {
    setActions([
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!notificationState?.selectedAlerts?.length}
            onClick={clearNotifications}>
            Clear notifications
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!notificationState?.selectedAlerts?.length}
            onClick={snoozeNotifications}>
            Snooze notifications
          </Button>
        )
      },
      {
        label: (
          <Button color="default" size="small" variant="link" onClick={clearAllNotifications}>
            Clear all new notifications
          </Button>
        )
      },
      {
        label: (
          <Button color="default" size="small" variant="link" onClick={snoozeAllNotifications}>
            Snooze all new notifications
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!Object.values(notificationState?.selectedFilters)?.length}
            onClick={clearFilters}>
            Clear all filters
          </Button>
        )
      }
    ]);
  }

  return (
    <div className="allow-overflow">
      <AppTable
        headerClass="py-2"
        actions={props?.props?.columnType !== 1 ? actions : null}
        settings={props?.props?.columnType !== 1}
        baseColumns={columns}
        dataSource={notificationState?.alerts}
        filterable={false}
        searchable={false}
        // loading={loading}
        rowsSelectable={props?.props?.columnType !== 1}
        rowClassName={record => (record.cleared_at ? 'row-secondary' : '')}
        handleOnChange={setFilters}
        handleOnSelectRecords={selectRecords}
      />
    </div>
  );
}

class Alerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      props: this.props
    };
    this.getData = this.getData.bind(this);
    this.snoozeNotification = this.snoozeNotification.bind(this);
    this.clearNotification = this.clearNotification.bind(this);
  }

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    const data = this.props.getNotifications({
      ...this.state.props.query
    });
    if (this.props.filter && typeof this.props.filter.filter !== 'undefined') {
      this.setState(state => ({
        data: data.notifications?.filter(i => this.props.filter.filter(i))
      }));
    } else {
      this.setState(state => ({
        data: data.notifications
      }));
    }
  }

  async snoozeNotification(id) {
    let response = await axios.put('notifications/' + id + '/snooze');
    if (response.status === 200) {
      this.getData();
    }
  }

  async clearNotification(id) {
    let response = await axios.put('notifications/' + id + '/clear');

    if (response.status === 200) {
      this.getData();
    }
  }

  render() {
    if (this.state.props.type === 'simple') {
      return <SimpleAlerts data={this.state.data} props={this.state.props} />;
    } else {
      return (
        <TableAlerts
          data={this.state.data}
          props={this.state.props}
          onClearClick={this.clearNotification}
          onSnoozeClick={this.snoozeNotification}
        />
      );
    }
  }
}

export default connect(
  state => ({
    alerts: state?.notification?.alerts
  }),
  dispatch => ({
    getNotifications: params => {
      return dispatch(getNotifications(params));
    }
  })
)(Alerts);
