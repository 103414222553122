import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Collapse, Nav, NavbarBrand } from 'reactstrap';

import { getUserRoles, hasPermission, includesRole } from '../../helpers/user';

import kraalWhitelogo from '../../assets/images/kraalWhitelogo.png';
import small_logo from '../../assets/images/logo-small.png';
import './styles.scss';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.expandLogo = this.expandLogo.bind(this);
    this.activeRoute.bind(this);
    this.state = {
      authentication: this.activeRoute('/authentication') !== '' ? true : false,
      uicomponents: this.activeRoute('/ui-components') !== '' ? true : false,
      samplepages: this.activeRoute('/sample-pages') !== '' ? true : false,
      dashboardpages: this.activeRoute('/dahboards') !== '' ? true : false,
      iconsPages: this.activeRoute('/icons') !== '' ? true : false,
      formlayoutPages: this.activeRoute('/form-layouts') !== '' ? true : false,
      formpickerPages: this.activeRoute('/form-pickers') !== '' ? true : false,
      collapse: '',
      user: props.user
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
  /*--------------------------------------------------------------------------------*/
  expandLogo() {
    // let flag = document.getElementById("sidebarbg").offsetWidth;
    // if( document.getElementById("logobg") ) {
    // 	document.getElementById("logobg").classList.remove("expand-logo");
    // 	if( flag < 100 ) {
    // 		document.getElementById("logobg").classList.add("expand-logo");
    // 	}
    // }
  }
  /*--------------------------------------------------------------------------------*/
  /*Verifies if routeName is the one active (in browser input)                      */
  /*--------------------------------------------------------------------------------*/
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) === 0 ? 'selected' : '';
  }

  onClickMenuItem(item) {
    this.setState({
      collapse: item?.name ?? false
    });
  }

  render() {
    let isDesktopView = true;
    if (document.getElementById('main-wrapper') && document.getElementById('main-wrapper').offsetWidth < 768) {
      isDesktopView = false;
    }
    const user = this.props.user;
    const roles = getUserRoles(user?.roles);
    return (
      <aside
        className="left-sidebar"
        id="sidebarbg"
        data-sidebarbg={this.props.data.settings[0].sidebarbg}
        onMouseEnter={this.expandLogo}
        onMouseLeave={this.expandLogo}>
        {isDesktopView && (
          <NavbarBrand href="/">
            <b className="logo-icon">
              <img src={small_logo} alt="homepage" className="light-logo icon-logo" />
            </b>
            <span className="logo-text">
              <img src={kraalWhitelogo} className="light-logo expendlogo" alt="homepage" />
            </span>
          </NavbarBrand>
        )}
        <div className="scroll-sidebar">
          <PerfectScrollbar className="sidebar-nav">
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar Menus will go here                                                */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav id="sidebarnav">
              {this.props.routes.map((prop, key) => {
                // if (!window.localStorage.getItem("token")) {
                // 	window.location = "/auth/login";
                // }
                if (prop?.permissions && !hasPermission(prop.permissions, this.props.userPermissions)) {
                  return null;
                }

                if (prop?.roles?.length && !includesRole(prop?.roles, roles)) {
                  return null;
                }
                if (prop.redirect || prop.display === false) {
                  return null;
                } else if (prop.navlabel) {
                  return (
                    <li className="nav-small-cap" key={key} onClick={() => this.onClickMenuItem()}>
                      <i className={prop.icon}></i>
                      <span className="hide-menu">{prop.name}</span>
                    </li>
                  );
                } else if (prop.collapse) {
                  let firstdd = {};

                  firstdd[prop['state']] = !this.state[prop.state];
                  return (
                    /*--------------------------------------------------------------------------------*/
                    /* Menus wiil be goes here                                                        */
                    /*--------------------------------------------------------------------------------*/
                    <li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key}>
                      <a
                        data-toggle="collapse"
                        className="sidebar-link has-arrow"
                        aria-expanded={this.state[prop.state]}
                        onClick={() => this.onClickMenuItem(prop)}>
                        <i className={prop.icon} />
                        <span className="hide-menu">{prop.name}</span>
                      </a>
                      {/*--------------------------------------------------------------------------------*/}
                      {/* Sub-Menus wiil be goes here                                                    */}
                      {/*--------------------------------------------------------------------------------*/}
                      <Collapse isOpen={prop.name === this.state.collapse}>
                        <ul className="first-level">
                          {prop.children.map((prop, key) => {
                            if (prop.redirect) return null;

                            if (prop.collapse) {
                              let seconddd = {};
                              seconddd[prop['state']] = !this.state[prop.state];
                              return (
                                <li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key}>
                                  <a
                                    data-toggle="collapse"
                                    className="sidebar-link has-arrow"
                                    aria-expanded={this.state[prop.state]}
                                    onClick={() => this.setState(seconddd)}>
                                    <i className={prop.icon} />
                                    <span className="hide-menu">{prop.name}</span>
                                  </a>
                                  {/*--------------------------------------------------------------------------------*/}
                                  {/* Sub-Menus wiil be goes here                                                    */}
                                  {/*--------------------------------------------------------------------------------*/}
                                  <Collapse isOpen={this.state[prop.state]}>
                                    <ul className="second-level">
                                      {prop.subchild.map((prop, key) => {
                                        if (prop.redirect) return null;
                                        return (
                                          <li
                                            className={this.activeRoute(prop.path) + ' sidebar-item'}
                                            key={key}
                                            onClick={() => this.onClickMenuItem()}>
                                            <NavLink to={prop.path} activeClassName="active" className="sidebar-link">
                                              <i className={prop.icon} />
                                              <span className="hide-menu">{prop.name}</span>
                                            </NavLink>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </Collapse>
                                </li>
                              );
                            }
                            return (
                              /*--------------------------------------------------------------------------------*/
                              /* Adding Sidebar Item                                                            */
                              /*--------------------------------------------------------------------------------*/
                              <li
                                className={
                                  this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'
                                }
                                key={key}>
                                <NavLink to={prop.path} className="sidebar-link" activeClassName="active">
                                  <i className={prop.icon} />
                                  <span className="hide-menu">{prop.name}</span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  );
                } else {
                  return (
                    /*--------------------------------------------------------------------------------*/
                    /* Adding Sidebar Item                                                            */
                    /*--------------------------------------------------------------------------------*/
                    <li
                      className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'}
                      key={key}
                      onClick={() => this.onClickMenuItem()}>
                      <NavLink to={prop.path} className="sidebar-link" activeClassName="active">
                        <i className={prop.icon} />
                        <span className="hide-menu">{prop.name}</span>
                      </NavLink>
                    </li>
                  );
                }
              })}
            </Nav>
          </PerfectScrollbar>
        </div>
      </aside>
    );
  }
}

export default connect(state => ({
  user: state?.user?.user
}))(Sidebar);
