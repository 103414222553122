import { memo, useContext } from 'react';

import { useSortable } from '@dnd-kit/sortable';

import { DragIndexContext } from './index';

const dragActiveStyle = (dragState, id) => {
  const { active, over, direction } = dragState;
  let style = {};

  if (active && active === id) {
    style = { backgroundColor: 'gray', opacity: 0.5 };
  } else if (over && id === over && active !== over) {
    style =
      direction === 'right'
        ? {
            borderRight: '1px dashed gray'
          }
        : {
            borderLeft: '1px dashed gray'
          };
  }

  return style;
};

function TableHeaderCell(props) {
  const dragState = useContext(DragIndexContext);
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.id
  });
  const style = {
    ...props.style,
    cursor: 'move',
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9,
          userSelect: 'none'
        }
      : {}),
    ...dragActiveStyle(dragState, props.id)
  };

  return <th {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
}

export default memo(TableHeaderCell);
