export const REDUCERS = {
  ADMIN: {
    SET_ADMIN_STATE: 'SET_ADMIN_STATE'
  },
  BREEDS: {
    FETCH_BREEDS_FAILURE: 'FETCH_BREEDS_FAILURE',
    FETCH_BREEDS_REQUEST: 'FETCH_BREEDS_REQUEST',
    FETCH_BREEDS_SUCCESS: 'FETCH_BREEDS_SUCCESS'
  },
  BREEDING: {
    DELETE_BREEDINGS: 'DELETE_BREEDINGS',
    FETCH_BREEDING_FAILURE: 'FETCH_BREEDING_FAILURE',
    FETCH_BREEDING_REQUEST: 'FETCH_BREEDING_REQUEST',
    FETCH_BREEDING_SUCCESS: 'FETCH_BREEDING_SUCCESS',
    FETCH_BREEDING_EVENT_TYPES_FAILURE: 'FETCH_BREEDING_EVENT_TYPES_FAILURE',
    FETCH_BREEDING_EVENT_TYPES_REQUEST: 'FETCH_BREEDING_EVENT_TYPES_REQUEST',
    FETCH_BREEDING_EVENT_TYPES_SUCCESS: 'FETCH_BREEDING_EVENT_TYPES_SUCCESS',
    FETCH_BREEDING_EVENT_STATUS_TYPES_FAILURE: 'FETCH_BREEDING_EVENT_STATUS_TYPES_FAILURE',
    FETCH_BREEDING_EVENT_STATUS_TYPES_REQUEST: 'FETCH_BREEDING_EVENT_STATUS_TYPES_REQUEST',
    FETCH_BREEDING_EVENT_STATUS_TYPES_SUCCESS: 'FETCH_BREEDING_EVENT_STATUS_TYPES_SUCCESS',
    SET_SELECTED_FILTERS: 'SET_SELECTED_FILTERS'
  },
  BREEDING_STATUSES: {
    FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE',
    FETCH_DATA_REQUEST: 'FETCH_DATA_REQUEST',
    FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS'
  },
  CALENDAR: {
    INCREMENT_NEXT_COUNT_CALENDAR: 'INCREMENT_NEXT_COUNT_CALENDAR',
    INCREMENT_PREV_COUNT_CALENDAR: 'INCREMENT_PREV_COUNT_CALENDAR',
    SET_CALENDAR_STATE: 'SET_CALENDAR_STATE',
    SET_CURRENT_DATE: 'SET_CURRENT_DATE'
  },
  EVENT: {
    SET_CURRENT_EVENT: 'SET_CURRENT_EVENT',
    SET_EVENT_LIST: 'SET_EVENT_LIST',
    SET_EVENT_QUERY_PARAMS: 'SET_EVENT_QUERY_PARAMS',
    SET_EVENT_STATE: 'SET_EVENT_STATE'
  },
  GATEWAY: {
    SET_GATEWAY: 'SET_GATEWAY',
    SET_ALL_GATEWAYS: 'SET_ALL_GATEWAYS',
    FETCH_GATEWAYS_FAILURE: 'FETCH_GATEWAYS_FAILURE',
    FETCH_GATEWAYS_REQUEST: 'FETCH_GATEWAYS_REQUEST',
    FETCH_GATEWAYS_SUCCESS: 'FETCH_GATEWAYS_SUCCESS'
  },
  GROUP: {
    SET_SELECTED_FILTERS: 'SET_SELECTED_FILTERS'
  },
  LOCATION: {
    FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE',
    FETCH_DATA_REQUEST: 'FETCH_DATA_REQUEST',
    FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS'
  },
  MEASUREMENT: {
    DELETE_MEASUREMENTS: 'DELETE_MEASUREMENTS',
    FETCH_MEASUREMENTS_FAILURE: 'FETCH_MEASUREMENTS_FAILURE',
    FETCH_MEASUREMENTS_REQUEST: 'FETCH_MEASUREMENTS_REQUEST',
    FETCH_MEASUREMENTS_SUCCESS: 'FETCH_MEASUREMENTS_SUCCESS',
    SET_SELECTED_FILTERS: 'SET_SELECTED_FILTERS',
    UPDATE_MEASUREMENT_FAILURE: 'UPDATE_MEASUREMENT_FAILURE'
  },
  MEASUREMENT_TYPE: {
    FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE',
    FETCH_DATA_REQUEST: 'FETCH_DATA_REQUEST',
    FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS'
  },
  NOTIFICATION: {
    SET_NOTIFICATION_LIST: 'SET_NOTIFICATION_LIST',
    SET_NOTIFICATION_STATE: 'SET_NOTIFICATION_STATE',
    SET_SELECTED_FILTERS: 'SET_SELECTED_FILTERS'
  },
  RULE: {
    SET_SELECTED_FILTERS: 'SET_SELECTED_FILTERS'
  },
  SUBSCRIPTION: {
    SET_PLANS: 'SET_PLANS',
    SET_MAPPED_PLANS: 'SET_MAPPED_PLANS',
    SET_FEATURE_OPTIONS: 'SET_FEATURE_OPTIONS',
    SET_MAPPED_FEATURE_OPTIONS: 'SET_MAPPED_FEATURE_OPTIONS',
    SET_SUBSCRIPTION_STATE: 'SET_SUBSCRIPTION_STATE'
  },
  TAG: {
    SET_TAG: 'SET_TAG',
    SET_TAGS: 'SET_TAGS',
    SET_SELECTED_FILTERS: 'SET_SELECTED_FILTERS'
  },
  TASK: {
    FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE',
    FETCH_DATA_REQUEST: 'FETCH_DATA_REQUEST',
    FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
    SET_TASK_STATE: 'SET_TASK_STATE',
    SET_CURRENT_TASK: 'SET_CURRENT_TASK',
    SET_TASK_QUERY_PARAMS: 'SET_TASK_QUERY_PARAMS',
    SET_SELECTED_FILTERS: 'SET_SELECTED_FILTERS'
  },
  USER: {
    SET_USER: 'SET_USER',
    SET_USERS: 'SET_USERS',
    SET_BASE_URL: 'SET_BASE_URL',
    SET_USER_STATE: 'SET_USER_STATE',
    SET_TABLE_SETTINGS: 'SET_TABLE_SETTINGS',
    SET_TABLE_PREFERENCES: 'SET_TABLE_PREFERENCES'
  },
  RESET: 'RESET',
  INVITATIONS: {
    SET_INVITATIONS: 'SET_INVITATIONS',
    SET_INVITATIONS_LOADING: 'SET_INVITATIONS_LOADING'
  },
  TABLE_CONFIGURATIONS: {
    SET_CONFIGURATIONS: 'SET_TABLE_CONFIGURATIONS',
    SET_CONFIGURATIONS_LOADING: 'SET_TABLE_CONFIGURATIONS_LOADING'
  }
};
