import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Input } from 'antd';
import classnames from 'classnames';

import './styles.scss';

function MultiSelectDropdown({ columns, onChange }) {
  const tablePreferences = useSelector(state => state.user.tablePreferences);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const options = columns.map(({ key, title, hidden }) => ({
    value: key,
    label: title,
    hidden
  }));
  const [selectedValues, setSelectedValues] = useState(
    options.reduce((acc, option) => (option.hidden ? acc : [...acc, option.value]), [])
  );
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = e => {
    setSearchValue(e.target.value.toLowerCase());
  };

  useEffect(() => {
    setSelectedValues(columns.reduce((acc, column) => (column.hidden ? acc : [...acc, column.key]), []));
  }, [tablePreferences, columns]);

  const clearAll = () => {
    setSelectedValues([]);
    onChange?.([]);
  };

  const selectAll = () => {
    setSelectedValues(options.map(value => value.value));
    onChange?.(options.map(value => value.value));
  };

  const filteredOptions = options.filter(option => option.label.toLowerCase().includes(searchValue));

  const handleCheckboxChange = checkedValue => {
    const updatedValues = selectedValues.includes(checkedValue)
      ? selectedValues.filter(val => val !== checkedValue)
      : [...selectedValues, checkedValue];

    setSelectedValues(updatedValues);
    onChange?.(updatedValues);
  };

  const menu = {
    style: { maxHeight: '500px', overflowY: 'auto' },
    items: [
      {
        key: 'search',
        label: (
          <div className="search-wrapper p-2">
            <Input
              placeholder="Search column"
              prefix={<SearchOutlined />}
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>
        ),
        disabled: true
      },
      {
        type: 'group',
        key: 'checkbox-group',
        label: (
          <div className="p-2">
            {filteredOptions.map(option => (
              <Checkbox
                key={option.value}
                className={classnames('option-item', 'p-1', {
                  'selected-option': selectedValues.includes(option.value)
                })}
                style={{ width: '100%' }}
                checked={selectedValues.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}>
                {option.label}
              </Checkbox>
            ))}
          </div>
        )
      },
      {
        key: 'selectAll',
        label: (
          <div className="d-flex justify-content-between p-2" style={{ borderTop: '1px solid #e9ecef ' }}>
            <Button size="small" type="link" disabled={!selectedValues.length} onClick={clearAll}>
              Clear all
            </Button>
            <Button size="small" type="primary" disabled={options.length === selectedValues.length} onClick={selectAll}>
              Select all
            </Button>
          </div>
        ),
        disabled: true
      }
    ]
  };

  return (
    <Dropdown
      overlayClassName="multi-select-dropdown"
      placement="bottomRight"
      open={isMenuOpen}
      menu={menu}
      onOpenChange={setIsMenuOpen}
      trigger={['click']}
      onCli
      overlayStyle={{
        backgroundColor: 'white',
        maxHeight: 500,
        overflowY: 'auto',
        width: '200px',
        boxShadow: '0 5px 10px rgba(0, 0, 0, .5)'
      }}>
      <Button variant="outlined">
        {selectedValues.length > 0 ? `Visible columns (${selectedValues.length})` : 'Select Options'}
        <DownOutlined style={{ marginLeft: '8px' }} />
      </Button>
    </Dropdown>
  );
}

export default memo(MultiSelectDropdown);
