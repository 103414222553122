import { memo, useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';

import { Card, Flex, Form, Input } from 'antd';

import messages from '../../constants/messages';

import './styles.css';

const { TextArea } = Input;

function GeofenceEditForm({
  circumference = 0,
  color,
  defaultName,
  description,
  farmId,
  formRef,
  isMaster = false,
  name,
  size = 0,
  handleColorChange,
  setSubmittable
}) {
  const [form] = Form.useForm();
  const [currentColor, setCurrentColor] = useState();

  if (formRef) formRef.current = form;

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [values]);

  useEffect(() => {
    form.setFieldsValue({ farmId, size, circumference });
  }, [farmId, size, circumference]);

  useEffect(() => {
    if (color) setCurrentColor(color);
  }, [color]);

  function changeCurrentColor({ hex }) {
    setCurrentColor(hex);
    handleColorChange(hex);
  }

  return (
    <Card title={name ? `Geofence ${name} Details` : 'Geofence Details'} className="mb-2">
      <Form
        initialValues={{ name: defaultName, description, farmId, size, circumference }}
        className="geofence-edit-form"
        name="basic"
        form={form}
        labelCol={{ span: 4 }}
        requiredMark={false}
        autoComplete="off">
        <Flex vertical={true} gap="small">
          <Form.Item
            label="Geofence Name"
            name="name"
            colon={false}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: messages.DATA_REQUIRED
              }
            ]}>
            <Input disabled={isMaster} />
          </Form.Item>

          <Form.Item label="Farm Name" name="farmId" colon={false} labelAlign="left">
            <Input disabled readOnly />
          </Form.Item>

          <Form.Item label="Size (ha)" name="size" colon={false} labelAlign="left">
            <Input disabled readOnly />
          </Form.Item>

          <Form.Item label="Perimeter (km)" name="circumference" colon={false} labelAlign="left">
            <Input disabled readOnly />
          </Form.Item>

          <Form.Item label="Description" name="description" colon={false} labelAlign="left">
            <TextArea className="textarea" />
          </Form.Item>

          <Form.Item label="Geofence Color" labelAlign="left" name="color" colon={false}>
            <CompactPicker name="colour" color={currentColor} onChange={changeCurrentColor} />
          </Form.Item>
        </Flex>
      </Form>
    </Card>
  );
}

export default memo(GeofenceEditForm);
