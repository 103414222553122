import { memo, useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';
import { useSelector } from 'react-redux';

import { Card, ConfigProvider, Flex, Form, Input, InputNumber, Select } from 'antd';
import classnames from 'classnames';

import { numberWithCommas } from '../../../helpers/common';
import { isFieldAvailable } from '../../../helpers/subscription';

import { FARM_EDIT_FIELDS, FIELD_TYPE } from '../../../constants/farm';
import { FEATURE_LIST } from '../../../constants/subscription';

import countries from '../../../countries.json';

const { TextArea } = Input;

function FarmEditForm({ color, editing, farm, formRef, handleColorChange, setSubmittable }) {
  const { myPlan } = useSelector(state => state.subscription);
  const [form] = Form.useForm();
  const [currentColor, setCurrentColor] = useState();

  if (formRef) formRef.current = form;

  const values = Form.useWatch([], form);

  const formattedOrder = FARM_EDIT_FIELDS.filter(field =>
    isFieldAvailable(field.id, myPlan?.type, FEATURE_LIST.FARM_DETAILS_FIELDS)
  );

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [values]);

  useEffect(() => {
    if (color) setCurrentColor(color);
  }, [color]);

  useEffect(() => {
    if (farm) {
      form.setFieldsValue(prepareFormValues());
    }
  }, [farm, editing]);

  function prepareFormValues() {
    const initValues = {};

    for (let field of formattedOrder) {
      let value = farm?.[field.id] ? farm?.[field.id] : field.type === FIELD_TYPE.NUMBER ? 0 : '';

      switch (field.id) {
        case 'street':
          value = farm['address'].street;
          break;
        case 'house_number':
          value = farm.address?.house_number || '/';
          break;
        case 'city':
          value = farm.address?.city || '';
          break;
        case 'postal_code':
          value = farm.address?.postal_code || '/';
          break;
        case 'country':
          value = farm.address?.country || '';
          break;
        case 'circumference':
        case 'size':
          value = value ? numberWithCommas(value) : '/';
          break;
        case 'herd_number':
          value = Array.isArray(farm?.herd_numbers) ? farm?.herd_numbers?.at(0) : farm?.herd_numbers || '0';
          break;
        default:
          if (Array.isArray(value)) {
            if (editing) {
              if (field.id !== 'geofences') {
                value = value.map(e => e.name).join(', ');
              }
            } else {
              value = value
                .filter(e => !e.is_master)
                .map(e => e.name)
                .join(', ');
            }
          }
          break;
      }

      initValues[field.id] = value;
    }

    return initValues;
  }

  function changeCurrentColor({ hex }) {
    setCurrentColor(hex);
    handleColorChange(hex);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextDisabled: 'rgba(0,0,0,0.88)'
        }
      }}>
      <Card title={`Farm ${farm?.name} Details`} className="mb-2">
        <Form
          className="farm-edit-form"
          name="farm-edit"
          form={form}
          initialValues={prepareFormValues()}
          labelCol={{ span: 6 }}
          requiredMark={false}
          autoComplete="off">
          <Flex vertical={true} gap="small">
            {formattedOrder?.map(field => {
              return (
                <Form.Item
                  labelWrap
                  key={field.id}
                  label={field.name}
                  name={field.id}
                  colon={false}
                  labelAlign="left"
                  rules={field.rules}>
                  {field.type === FIELD_TYPE.SELECT ? (
                    <Select
                      className={classnames('country-select', { 'hidden-arrow': !editing })}
                      disabled={!editing}
                      name={field.id}
                      options={countries.map(x => ({ value: x, label: x }))}
                      variant={editing ? 'outlined' : 'borderless'}
                    />
                  ) : field.type === FIELD_TYPE.TEXTAREA ? (
                    <TextArea
                      className="textarea"
                      name={field.id}
                      disabled={!editing}
                      variant={editing ? 'outlined' : 'borderless'}
                    />
                  ) : field.type === FIELD_TYPE.NUMBER ? (
                    <InputNumber
                      changeOnWheel
                      className="full-width"
                      disabled={!editing || !field.editable}
                      min={0}
                      name={field.id}
                      variant={editing && field.editable ? 'outlined' : 'borderless'}
                    />
                  ) : (
                    <Input
                      disabled={!editing || !field.editable}
                      name={field.id}
                      variant={editing && field.editable ? 'outlined' : 'borderless'}
                    />
                  )}
                </Form.Item>
              );
            })}

            {editing && (
              <Form.Item label="Geofence Color" labelAlign="left" name="color" colon={false}>
                <CompactPicker name="colour" color={currentColor} onChange={changeCurrentColor} />
              </Form.Item>
            )}
          </Flex>
        </Form>
      </Card>
    </ConfigProvider>
  );
}

export default memo(FarmEditForm);
