export const getUserRoles = roles => {
  if (!roles?.length) return null;
  return roles.map(item => item.name);
};

export const includesRole = (rules, roles) => {
  if (!rules?.length || !roles?.length) return false;

  for (let i = 0; i < roles?.length; i++) {
    if (rules.indexOf(roles[i]) !== -1) return true;
  }
  return false;
};

export const hasPermission = (requiredPermissions, userPermissions) =>
  requiredPermissions.some(permission => !!userPermissions?.includes(permission));
