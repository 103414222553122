import { REDUCERS } from '../../constants/reducers';

const initialState = {
  loading: false,
  invitations: []
};

const invitation = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.INVITATIONS.SET_INVITATIONS:
      return {
        ...state,
        invitations: action.value
      };

    case REDUCERS.INVITATIONS.SET_INVITATIONS_LOADING:
      return { ...state, loading: action.value };

    case REDUCERS.RESET:
      return initialState;

    default:
      return state;
  }
};

export { invitation };
