import { createAsyncThunk } from '@reduxjs/toolkit';

import { formatArrayToFilterOptions, formatArrayToOptions } from '../../helpers/common';

import { FarmService } from '../../services';

export const createFarm = createAsyncThunk('farm/createFarm', async (data, { rejectWithValue }) => {
  try {
    const response = await FarmService.createFarm(data);

    return response?.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to create farm');
  }
});

export const fetchFarms = createAsyncThunk('farm/fetchFarms', async (params, { rejectWithValue }) => {
  try {
    const response = await FarmService.fetchFarms(params);
    const payload = {
      list: response.data,
      options: formatArrayToOptions(response.data, 'name', 'id'),
      filterOptions: formatArrayToFilterOptions(response.data, 'name', 'id')
    };
    return payload;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch farms');
  }
});

export const fetchFarm = createAsyncThunk('farm/fetchFarm', async (id, { rejectWithValue }) => {
  try {
    const { data } = await FarmService.fetchFarm(id);

    return data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch farm');
  }
});

export const updateFarm = createAsyncThunk('farm/updateFarm', async ({ id, data }, { rejectWithValue }) => {
  try {
    await FarmService.updateFarm(id, data);
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to update farm');
  }
});

export const deleteFarms = createAsyncThunk('farm/deleteFarms', async (ids, { rejectWithValue }) => {
  try {
    await FarmService.deleteFarms(ids);

    return ids;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to delete farms');
  }
});

export const deleteFarm = createAsyncThunk('farm/deleteFarm', async (id, { rejectWithValue }) => {
  try {
    await FarmService.deleteFarm(id);

    return id;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to delete farm');
  }
});
