import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Table } from 'reactstrap';

import { Button, Card, Typography } from 'antd';
import moment from 'moment/moment';

import { capitalize } from '../../helpers/common';
import { formatDateDifference } from '../../helpers/date';

import { DATE_FORMAT } from '../../constants/common';

import default_animal from '../../assets/images/default-animal.jpg';
import { col_1, col_2 } from './constants';

const { Title } = Typography;

function LivestockDetails({ animal }) {
  const COL1_ROWS = [];
  const COL2_ROWS = [];
  if (animal) {
    // COLOUMN 1 - DATA
    for (const key of Object.keys(col_1)) {
      const label = col_1[key]['lable'] || '';
      let value = animal[key];

      if (key === 'eartag_management_id' && value.indexOf('mgmt~tmp~') > -1) {
        value = '';
      } else if (key === 'sex') {
        if (value === 'male') value = 'Male';
        else if (value === 'female') value = 'Female';
      } else if (key === 'kraal_tag_id') {
        if (animal[key]) {
          value = animal[key];
        } else {
          value = animal['tag'];
          value = value && value.diagri_id ? <Link to={`/tag/${value.id}`}>{value.diagri_id}</Link> : '';
        }
      } else if (key === 'stock_type') {
        value = capitalize(value);
      } else if (key === 'breed' && value?.display_name) {
        value = value?.display_name;
      } else if (value === null) {
        value = '';
      }

      COL1_ROWS.push(
        <tr key={key}>
          <td>{label}</td>
          <td>{value}</td>
        </tr>
      );
    }

    // COLOUMN 2 - DATA
    for (const key of Object.keys(col_2)) {
      const label = col_2[key]['lable'] || '';
      let value = animal[key];

      if (key === 'dob_at' && value && value != '' && value != '0000-00-00 00:00:00') {
        value = new Date(value);
        value = moment(value).format(DATE_FORMAT.DATE);
      }
      if (key === 'geofences') {
        value = value.filter(s => s.name.indexOf('Master Geofence') === -1);
        value =
          value && value.length > 0
            ? value
                .map(x => (
                  <Link key={x.id} to={`/geofence/${x.id}`}>
                    {x.name}
                  </Link>
                ))
                .reduce((prev, curr) => [prev, ', ', curr]) || ''
            : '';
      } else if (key === 'age') {
        value = formatDateDifference(animal['dob_at']);
      } else if (key === 'last_reading') {
        value = moment(animal?.tag?.timestamp_at).format(DATE_FORMAT.DATETIME);
      } else if (key === 'last_notification') {
        value = moment(animal?.last_notification?.created_at).format(DATE_FORMAT.DATETIME);
      } else if (value === null) {
        value = '';
      }

      COL2_ROWS.push(
        <tr key={key}>
          <td>{label}</td>
          <td>{value}</td>
        </tr>
      );
    }
  }

  return (
    <Row>
      <Col lg="12">
        <Card className="p-0 mb-1" style={{ padding: 0 }}>
          <Row>
            <Col sm="9" style={{ padding: '0px 15px' }}>
              <Title level={4}>Livestock {animal.identifier} Details</Title>
            </Col>
            <Col sm="3">
              <Link to={`/animal/${animal.id}`}>
                <Button className="float-right mb-2" color="primary" variant="outlined">
                  More Details
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <Table className="no-border less-pad dual-text animal-card-table">
                <tbody>{COL1_ROWS}</tbody>
              </Table>
            </Col>
            <Col sm="5">
              <Table className="no-border less-pad dual-text animal-card-table">
                <tbody>{COL2_ROWS}</tbody>
              </Table>
            </Col>
            <Col sm="3" className="animal-photo text-right tracking-detail">
              <Card className="float-right">
                <img src={animal.photo || default_animal} className="card-img-top ieFix" width="240" height="176" />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default memo(LivestockDetails);
