import messages from './messages';

const COMMON_KEYS = {
  city: 'Farm City / Town',
  country: 'Farm Country',
  description: 'Farm Description',
  full_address: 'Farm Address',
  herd_number: 'Herd #',
  house_number: 'House number / Name',
  identifier: 'Farm ID',
  name: 'Farm Name',
  postal_code: 'Farm Postal Code',
  size: 'Farm Size (ha)',
  street: 'Farm Street Name'
};

export const KEY_MAP = {
  ...COMMON_KEYS,
  actual_livestock: 'Actual Tracked Livestock',
  address: 'Farm Address',
  animals_count: 'Assigned Livestock',
  circumference: 'Farm Perimeter (km)',
  expected_livestock: 'Expected Tracked Livestock',
  geofence_id: 'Farm Master Geofence ID'
};

export const NEW_FARM_KEY_MAP = {
  ...COMMON_KEYS,
  circumference: 'Circumference (km)',
  days_grazed: 'Days Grazed',
  farm_rules: 'Farm Rules',
  geofence_id: 'Geofence ID',
  geofences: 'Geofences'
};

export const ORDER = [
  'identifier',
  'name',
  'address',
  'house_number',
  'city',
  'postal_code',
  'country',
  'size',
  'circumference',
  'herd_numbers',
  'animals_count',
  'expected_livestock',
  'actual_livestock',
  'description'
];

export const FIELD_TYPE = {
  INPUT: 'INPUT',
  NUMBER: 'NUMBER',
  SELECT: 'SELECT',
  TEXTAREA: 'TEXTAREA'
};

export const FARM_CREATE_FIELDS = [
  {
    id: 'name',
    type: FIELD_TYPE.INPUT,
    name: NEW_FARM_KEY_MAP['name'],
    rules: [
      {
        required: true,
        message: messages.DATA_REQUIRED
      }
    ]
  },
  {
    id: 'house_number',
    type: FIELD_TYPE.INPUT,
    name: NEW_FARM_KEY_MAP['house_number']
  },
  {
    id: 'street',
    type: FIELD_TYPE.INPUT,
    name: NEW_FARM_KEY_MAP['street'],
    rules: [
      {
        required: true,
        message: messages.DATA_REQUIRED
      }
    ]
  },
  {
    id: 'city',
    type: FIELD_TYPE.INPUT,
    name: NEW_FARM_KEY_MAP['city'],
    rules: [
      {
        required: true,
        message: messages.DATA_REQUIRED
      }
    ]
  },
  {
    id: 'postal_code',
    type: FIELD_TYPE.INPUT,
    name: NEW_FARM_KEY_MAP['postal_code']
  },
  {
    id: 'country',
    type: FIELD_TYPE.SELECT,
    name: NEW_FARM_KEY_MAP['country']
  },
  {
    id: 'herd_number',
    type: FIELD_TYPE.NUMBER,
    name: NEW_FARM_KEY_MAP['herd_number']
  },
  {
    id: 'description',
    editable: true,
    type: FIELD_TYPE.TEXTAREA,
    name: NEW_FARM_KEY_MAP['description']
  }
];

export const FARM_EDIT_FIELDS = [
  {
    id: 'identifier',
    editable: false,
    type: FIELD_TYPE.INPUT,
    name: KEY_MAP['identifier']
  },
  {
    id: 'name',
    editable: true,
    type: FIELD_TYPE.INPUT,
    rules: [
      {
        required: true,
        message: messages.DATA_REQUIRED
      }
    ],
    name: KEY_MAP['name']
  },
  {
    id: 'house_number',
    editable: true,
    type: FIELD_TYPE.INPUT,
    name: KEY_MAP['house_number']
  },
  {
    id: 'street',
    editable: true,
    type: FIELD_TYPE.INPUT,
    name: NEW_FARM_KEY_MAP['street'],
    rules: [
      {
        required: true,
        message: messages.DATA_REQUIRED
      }
    ]
  },
  {
    id: 'city',
    editable: true,
    type: FIELD_TYPE.INPUT,
    name: KEY_MAP['city']
  },
  {
    id: 'postal_code',
    editable: true,
    type: FIELD_TYPE.INPUT,
    name: KEY_MAP['postal_code']
  },
  {
    id: 'country',
    editable: true,
    type: FIELD_TYPE.SELECT,
    name: KEY_MAP['country']
  },
  {
    id: 'size',
    editable: false,
    type: FIELD_TYPE.NUMBER,
    name: KEY_MAP['size']
  },
  {
    id: 'circumference',
    editable: false,
    type: FIELD_TYPE.NUMBER,
    name: KEY_MAP['circumference']
  },
  {
    id: 'herd_number',
    editable: true,
    type: FIELD_TYPE.INPUT,
    name: KEY_MAP['herd_number']
  },
  {
    id: 'animals_count',
    editable: true,
    type: FIELD_TYPE.NUMBER,
    name: KEY_MAP['animals_count']
  },
  {
    id: 'expected_livestock',
    editable: false,
    type: FIELD_TYPE.NUMBER,
    name: KEY_MAP['expected_livestock']
  },
  {
    id: 'actual_livestock',
    editable: false,
    type: FIELD_TYPE.NUMBER,
    name: KEY_MAP['actual_livestock']
  },
  {
    id: 'description',
    editable: true,
    type: FIELD_TYPE.TEXTAREA,
    name: KEY_MAP['description']
  }
];

export const ADDRESS = ['address', 'street', 'house_number', 'city', 'postal_code', 'country'];

export const blacklist_edit = [
  'circumference',
  'geofence_id',
  'herd_nr',
  'identifier',
  'rules',
  'size',
  'total_expected_livestock'
];

export const blacklist_full = ['full_address', 'user_id', 'address_id', 'created_at', 'updated_at', 'id'];

export const blacklist_create = [
  'address',
  'circumference',
  'days_grazed',
  'farm_rules',
  'full_address',
  'geofence_id',
  'geofences',
  'id',
  'master_geofence',
  'size'
];
