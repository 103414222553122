import { createSlice } from '@reduxjs/toolkit';

import { fetchMatingEventTypes } from '../actions/mating-event';

const matingEventSlice = createSlice({
  name: 'matingEvent',
  initialState: {
    data: null,
    loading: false,
    error: null,
    filterOptions: [],
    selectOptions: []
  },
  extraReducers: builder => {
    builder
      // Fetch Mating Event Types
      .addCase(fetchMatingEventTypes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMatingEventTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.filterOptions = action.payload.filterOptions;
        state.selectOptions = action.payload.selectOptions;
      })
      .addCase(fetchMatingEventTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default matingEventSlice.reducer;
