import { memo } from 'react';
import { Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';

import { Typography } from 'antd';

import { capitalize } from '../../helpers/common';

const { Paragraph, Text, Title } = Typography;

function MapPopup({ name, additionalData, description, link }) {
  return (
    <>
      {name && (
        <Popup>
          <Title level={5} ellipsis={true}>
            {name}
          </Title>
          {additionalData && <Paragraph type="secondary">{capitalize(additionalData)}</Paragraph>}
          {description && <Paragraph>{description}</Paragraph>}
          {link && (
            <Link to={link}>
              <Text type="success">More details</Text>
            </Link>
          )}
        </Popup>
      )}
    </>
  );
}

export default memo(MapPopup);
