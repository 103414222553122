export const ROLES = {
  ADMIN: {
    value: 'admin',
    label: 'Administrator'
  },
  KEEPER: {
    value: 'keeper',
    label: 'Keeper'
  },
  EMPLOYEE: {
    value: 'employee',
    label: 'Employee'
  },
  CONSULTANT: {
    value: 'consultant',
    label: 'Farm Consultant'
  },
  VETERINARY: {
    value: 'veterinary',
    label: 'Veterinary'
  },
  OWNER: {
    value: 'owner',
    label: 'Livestock Owner'
  }
};

export const ROLES_TO_SHOW_SUBSCRIPTION_TAB = [ROLES.KEEPER.value];
