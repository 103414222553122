import { memo, useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';

import { Card, Flex, Form, Input, InputNumber, Select } from 'antd';

import { FARM_CREATE_FIELDS, FIELD_TYPE } from '../../../constants/farm';

import countries from '../../../countries.json';

const { TextArea } = Input;

function FarmCreateForm({ address, color, formRef, handleColorChange, setSubmittable }) {
  const [form] = Form.useForm();
  const [currentColor, setCurrentColor] = useState();

  if (formRef) formRef.current = form;

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [values]);

  useEffect(() => {
    if (color) setCurrentColor(color);
  }, [color]);

  useEffect(() => {
    if (address) {
      prepareFormValues();
    }
  }, [address]);

  function changeCurrentColor({ hex }) {
    setCurrentColor(hex);
    handleColorChange(hex);
  }

  function prepareFormValues() {
    return {
      city: address.city,
      country: address.country,
      herd_number: '0',
      house_number: address.house_number,
      postal_code: address.postal_code,
      street: address.street
    };
  }

  return (
    <Card title="Farm Details" className="mb-2">
      <Form
        className="farm-edit-form"
        name="farm-edit"
        form={form}
        initialValues={prepareFormValues()}
        labelCol={{ span: 6 }}
        requiredMark={false}
        autoComplete="off">
        <Flex vertical={true} gap="small">
          {FARM_CREATE_FIELDS?.map(field => {
            return (
              <Form.Item
                labelWrap
                key={field.id}
                label={field.name}
                name={field.id}
                colon={false}
                labelAlign="left"
                rules={field.rules}>
                {field.type === FIELD_TYPE.SELECT ? (
                  <Select name={field.id} options={countries.map(x => ({ value: x, label: x }))} />
                ) : field.type === FIELD_TYPE.TEXTAREA ? (
                  <TextArea className="textarea" name={field.id} />
                ) : field.type === FIELD_TYPE.NUMBER ? (
                  <InputNumber changeOnWheel className="full-width" min={0} name={field.id} />
                ) : (
                  <Input name={field.id} />
                )}
              </Form.Item>
            );
          })}

          <Form.Item label="Geofence Color" labelAlign="left" name="color" colon={false}>
            <CompactPicker name="colour" color={currentColor} onChange={changeCurrentColor} />
          </Form.Item>
        </Flex>
      </Form>
    </Card>
  );
}

export default memo(FarmCreateForm);
