import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card } from 'antd';

import { hasPermission } from '../../../helpers/user';

import { DEFAULT_PAGE_SIZE } from '../../../constants/common';
import { PERMISSIONS } from '../../../constants/permissions';
import { TASK_MODAL_MODE } from '../../../constants/task';

import { setSelectedFilters } from '../../../redux/actions/task';
import { getUsers } from '../../../redux/actions/user';

import TaskModal from '../../../components/Modals/TaskModal';
import Tasks from '../../../components/Tasks';

const MyTasks = () => {
  const dispatch = useDispatch();
  const { selectedFilters } = useSelector(state => state.task);
  const user = useSelector(state => state.user);
  const [isOpenTaskModal, setOpenTaskModal] = useState(false);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    dispatch(getUsers());
    initActions();
  }, []);

  useEffect(() => {
    initActions();
  }, [selectedFilters]);

  const clearFilters = () => {
    dispatch(setSelectedFilters({}));
  };

  const onAddTaskClick = () => {
    setOpenTaskModal(true);
  };

  const onSubmit = () => {
    setOpenTaskModal(false);
  };

  function initActions() {
    const availableActions = [];

    if (hasPermission([PERMISSIONS.TASKS.CREATE], user.permissions)) {
      availableActions.push({
        label: (
          <Button color="default" size="small" variant="link" onClick={onAddTaskClick}>
            Add task
          </Button>
        )
      });
    }

    availableActions.push({
      label: (
        <Button
          color="default"
          size="small"
          variant="link"
          disabled={!Object.values(selectedFilters)?.length}
          onClick={clearFilters}>
          Clear all filters
        </Button>
      )
    });

    setActions(availableActions);
  }

  return (
    <>
      <Card>
        <Tasks
          searchPlaceholder="Search tasks"
          title="Tasks"
          actions={actions}
          queryParameters={{ limit: DEFAULT_PAGE_SIZE }}
          rowsSelectable={false}
          searchable={true}
          settings={true}
        />
      </Card>

      <TaskModal
        onSubmit={onSubmit}
        isOpen={isOpenTaskModal}
        onCancel={() => setOpenTaskModal(false)}
        mode={TASK_MODAL_MODE.CREATE}
      />
    </>
  );
};

export default memo(MyTasks);
