import { Component } from 'react';
import { connect } from 'react-redux';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap';

import moment from 'moment';

import axios from '../../../axios';

import { hasPermission } from '../../../helpers/user';

import { DATE_FORMAT } from '../../../constants/common';
import messages from '../../../constants/messages';
import { PERMISSIONS } from '../../../constants/permissions';

import { errorToastHandler } from '../../../components/action_notifier';

import AppDropdown from '../../ui-components/AppDropdown';

class EditTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '1',
      delete_modal: false,
      editData: {},
      editing: false,
      selected_geofences: [],
      tag: {}
    };

    this.enableEditing = this.enableEditing.bind(this);
    this.initActions = this.initActions.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveTag = this.saveTag.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {
    this.getTag();
    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: 'Edit Kraal tag',
          isVisible: () => hasPermission([PERMISSIONS.TAGS.UPDATE_ALL], this.props.user.permissions),
          onClick: () => this.enableEditing()
        },
        {
          label: 'Archive Kraal tag',
          isVisible: () => hasPermission([PERMISSIONS.TAGS.DELETE_ALL], this.props.user.permissions),
          onClick: () => this.setDeleteTags()
        }
      ]
    });
  }

  toggleModal(modal) {
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  async deleteTag() {
    const response = await axios.delete('tags/archive', {
      data: {
        ids: [this.state.tag.id]
      }
    });

    if (response.status === 200) {
      this.getTag();
      this.toggleModal('delete_modal');
    }
  }

  async getTag() {
    const id = this.props.match.params.id;
    const response = await axios.get('tags/admin/' + id);

    if (response.status === 200) this.setState(state => ({ ...state, tag: response.data }));
  }

  onCancelEdit = (value, field) => {
    this.setState(state => ({ ...state, editData: this.state.tag, [field]: value }));
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  enableEditing() {
    this.setState(state => ({
      ...state,
      editing: true,
      editData: { ...this.state.tag }
    }));
  }

  onChange = (value, field) => {
    this.setState(state => ({ ...state, editData: { ...state.editData, [field]: value } }));
  };

  setDeleteTags() {
    this.setState({ ...this.state, delete_tags: this.state.selected_tags });
    this.toggleModal('delete_modal');
  }

  async saveTag() {
    const id = this.props.match.params.id;
    const editData = this.state.editData;

    try {
      const response = await axios.put('tags/' + id, { ...editData });

      if (response.status === 200) {
        this.getTag();
        this.setState({ ...this.state, editing: false });
      }
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }
  }

  render() {
    if (!this.state.tag.id) return null;

    return (
      <>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4 className="">
              {this.state.editing && 'Edit '}Tag
              {this.state.tag.name || this.state.tag.appeui}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={() => this.props.history.push('/administration/tags')}>
                  List of Kraal Tags
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.editing && 'Edit '}Tag {this.state.tag.name || this.state.tag.appeui}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            {this.state.editing && (
              <>
                <Button color="primary" className="float-right" onClick={() => this.saveTag()}>
                  Save
                </Button>
                <Button className="outline float-right" onClick={() => this.onCancelEdit(false, 'editing')}>
                  Cancel
                </Button>
              </>
            )}
            {!this.state.editing && !this.state.tag.deleted_at && (
              <AppDropdown
                className="d-flex justify-content-end"
                label="Actions"
                items={this.state.actions.filter(item => item.isVisible())}
                handleClick={action => action.handler()}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <div className="p-30  global-heading-tag">
                    <h5 className="details-title">Tag {this.state.tag.name || this.state.tag.appeui}</h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td className="v-align-middle">
                          <b>Tag Id</b>
                        </td>
                        <td>{this.state.tag.id || '/'}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Identifier</b>
                        </td>
                        <td>
                          <span>{this.state.tag.identifier || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Farm Id</b>
                        </td>
                        <td>
                          <span>{this.state.tag.farm_id || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Farm Name</b>
                        </td>
                        <td>
                          <span>{this.state.tag.farm_name || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway ID</b>
                        </td>
                        <td>
                          <span>{this.state.tag.gateway_id || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway Name</b>
                        </td>
                        <td>
                          <span>{this.state.tag.gateway_name || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Type</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'type'}
                              defaultValue={this.state.editData.type}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.tag.type || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Deveui</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'deveui'}
                              defaultValue={this.state.editData.deveui}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.tag.deveui || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Diagri ID</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'diagri_id'}
                              defaultValue={this.state.editData.diagri_id}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.tag.diagri_id || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Version</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'diagri_id'}
                              defaultValue={this.state.editData.version}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.tag.version || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Firmware Version</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'firmware_version'}
                              defaultValue={this.state.editData.firmware_version}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.tag.firmware_version || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Created at</b>
                        </td>
                        <td>
                          {(this.state.tag.created_at &&
                            moment(this.state.tag.created_at).format(DATE_FORMAT.DATETIME)) ||
                            '/'}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Updated at</b>
                        </td>
                        <td>
                          {(this.state.tag.updated_at &&
                            moment(this.state.tag.updated_at).format(DATE_FORMAT.DATETIME)) ||
                            '/'}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Deleted at</b>
                        </td>
                        <td>
                          {(this.state.tag.deleted_at &&
                            moment(this.state.tag.deleted_at).format(DATE_FORMAT.DATETIME)) ||
                            '/'}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={this.state.delete_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive tags</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive selected tags? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('delete_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteTag()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default connect(state => state)(EditTag);
