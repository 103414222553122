import { Component, createRef } from 'react';

import BreedingTable from '../../components/Breeding';
import Filters from '../../components/filters/index.jsx';

export default class Breeding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      filters_open: false,
      isTabInAction: false,
      tabType: 'breeding',
      tab_action: ''
    };

    this.filtersClose = this.filtersClose.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);

    this.breedingTab = createRef();
  }

  filtersUpdated(filters) {
    this.breedingTab.current.filterData(filters);
  }

  filtersClose() {
    this.setState(state => ({ ...state, filters_open: false }));
  }

  toggleModal(modal) {
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  render() {
    return (
      <>
        <BreedingTable className="card p-2" breadcrumb="List of Livestock breeding" title="Livestock breeding" />

        <Filters
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm', 'geofence', 'label']}
        />
      </>
    );
  }
}
