import { useEffect, useRef, useState } from 'react';
import { FeatureGroup, LayerGroup, LayersControl, MapContainer } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';

import classnames from 'classnames';
import L from 'leaflet';
import isEmpty from 'lodash.isempty';

import { GEOMETRY_TYPE } from '../../constants/map';

import EditButtons from './EditButtons';
import MapBaseLayers from './MapBaseLayers';
import MapSiteFeature from './MapSiteFeature';
import Shape from './Shape';
import {
  EDIT_CONTROL_DRAW_OPTIONS,
  EDIT_CONTROL_POSITION,
  FULLSCREEN_CONTROL_POSITION,
  LAYERS_CONTROL_POSITION,
  SHAPE_OPTIONS,
  ZOOM,
  ZOOM_MAX
} from './constants';
import './styles.scss';

const { Overlay } = LayersControl;

const customizeButtonLabels = () => {
  L.drawLocal.edit.toolbar.actions.save.text = 'Finish';
};

export default function MapSite(props) {
  const featureGroupRef = useRef(null);
  const mapRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [state, setState] = useState({
    bounds: [
      [-31.0, 13.0],
      [-31.0, 33.0]
    ],
    color: props.color,
    editing: props.editing,
    enableDraw: !!props.new,
    farm: props.farm,
    features: props.site.features,
    geofences: [],
    leaflet_id: null,
    new: props.new,
    position: [-29.132312, 26.057016],
    set: false,
    site: props.site
  });

  const initializeFeatureGroup = () => {
    if (!featureGroupRef?.current) return;

    const fg = featureGroupRef.current;

    fg.clearLayers();

    if (isEmpty(props.site?.geo_json)) return;

    props.site?.geo_json?.properties?.radius
      ? drawCircle(fg, props.site?.geo_json?.geometry?.coordinates, props.site?.geo_json?.properties?.radius)
      : drawPolygon(fg, props.site?.geo_json);
  };

  function drawCircle(fg, coordinates, radius) {
    const [lat, lng] = [coordinates?.at(1), coordinates?.at(0)];
    const layer = L.circle([lat, lng], {
      ...SHAPE_OPTIONS,
      color: state.color,
      fillColor: state.color,
      radius
    });

    fg.addLayer(layer);
  }

  function drawPolygon(fg, geoJson) {
    L.geoJSON(geoJson, {
      style: {
        ...SHAPE_OPTIONS,
        color: state.color,
        fillColor: state.color
      }
    }).eachLayer(layer => fg.addLayer(layer));
  }

  const handleFullScreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement);
  };

  const clickEditButton = () => {
    const editButton = document.querySelector('a.leaflet-draw-edit-edit');

    if (editButton) editButton.click();
  };

  useEffect(() => {
    customizeButtonLabels();

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (props.farm && props.site) {
      initializeFeatureGroup();
      tick(props.farm, props.site);
    }
  }, [props.farm, props.site, props.selected]);

  useEffect(() => {
    toggleEditMode();

    if (props.editing) {
      setTimeout(clickEditButton, 300);
    }
  }, [props.editing]);

  useEffect(() => {
    handleColorChange();
  }, [props.color]);

  function toggleEditMode() {
    if (!mapRef.current) return;

    const mapContainer = mapRef?.current?.getContainer();

    props.editing ? mapContainer.classList.add('editing') : mapContainer.classList.remove('editing');
  }

  function handleColorChange() {
    if (!featureGroupRef.current) return;

    if (state.color !== props.color) {
      featureGroupRef.current.eachLayer(layer => {
        if (typeof layer.setStyle === 'function') {
          layer.setStyle({ color: props.color, fillColor: props.color, zIndex: 9999 });
        }
      });

      setState(prevState => ({ ...prevState, color: props.color }));
    }
  }

  function calculateBoundsForCircle(geoJson) {
    if (isEmpty(geoJson?.geometry)) return;

    let bounds = {};
    const { coordinates } = geoJson.geometry;
    const radius = geoJson.properties?.radius || 0;

    if (coordinates?.length === 2 && radius > 0) {
      const [lng, lat] = coordinates;
      const center = L.latLng(lat, lng);

      bounds = center.toBounds(radius * 2);

      return bounds;
    }
  }

  function calculateBoundsForPolygon(geoJson) {
    const group = new L.geoJson(geoJson);
    let bounds = group.getBounds();

    if (bounds.isValid()) {
      const corner2 = L.latLng(parseFloat(bounds._southWest?.lat), parseFloat(bounds._southWest?.lng));
      const corner1 = L.latLng(parseFloat(bounds._northEast?.lat), parseFloat(bounds._northEast?.lng));

      bounds = L.latLngBounds(corner1, corner2);

      return bounds;
    }
  }

  function calculateMapBounds(geoData) {
    // geoData can be FeatureCollection or GeoJSON
    const source = geoData?.geometry ? geoData : geoData?.features?.at(0);

    if (!source) return;

    const bounds =
      source.geometry?.type === GEOMETRY_TYPE.POINT && source.properties?.radius
        ? calculateBoundsForCircle(source)
        : calculateBoundsForPolygon(source);

    setState(prevState => ({ ...prevState, bounds }));

    if (mapRef?.current) {
      const center = bounds.getCenter();
      mapRef?.current?.setView(center, Math.min(mapRef?.current?.getBoundsZoom(bounds), ZOOM_MAX));
    }
  }

  function tick(farm, site) {
    const geofences = { type: 'FeatureCollection', features: [] };
    const masterGeofences = { type: 'FeatureCollection', features: [] };
    const sites = { type: 'FeatureCollection', features: [] };

    if (isEmpty(site?.geo_json)) {
      featureGroupRef?.current?.clearLayers();
    }

    if (!farm?.meta?.features?.length && isEmpty(site?.geo_json)) return;

    farm?.meta?.features?.forEach(feature => {
      const target = feature.is_master ? masterGeofences.features : geofences.features;
      target.push({ ...feature.geo_json, color: feature.color });
    });

    farm?.meta?.sites?.forEach(s => {
      if (site?.geo_json?.properties?.identifier !== s.identifier) {
        sites.features.push({ ...s.geo_json, color: s.color });
      }
    });

    setState(prevState => ({
      ...prevState,
      features_geo: geofences,
      features_master: masterGeofences,
      features_sites: { ...sites, features: sites.features.filter(f => f.properties?.id !== site.id) },
      farm,
      site
    }));

    const center = site?.geo_json?.type
      ? site?.geo_json
      : props.selected?.geo_json
      ? props.selected?.geo_json
      : masterGeofences;

    calculateMapBounds(center);
  }

  function handleCreateShape(e) {
    setState(prevState => ({ ...prevState, enableDraw: !!props.new }));

    const layers = L.layerGroup().addLayer(e.layer);

    setState(prevState => ({ ...prevState, leaflet_id: e.layer._leaflet_id }));

    layers.eachLayer(a => {
      if (props.onCreate) {
        const geoJson = a.toGeoJSON();
        geoJson.properties.radius = a._mRadius ? a._mRadius : a._radius;

        props.onCreate(geoJson);
      }
    });
  }

  function handleEditShape(layerGroup) {
    layerGroup.layers.eachLayer(layer => {
      if (props.onEdit) {
        const geoJson = layer.toGeoJSON();

        if (layer._mRadius) {
          geoJson.properties.radius = layer._mRadius;
        } else if (layer._radius) {
          geoJson.properties.radius = layer._radius;
        }
        props.onEdit(geoJson);
      }
    });

    setState(prevState => ({ ...prevState, enableDraw: false }));
  }

  function handleDeleteShape() {
    setState(prevState => ({ ...prevState, enableDraw: true }));
  }

  function handleSave() {
    props.handleSaveEditing();
    exitFullScreenMode();
  }

  function exitFullScreenMode() {
    if (mapRef && mapRef?.current?.fullscreenControl) {
      document.exitFullscreen();
    }
  }

  return (
    <MapContainer
      className={classnames('map', { 'edit-controls-visible': props.new })}
      attributionControl={false}
      ref={mapRef}
      bounds={state.bounds}
      zoom={ZOOM}>
      <LayersControl collapsed={!L.Browser.mobile} position={LAYERS_CONTROL_POSITION}>
        <MapBaseLayers />

        <Overlay checked name="Site">
          <LayerGroup>
            {state?.features_master?.features?.map((item, index) => (
              <Shape key={JSON.stringify({ ...item, index })} item={item} path="site" />
            ))}

            {state?.features_geo?.features?.map((item, index) => (
              <Shape key={JSON.stringify({ ...item, index })} item={item} path="site" />
            ))}

            {state?.features_sites?.features
              ?.filter(item => item.geometry?.type)
              .map((item, index) => (
                <MapSiteFeature
                  color={item.color}
                  type={item?.geometry?.type}
                  geometry={item?.geometry?.coordinates}
                  properties={item.properties}
                  key={JSON.stringify({ ...item, index })}
                />
              ))}

            <FeatureGroup ref={featureGroupRef}>
              <EditControl
                draw={{
                  ...EDIT_CONTROL_DRAW_OPTIONS,
                  circle: props.editing &&
                    state.enableDraw && {
                      shapeOptions: { ...SHAPE_OPTIONS, color: state.color, fillColor: state.color }
                    },
                  polygon: props.editing &&
                    state.enableDraw && {
                      shapeOptions: { ...SHAPE_OPTIONS, color: state.color, fillColor: state.color }
                    }
                }}
                edit={{ featureGroup: featureGroupRef?.current, edit: true, remove: true }}
                position={EDIT_CONTROL_POSITION}
                onCreated={handleCreateShape}
                onDeleted={handleDeleteShape}
                onEdited={handleEditShape}
              />
              {/*{state.site?.geo_json?.geometry && !state.new && (*/}
              {/*  <MapSiteFeature*/}
              {/*    color={state.color}*/}
              {/*    geometry={state.site?.geo_json?.geometry?.coordinates}*/}
              {/*    new={state.new}*/}
              {/*    properties={state.site?.geo_json?.properties}*/}
              {/*    type={state.site?.geo_json?.geometry?.type}*/}
              {/*  />*/}
              {/*)}*/}
            </FeatureGroup>
          </LayerGroup>

          {!L.Browser.mobile && <FullscreenControl position={FULLSCREEN_CONTROL_POSITION} />}
          {isFullScreen && props.editing && (
            <EditButtons handleCancel={props.handleCancelEditing} handleReturn={handleSave} />
          )}
        </Overlay>
      </LayersControl>
    </MapContainer>
  );
}
