import { createAsyncThunk } from '@reduxjs/toolkit';

import { formatArrayToFilterOptions, formatArrayToOptions } from '../../helpers/common';

import { MatingEventService } from '../../services';

export const fetchMatingEventTypes = createAsyncThunk(
  'matingEvent/fetchMatingEventTypes',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await MatingEventService.fetchMatingEventTypes(params);

      return {
        data,
        filterOptions: formatArrayToFilterOptions(data, 'mating_type_name', 'id'),
        selectOptions: formatArrayToOptions(data, 'mating_type_name', 'id')
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch mating event types');
    }
  }
);
