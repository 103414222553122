import { useEffect, useRef, useState } from 'react';
import { FeatureGroup, LayerGroup, LayersControl, MapContainer } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';

import classnames from 'classnames';
import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';

import { GEOMETRY_TYPE } from '../../constants/map';

import EditButtons from './EditButtons';
import Feature from './Feature';
import MapBaseLayers from './MapBaseLayers';
import MapPopup from './MapPopup';
import MapSiteFeature from './MapSiteFeature';
import Shape from './Shape';
import {
  EDIT_CONTROL_DRAW_OPTIONS,
  EDIT_CONTROL_POSITION,
  FULLSCREEN_CONTROL_POSITION,
  LAYERS_CONTROL_POSITION,
  SHAPE_OPTIONS,
  TYPE,
  ZOOM,
  ZOOM_MAX
} from './constants';
import './styles.scss';

const provider = new OpenStreetMapProvider();
const { Overlay } = LayersControl;

const customizeButtonLabels = () => {
  L.drawLocal.edit.toolbar.actions.save.text = 'Finish';
};

function MapSimple(props) {
  const featureGroupRef = useRef(null);
  const mapRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [state, setState] = useState({
    bounds: null,
    color: props.color,
    enableDraw: !!props.isNew,
    farm: props.farm,
    geofences: [],
    masterGeofences: [],
    position: [-29.132312, 26.057016],
    sites: { features: [] }
  });

  const initializeFeatureGroup = () => {
    if (!featureGroupRef?.current) return;

    const fg = featureGroupRef.current;

    fg.clearLayers();

    if (isEmpty(props.farm?.master_geofence?.geo_json)) return;

    props.farm?.master_geofence?.geo_json?.properties?.radius
      ? drawCircle(
          fg,
          props.farm?.master_geofence?.geo_json?.geometry.coordinates,
          props.farm?.master_geofence?.geo_json?.properties.radius
        )
      : drawPolygon(fg, props.farm?.master_geofence?.geo_json);
  };

  const handleFullScreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement);
  };

  const clickEditButton = () => {
    const editButton = document.querySelector('a.leaflet-draw-edit-edit');

    if (editButton) editButton.click();
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (props.farm) {
      customizeButtonLabels();
      initializeFeatureGroup();
      tick();
    }
  }, [props.farm]);

  const debounceSearch = debounce(cb => {
    cb();
  }, 500);

  useEffect(() => {
    if (props.defaultPosition?.latitude) {
      if (mapRef?.current) {
        mapRef?.current?.setView([props.defaultPosition?.latitude, props.defaultPosition?.longitude], ZOOM);
      }
    } else {
      debounceSearch(searchPositionByAddress);
    }
  }, [props.defaultPosition]);

  useEffect(() => {
    toggleEditMode();

    if (props.editing) {
      setTimeout(clickEditButton, 300);
    }
  }, [props.editing]);

  useEffect(() => {
    handleColorChange();
  }, [props.color]);

  function drawCircle(fg, coordinates, radius) {
    const [lat, lng] = [coordinates?.at(1), coordinates?.at(0)];
    const layer = L.circle([lat, lng], {
      ...SHAPE_OPTIONS,
      color: state.color,
      fillColor: state.color,
      radius
    });

    fg.addLayer(layer);
  }

  function drawPolygon(fg, geoJson) {
    L.geoJSON(geoJson, {
      style: {
        ...SHAPE_OPTIONS,
        color: state.color,
        fillColor: state.color
      }
    }).eachLayer(layer => fg.addLayer(layer));
  }

  function toggleEditMode() {
    if (!mapRef.current || state.editing === props.editing) return;

    const mapContainer = mapRef?.current?.getContainer();

    props.editing ? mapContainer.classList.add('editing') : mapContainer.classList.remove('editing');
  }

  function handleColorChange() {
    if (!featureGroupRef.current) return;

    if (state.color !== props.color) {
      featureGroupRef.current.eachLayer(layer => {
        if (typeof layer.setStyle === 'function') {
          layer.setStyle({ color: props.color, fillColor: props.color, zIndex: 9999 });
        }
      });

      setState(prevState => ({ ...prevState, color: props.color }));
    }
  }

  function handleCreateShape(e) {
    setState(prevState => ({ ...prevState, enableDraw: false }));

    const layers = L.layerGroup().addLayer(e.layer);

    setState(prevState => ({ ...prevState, leaflet_id: e.layer._leaflet_id }));

    layers.eachLayer(layer => {
      if (props.type === TYPE.FARM && props.onCreateGeofence) {
        const geoJson = layer.toGeoJSON();
        geoJson.properties.radius = layer._mRadius ? layer._mRadius : layer._radius;

        props.onCreateGeofence(geoJson);
      }
    });

    if (props.isNew) {
      setState(prevState => ({ ...prevState, enableDraw: true }));
    }
  }

  function handleEditShape(layerGroup) {
    layerGroup.layers.eachLayer(layer => {
      if (props.editFence && props.type === TYPE.FARM) {
        const geoJson = layer.toGeoJSON();

        if (layer._mRadius) {
          geoJson.properties.radius = layer._mRadius;
        } else if (layer._radius) {
          geoJson.properties.radius = layer._radius;
        }

        props.editFence(geoJson);
      }
    });

    setState(prevState => ({ ...prevState, enableDraw: false }));
  }

  function handleDeleteShape() {
    setState(prevState => ({ ...prevState, enableDraw: true }));

    if (props.handleDelete) props.handleDelete();
  }

  async function searchPositionByAddress() {
    let position;
    const set_position = props.defaultPosition ? props.defaultPosition : props.farm?.address?.full_address;

    if (!set_position) return;

    try {
      const address = await provider.search({ query: set_position });

      if (!address) return;

      position = address.length ? [+address?.at(0)?.y, +address?.at(0)?.x] : state.position;

      if (position?.length && position.every(p => !Number.isNaN(p))) {
        setState(prevState => ({ ...prevState, position }));

        if (mapRef?.current) {
          mapRef?.current?.setView(position, mapRef?.current?.getZoom());
        }
      }
    } catch (error) {
      console.error(error);
    }

    return position;
  }

  function calculateBoundsForCircle(geoJson) {
    if (isEmpty(geoJson?.geometry)) return;

    let bounds = {};
    const { coordinates } = geoJson.geometry;
    const radius = geoJson.properties?.radius || 0;

    if (coordinates?.length === 2 && radius > 0) {
      const [lng, lat] = coordinates;
      const center = L.latLng(lat, lng);

      bounds = center.toBounds(radius * 2);

      return bounds;
    }
  }

  function calculateBoundsForPolygon(geoJson) {
    const group = new L.geoJson(geoJson);
    let bounds = group.getBounds();

    if (bounds.isValid()) {
      const southWest = bounds.getSouthWest();
      const northEast = bounds.getNorthEast();

      const corner1 = L.latLng(parseFloat(northEast.lat), parseFloat(northEast.lng));
      const corner2 = L.latLng(parseFloat(southWest.lat), parseFloat(southWest.lng));

      bounds = L.latLngBounds(corner1, corner2);

      return bounds;
    }
  }

  function calculateMapBounds(geoData) {
    const source = geoData?.geometry ? geoData : geoData?.features?.at(0);

    if (!source) return;

    const bounds =
      source.geometry?.type === GEOMETRY_TYPE.POINT && source.properties?.radius
        ? calculateBoundsForCircle(source)
        : calculateBoundsForPolygon(source);

    setState(prevState => ({ ...prevState, farm: props.farm, bounds }));

    if (mapRef?.current) {
      const center = bounds.getCenter();
      mapRef?.current?.setView(center, Math.min(mapRef?.current?.getBoundsZoom(bounds), ZOOM_MAX));
    }
  }

  async function tick() {
    if (!props?.farm?.master_geofence) return;

    // const geofences = { type: 'FeatureGroup', features: [] };
    // const masterGeofences = { type: 'FeatureCollection', features: [] };
    // const sites = { type: 'Feature', features: [] };
    //
    // const farmGeofencesClone = structuredClone(props?.farm?.geofences);
    // const farmSitesClone = structuredClone(props?.farm?.sites || []);
    //
    // for (const farmSiteClone of farmSitesClone) {
    //   if (farmSiteClone.geo_json.type) {
    //     sites.features.push({ ...farmSiteClone.geo_json, color: farmSiteClone.color });
    //   }
    // }
    //
    // for (const farmGeofenceClone of farmGeofencesClone) {
    //   if (farmGeofenceClone.is_master) {
    //     const coordinates = structuredClone(farmGeofenceClone?.geo_json?.geometry?.coordinates?.at(0));
    //
    //     if (isArrayOfArrays(coordinates)) {
    //       farmGeofenceClone.geo_json.geometry.coordinates[0] = reverseCoordinates(coordinates);
    //       masterGeofences.features.push(farmGeofenceClone.geo_json);
    //     }
    //   } else {
    //     geofences.features.push({ ...farmGeofenceClone.geo_json, color: farmGeofenceClone.color });
    //   }
    // }

    setState(prevState => ({
      ...prevState,
      masterGeofences: [],
      geofences: [],
      sites: []
    }));

    // const center = props?.farm?.master_geofence ? props?.farm?.master_geofence?.geo_json : masterGeofences;
    const center = props?.farm?.master_geofence?.geo_json;
    await calculateMapBounds(center);
  }

  function handleSave() {
    props.handleSaveEditing();
    exitFullScreenMode();
  }

  function exitFullScreenMode() {
    if (mapRef && mapRef?.current?.fullscreenControl) {
      document.exitFullscreen();
    }
  }

  return (
    <>
      <MapContainer
        className={classnames('map', { 'edit-controls-visible': props.isNew })}
        attributionControl={false}
        bounds={state.bounds || null}
        center={state.position}
        ref={mapRef}
        zoom={ZOOM}>
        <LayersControl collapsed={!L.Browser.mobile} position={LAYERS_CONTROL_POSITION}>
          <MapBaseLayers />

          <Overlay checked name="Farm">
            <LayerGroup>
              {state.sites.features?.length > 0 &&
                !state.new &&
                state.sites?.features.map(i => (
                  <MapSiteFeature
                    key={i?.properties?.id}
                    type={i.geometry.type}
                    color={i.color}
                    geometry={i.geometry.coordinates}
                    properties={i.properties}
                  />
                ))}

              {state.geofences?.features?.map((item, index) => (
                <Shape key={JSON.stringify({ ...item, index })} item={item} path="geofence" />
              ))}

              <FeatureGroup ref={featureGroupRef}>
                <EditControl
                  draw={{
                    ...EDIT_CONTROL_DRAW_OPTIONS,
                    circle: props.editing &&
                      state.enableDraw && {
                        shapeOptions: { ...SHAPE_OPTIONS, color: state.color, fillColor: state.color }
                      },
                    polygon: props.editing &&
                      state.enableDraw && {
                        shapeOptions: { ...SHAPE_OPTIONS, color: state.color, fillColor: state.color }
                      }
                  }}
                  edit={{ featureGroup: featureGroupRef?.current, edit: true, remove: true }}
                  position={EDIT_CONTROL_POSITION}
                  onEdited={handleEditShape}
                  onCreated={handleCreateShape}
                  onDeleted={handleDeleteShape}
                />

                {state.masterGeofences?.features?.map(i => {
                  return (
                    <Feature
                      color={state.color}
                      geometry={i.geometry.coordinates}
                      key={JSON.stringify(i.geometry.coordinates)}
                      type={i.geometry.type}
                      setAnimal={props.setAnimal}>
                      {i?.properties && (
                        <MapPopup
                          description={i.properties.description}
                          link={`/geofence/${i.properties?.id}`}
                          name={i.properties.name}
                        />
                      )}
                    </Feature>
                  );
                })}
              </FeatureGroup>
            </LayerGroup>

            {!L.Browser.mobile && <FullscreenControl position={FULLSCREEN_CONTROL_POSITION} />}
            {isFullScreen && props.editing && (
              <EditButtons handleCancel={props.handleCancelEditing} handleReturn={handleSave} />
            )}
          </Overlay>
        </LayersControl>
      </MapContainer>
    </>
  );
}

export default MapSimple;
