import axios from '../../axios';

const service = {
  createInvitation(data) {
    return axios({
      method: 'post',
      url: '/invitations',
      data
    });
  },

  getInvitations() {
    return axios({
      method: 'get',
      url: '/invitations'
    });
  },

  archiveInvitations(invitationIds = []) {
    return axios({
      method: 'delete',
      url: '/invitations/bulk',
      data: { ids: invitationIds }
    });
  },

  updateInvitation(id, data) {
    return axios({
      method: 'put',
      url: `/invitations/${id}`,
      data: data
    });
  }
};

export default service;
