import { memo } from 'react';

import './styles.scss';

function AppTag({ style, children }) {
  return (
    <div className="app-tag" style={style}>
      {children}
    </div>
  );
}
export default memo(AppTag);
