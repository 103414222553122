import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Button, Flex, Typography } from 'antd';
import get from 'lodash.get';

import axios from '../../../axios';

import { capitalize } from '../../../helpers/common';
import { getConfirmationModalDescription, isGeofenceMatchedWithPhysicalGroup } from '../../../helpers/livestock';
import { hasPermission } from '../../../helpers/user';

import messages from '../../../constants/messages';
import { PERMISSIONS } from '../../../constants/permissions';
import { FEATURE_LIST } from '../../../constants/subscription';

import { fetchAnimal } from '../../../redux/actions/animal';

import AppModal from '../../../components/AppModal';
import CancelSaveButtons from '../../../components/CancelSaveButtons';
import FeatureProvider from '../../../components/FeatureProvider';
import LoadingBar from '../../../components/LoadingBar';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import PrevNextButtons from '../../../components/PrevNextButtons';
import { errorToastHandler } from '../../../components/action_notifier';
import AnimalCard from '../../../components/animals/animalCard';

import AppBreadcrumb from '../../ui-components/AppBreadcrumb';
import AppDropdown from '../../ui-components/AppDropdown';

import AnimalHistory from './AnimalHistory';

const { Title } = Typography;

function Animal(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { animal, loading } = useSelector(state => state.animal);
  const user = useSelector(state => state.user);
  const [actions, setActions] = useState([]);
  const [state, setState] = useState({
    loading: true,
    animal: {},
    animal_edit: {},
    breed_opts: [], // TODO: load list of breeds from api
    confirmationModalDescription: '',
    delete_modal: false,
    delete_notif_modal: false,
    edit_modal: false,
    farm_opts: [],
    farms: [],
    geofence_opts: [],
    isConfirmed: false,
    isOpenConfirmationModal: false,
    isOpenEventModal: false,
    isOpenTaskModal: false,
    isTabInAction: false,
    labels_opts: [],
    link_modal: false,
    selected_breed: [],
    selected_farm: [],
    selected_geofences: [],
    selected_labels: [],
    selected_sex: [],
    selected_specie: [],
    selected_type: {},
    sex_opts: [
      { value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' }
    ],
    specie_opts: [
      { value: 'Bovine', label: 'Bovine' },
      { value: 'Ovine', label: 'Ovine' },
      { value: 'Caprine', label: 'Caprine' }
    ], // TODO: load list of species from api
    tabDropdownOpen: false,
    tabType: '',
    tab_action: '',
    type_opts: []
  });

  const child = useRef();
  const breedingTab = useRef();
  const measureTab = useRef();
  const treatmentTab = useRef();

  useEffect(() => {
    // dispatch(getUsers());
    // dispatch(getNotifications());
    // getBreeds();
    getTypes();
    getFarms();
    getLabels();
    getAnimal();
    initActions();
  }, []);

  useEffect(() => {
    if (animal) {
      prepareData();
    }
  }, [animal]);

  const navigateToThePrevPage = useCallback(() => {
    props.history.push(`/animal/${state.animal?.prev_id}`);
  }, [state.animal?.prev_id]);

  const navigateToTheNextPage = useCallback(() => {
    props.history.push(`/animal/${state.animal?.next_id}`);
  }, [state.animal?.next_id]);

  function initActions() {
    const availableActions = [];

    if (hasPermission([PERMISSIONS.ANIMALS.CREATE], user?.permissions)) {
      availableActions.push({
        label: (
          <Link className="app-link" to="/animal_new">
            Add livestock
          </Link>
        )
      });
    }

    if (hasPermission([PERMISSIONS.ANIMALS.UPDATE_OWN], user?.permissions)) {
      availableActions.push({
        label: (
          <Button color="default" size="small" variant="link" onClick={() => toggleModal('edit_modal')}>
            Edit livestock
          </Button>
        )
      });
    }

    if (hasPermission([PERMISSIONS.ANIMALS.DELETE_OWN], user?.permissions)) {
      availableActions.push({
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!state.animal?.deleted_at && !state.animal?.loss_date}
            onClick={() => toggleModal('delete_modal')}>
            Archive livestock
          </Button>
        )
      });
    }

    setActions(availableActions);
  }

  async function getLabels() {
    const response = await axios.get('labels');

    if (response.status === 200) {
      setState({
        label_opts: response.data.map(x => ({ value: x.id, label: x.name, color: x.colour }))
      });
    }
  }

  const onChange = (value, field) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  async function getAnimal() {
    try {
      await dispatch(fetchAnimal(+id)).unwrap();
    } catch (error) {
      errorToastHandler(messages.FAILED_ON_FETCH_DATA);
    }
  }

  function prepareData() {
    let breed_s = {};

    const breed = get(animal, 'breed.display_name', null);
    const specie = get(animal, 'specie.name', null);

    if (breed) {
      breed_s = { value: breed.code, label: breed.display_name };
    }

    const specie_s = state.specie_opts?.filter(s => s.value === specie)[0];
    const sex_s = state.sex_opts?.filter(s => s.value === animal?.sex)[0];
    const farm_s = state.farm_opts?.filter(s => s.value === animal?.farm_id)[0];
    const leabels_s = animal?.labels?.map(x => ({ label: x.name, value: x.id, color: x.colour }));
    const fences_s = animal?.geofences?.map(x => ({ label: x.name, value: x.id }));

    setState(prevState => ({
      ...prevState,
      isConfirmed: false,
      animal: {
        ...animal,
        breed,
        specie,
        dob_at_holder: animal?.dob_at
      },
      animal_edit: {
        ...animal,
        breed,
        specie,
        dob_at_holder: animal?.dob_at
      },
      selected_sex: sex_s,
      selected_farm: farm_s,
      selected_specie: specie_s,
      selected_labels: leabels_s,
      selected_breed: breed_s,
      selected_geofences: fences_s,
      selected_type: {
        value: animal?.stock_type,
        label: capitalize(animal?.stock_type)
      }
    }));
  }

  async function editAnimal(isConfirmed = false) {
    ////////// RG - NEW LOGIC TO SAVE DATA //////////
    const livestockData = child.current.getLivestockData();

    if (livestockData.loss_date) {
      if (livestockData?.tag?.id) {
        errorToastHandler(messages.ANIMAL_UNLINK_TAG_REQUIRED);
        return false;
      }
    }

    if (livestockData.breed && livestockData.breed.id) {
      livestockData.breed = livestockData.breed.display_name;
    }
    livestockData.geofence_ids = livestockData.geofences.map(x => x.id);
    livestockData.label_ids = livestockData.labels.map(x => x.id);
    livestockData.group_ids = livestockData.label_ids ? livestockData.label_ids : [];
    livestockData.sire_id = livestockData.sire_id ? Number(livestockData.sire_id) : null;
    livestockData.dam_id = livestockData.dam_id ? Number(livestockData.dam_id) : null;
    livestockData.photo = null;

    if (livestockData.new_photo) {
      livestockData.photo = livestockData.new_photo;
    }

    if (
      !livestockData.eartag_management_id ||
      livestockData.eartag_management_id === '' ||
      !livestockData.farm_id ||
      livestockData.farm_id === '' ||
      !livestockData.specie ||
      livestockData.specie === '' ||
      !livestockData.sex ||
      livestockData.sex === ''
    ) {
      errorToastHandler(messages.FILL_MANDATORY_FIELDS);
      return false;
    } else {
      if (!isConfirmed) {
        const isGeofenceMatched = isGeofenceMatchedWithPhysicalGroup(livestockData);
        const description = getConfirmationModalDescription(isGeofenceMatched);

        if (description) {
          setState(prevState => ({
            ...prevState,
            confirmationModalDescription: description,
            isOpenConfirmationModal: true
          }));
        } else updateAnimalReq(livestockData);
      } else updateAnimalReq(livestockData);
    }
  }

  async function updateAnimalReq(livestockData) {
    const animalId = props.match.params.id;
    const response = await axios.put('animals/' + animalId, livestockData);

    if (response.status === 200) {
      if (props.match.params.type === 'new') {
        window.location.href = '/animal/' + animalId;
      } else {
        getAnimal();
        toggleModal('edit_modal');
      }
    }
  }

  function toggleModal(modal) {
    // RG - OVERWRITE THE 'animal_edit' VARIABLE //
    setState(prevState => ({
      ...prevState,
      animal_edit: JSON.parse(JSON.stringify(prevState.animal)),
      [modal]: !prevState[modal]
    }));
  }

  async function deleteAnimal() {
    if (state.animal?.tag?.id) {
      errorToastHandler(messages.ANIMAL_UNLINK_TAG_REQUIRED);
    } else {
      const animalId = props.match.params.id;
      const response = await axios.delete('animals/' + animalId);

      if (response.status === 200) props.history.push('/animals');
    }
  }

  async function getFarms() {
    const response = await axios.get('farms?with_details=true', {
      query: state.query || null
    });

    if (response.status === 200) {
      const mapped_farms = [];

      for (let farm of response.data) {
        mapped_farms.push({
          value: farm.id,
          label: farm.name,
          meta: {
            features: farm.geofences
          }
        });
      }
      setState(prevState => ({ ...prevState, farms: response.data, farm_opts: mapped_farms }));
    }
  }

  async function getBreeds() {
    const response = await axios.get('breeds');

    if (response.status === 200) {
      setState(prevState => ({
        ...prevState,
        breed_opts: response.data.map(x => ({ value: x.code, label: x.display_name }))
      }));
    }
  }

  async function getTypes() {
    const response = await axios.get('/animals/stock-types');

    if (response.status === 200) setState(prevState => ({ ...prevState, type_opts: response.data }));
  }

  function tab_saveAction() {
    let tabRef;
    const activeTab = Number(state.activeTab);

    if (activeTab === 3) {
      tabRef = measureTab;
    } else if (activeTab === 4) {
      tabRef = breedingTab;
    } else if (activeTab === 5) {
      tabRef = treatmentTab;
    }

    if (tabRef) tabRef.current.saveAction();
  }

  function tab_cancelAction() {
    let tabRef;
    const activeTab = Number(state.activeTab);

    if (activeTab === 3) {
      tabRef = measureTab;
    } else if (activeTab === 4) {
      tabRef = breedingTab;
    } else if (activeTab === 5) {
      tabRef = treatmentTab;
    }

    if (tabRef) {
      tabRef.current.cancelAction();
      clearTabOperation();
    }
  }

  // function addNewTabRows() {
  //   setState({ isTabInAction: !state.isTabInAction, tab_action: 'add' });
  // }

  function clearTabOperation() {
    setState(prevState => ({ ...prevState, isTabInAction: !state.isTabInAction, tab_action: state.tab_action }));
  }

  if (state.animal?.id === null) return null;

  const breadcrumbItems = [
    {
      title: <Link to="/animals">List of Livestock</Link>
    },
    {
      title: `Livestock ${state.animal?.identifier || state.animal?.eartag_official_id}`
    }
  ];

  return (
    <>
      {loading ? (
        <LoadingBar />
      ) : (
        <>
          <Flex gap="small" vertical={false} justify="space-between" wrap>
            <Flex vertical={true}>
              <Title level={4}>Livestock {state.animal?.identifier || state.animal?.eartag_official_id}</Title>

              <AppBreadcrumb items={breadcrumbItems} />
            </Flex>
            <Flex gap="small">
              {state.animal?.tag && (
                <FeatureProvider name={FEATURE_LIST.TRACK_ANIMAL}>
                  <Button
                    className=""
                    color="primary"
                    size="large"
                    variant="solid"
                    onClick={() => props.history.push('/tracking/' + state.animal?.id)}>
                    <i className="fas fa-pen-square"></i> Track Livestock
                  </Button>
                </FeatureProvider>
              )}

              {!state.edit_modal && actions?.length && <AppDropdown label="Actions" items={actions} />}

              {state.edit_modal && (
                <CancelSaveButtons handleCancel={() => toggleModal('edit_modal')} handleSave={editAnimal} />
              )}

              <PrevNextButtons
                nextId={state.animal?.next_id}
                prevId={state.animal?.prev_id}
                handlePrev={navigateToThePrevPage}
                handleNext={navigateToTheNextPage}
              />
            </Flex>
          </Flex>

          <AnimalCard
            className="mb-2"
            animal={state.animal_edit}
            history={props.history}
            onChange={onChange}
            toggleModal={toggleModal}
            editMode={state.edit_modal}
            ref={child}
            actionType={props.match.params.type}
          />

          <AnimalHistory animalId={id} permissions={user?.permissions} />

          {state.tabType !== '' && state.isTabInAction && (
            <CancelSaveButtons className="float-right" handleCancel={tab_cancelAction} handleSave={tab_saveAction} />
          )}

          <AppModal
            isOpen={state.delete_modal}
            confirmButtonColor="danger"
            confirmButtonText="Archive"
            title="Archive animal"
            handleCancel={() => toggleModal('delete_modal')}
            handleConfirm={deleteAnimal}>
            <div className="py-4">Are you sure you want to archive this animal? This action cannot be undone.</div>
          </AppModal>

          <ConfirmationModal
            isOpen={state.isOpenConfirmationModal}
            description={state.confirmationModalDescription}
            submitText={'Yes'}
            submitButtonType={'primary'}
            onCancel={() => setState({ isOpenConfirmationModal: false })}
            onSubmit={() => {
              editAnimal(true);
              setState({ isConfirmed: true, isOpenConfirmationModal: false });
            }}
          />
        </>
      )}
    </>
  );
}

export default memo(Animal);
