import BlankLayout from '../layouts/blanklayout.jsx';
import FullLayout from '../layouts/fulllayout.jsx';

const indexRoutes = [
  { path: '/auth', name: 'Authentication', component: BlankLayout },
  { path: '/account', name: 'Account', component: BlankLayout },
  { path: '/', name: 'Dashboard', component: FullLayout, protected: true }
];

export default indexRoutes;
