import axios from '../../axios';

const path = 'animals';

const service = {
  fetchAnimals(params) {
    return axios({ method: 'get', url: path, params });
  },

  fetchAnimal(id) {
    return axios({ method: 'get', url: `${path}/${id}` });
  },

  fetchAnimalsTags(params) {
    return axios({ method: 'get', url: `${path}/tag-data`, params });
  },

  fetchAnimalTags(id, params) {
    return axios({ method: 'get', url: `${path}/${id}/tag-data`, params });
  },

  async updateAnimals(payload) {
    return axios({ method: 'put', url: path, data: payload });
  }
};

export default service;
