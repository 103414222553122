import { Suspense, lazy, memo } from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Tabs } from 'antd';

import LoadingBar from '../../../components/LoadingBar';

const Alerts = lazy(() => import('../../../components/Alerts'));

const DEFAULT_TAB = 'notifications';

function GeofenceHistory({ geofenceId }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get('tab');
  const items = [
    {
      key: 'notifications',
      label: 'Notifications',
      children: (
        <Suspense fallback={<LoadingBar />}>
          <Alerts type="full" columnType={1} query={{ geofence_ids: [geofenceId] }} />
        </Suspense>
      )
    }
  ];

  return (
    <Card title="History">
      <Tabs defaultActiveKey={tab || DEFAULT_TAB} items={items} />
    </Card>
  );
}

export default memo(GeofenceHistory);
