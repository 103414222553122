import { useEffect, useState } from 'react';

import { Button } from 'antd';
import moment from 'moment';

import { sortDates, sortStrings } from '../../helpers/filter';

import { DATE_FORMAT } from '../../constants/common';
import { EVENT_MODAL_MODE } from '../../constants/event';

import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import EventModal from '../../components/Modals/EventModal';

function CustomAgenda({ events }) {
  const [actions, setActions] = useState([]);
  const [isOpenEventModal, setOpenEventModal] = useState(false);

  useEffect(() => {
    initActions();
  }, []);

  function initActions() {
    setActions([
      {
        label: (
          <Button color="default" size="small" variant="link" onClick={() => onAddEventClick()}>
            Add event
          </Button>
        )
      }
    ]);
  }

  const onAddEventClick = () => {
    setOpenEventModal(true);
  };

  const onSubmit = () => {
    setOpenEventModal(false);
  };
  const commonColumnProperties = {
    ellipsis: true,
    sortDirections: ['ascend', 'descend']
  };

  const columns = [
    {
      ...commonColumnProperties,
      title: 'Date',
      dataIndex: 'start_at',
      width: COLUMN_SIZE.LG,
      render: (value, record) => moment(record.start_at).format(DATE_FORMAT.DATE),
      sorter: (a, b) => sortDates(a.start_at, b.start_at)
    },
    {
      ...commonColumnProperties,
      title: 'Time',
      dataIndex: 'time',
      width: COLUMN_SIZE.LG,
      render: (value, record) => {
        const start = moment(record.start).format('HH:mm');
        const end = moment(record.end).format('HH:mm');

        return `${start} - ${end}`;
      },
      sorter: (a, b) => sortDates(a.start, b.start)
    },
    {
      ...commonColumnProperties,
      title: 'Event',
      dataIndex: 'title',
      searchable: true,
      width: COLUMN_SIZE.XL,
      sorter: (a, b) => sortStrings(a.title, b.title)
    },
    {
      ...commonColumnProperties,
      title: 'Description',
      dataIndex: 'description',
      searchable: true,
      width: COLUMN_SIZE.XL,
      sorter: (a, b) => sortStrings(a.description, b.description)
    }
  ];
  return (
    <>
      <AppTable
        actions={actions}
        baseColumns={columns}
        headerClass="pb-2"
        dataSource={events}
        loading={false}
        rowsSelectable={false}
        searchable={false}
        settings={true}
      />

      <EventModal
        onSubmit={onSubmit}
        isOpen={isOpenEventModal}
        onCancel={() => setOpenEventModal(false)}
        mode={EVENT_MODAL_MODE.CREATE}
      />
    </>
  );
}

export default CustomAgenda;
