import { createSlice } from '@reduxjs/toolkit';

const mapSlice = createSlice({
  name: 'map',
  initialState: {
    data: {}
  },
  reducers: {
    setMapData: (state, action) => {
      state.data = { ...action.payload };
    }
  },
  extraReducers: () => {}
});

export const { setMapData } = mapSlice.actions;
export default mapSlice.reducer;
