export const SLIDER_DEFAULT_TIME_FRAME = 66;
export const SLIDER_DEFAULT_TIME_INTERVAL = 0;

export const MIN_TIME_FRAME_DOTS_NUMBER = {
  1: 5,
  2: 10,
  6: 30,
  12: 60,
  24: 120,
  36: 180,
  48: 240
};

export const SLIDER = {
  timeFrameSlots: [
    {
      index: 0,
      value: 1,
      label: '1hr'
    },
    {
      index: 16,
      value: 2,
      label: '2hrs'
    },
    {
      index: 32,
      value: 6,
      label: '6hrs'
    },
    {
      index: 49,
      value: 12,
      label: '12hrs'
    },
    {
      index: 66,
      value: 24,
      label: '24hrs'
    },
    {
      index: 83,
      value: 36,
      label: '36hrs'
    },
    {
      index: 100,
      value: 48,
      label: '48hrs'
    }
  ],
  timeIntervalSlots: [
    {
      index: 0,
      value: 1,
      label: '10mins'
    },
    {
      index: 15,
      value: 2,
      label: '20mins'
    },
    {
      index: 30,
      value: 3,
      label: '30mins'
    },
    {
      index: 45,
      value: 6,
      label: '60mins'
    },
    {
      index: 58,
      value: 12,
      label: '2hrs'
    },
    {
      index: 72,
      value: 36,
      label: '6hrs'
    },
    {
      index: 86,
      value: 72,
      label: '12hrs'
    },
    {
      index: 100,
      value: 144,
      label: '24hrs'
    }
  ]
};

export const col_1 = {
  eartag_management_id: {
    is_editable: true,
    lable: 'Mgmt Tag ID'
  },
  kraal_tag_id: {
    is_editable: true,
    lable: 'Kraal Tag ID'
  },
  eartag_official_id: {
    is_editable: true,
    lable: 'Official Tag ID'
  },
  brand: {
    is_editable: true,
    lable: 'Brand'
  },
  name: {
    is_editable: true,
    lable: 'Description'
  },
  colour: {
    is_editable: true,
    lable: 'Colour'
  },
  breed: {
    is_editable: true,
    lable: 'Breed'
  },
  sex: {
    is_editable: true,
    lable: 'Sex'
  }
};

export const col_2 = {
  geofences: {
    is_editable: true,
    lable: 'Assigned Geofence'
  },
  dob_at: {
    is_editable: true,
    lable: 'DOB'
  },
  age: {
    is_editable: false,
    lable: 'Age'
  },
  last_notification: {
    is_editable: false,
    lable: 'Last Notification'
  },
  last_reading: {
    is_editable: false,
    lable: 'Last Reading'
  }
};
