import { createAsyncThunk } from '@reduxjs/toolkit';

import { GeofenceService } from '../../services';

export const createGeofence = createAsyncThunk('geofence/createGeofence', async (data, { rejectWithValue }) => {
  try {
    const response = await GeofenceService.createGeofence(data);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to create geofence');
  }
});

export const fetchGeofences = createAsyncThunk('geofence/fetchGeofences', async (params, { rejectWithValue }) => {
  try {
    const response = await GeofenceService.fetchGeofences(params);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch geofences');
  }
});

export const fetchGeofence = createAsyncThunk('geofence/fetchGeofence', async (id, { rejectWithValue }) => {
  try {
    const response = await GeofenceService.fetchGeofence(id);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch geofences');
  }
});

export const updateGeofence = createAsyncThunk('geofence/updateGeofence', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await GeofenceService.updateGeofence(id, data);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to update geofence');
  }
});

export const deleteGeofences = createAsyncThunk('geofence/deleteGeofences', async (ids, { rejectWithValue }) => {
  try {
    await GeofenceService.deleteGeofences(ids);

    return ids;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to delete geofences');
  }
});

export const deleteGeofence = createAsyncThunk('geofence/deleteGeofence', async (id, { rejectWithValue }) => {
  try {
    await GeofenceService.deleteGeofence(id);
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to delete geofence');
  }
});
