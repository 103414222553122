import { createSlice } from '@reduxjs/toolkit';

import { createSite, deleteSites, fetchSites, updateSite } from '../actions/site';

const siteSlice = createSlice({
  name: 'site',
  initialState: {
    data: [],
    error: null,
    loading: false,
    mapData: {},
    selectedFilters: {},
    site: {}
  },
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setMapData: (state, action) => {
      state.mapData = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      // Create Site
      .addCase(createSite.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSite.fulfilled, (state, action) => {
        state.loading = false;
        state.site = action.payload;
      })
      .addCase(createSite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch Sites
      .addCase(fetchSites.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSites.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update Site
      .addCase(updateSite.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSite.fulfilled, (state, action) => {
        state.loading = false;
        state.site = action.payload;
      })
      .addCase(updateSite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete Sites
      .addCase(deleteSites.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSites.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(record => !action.payload?.includes(record.id));
      })
      .addCase(deleteSites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setSelectedFilters, setMapData } = siteSlice.actions;
export default siteSlice.reducer;
