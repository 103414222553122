export const GOOGLE_LAYER = {
  HYBRID: 'hybrid',
  TERRAIN: 'terrain'
};

export const TYPE = {
  FARM: 'farm',
  GEOFENCE: 'geofence'
};

export const DEFAULT_LINE_COLOR = '#ff0000';
export const DEFAULT_SHAPE_COLOR = '#3388ff';
export const EDIT_CONTROL_POSITION = 'topleft';
export const EDIT_CONTROL_DRAW_OPTIONS = {
  circlemarker: false,
  marker: false,
  point: false,
  polyline: false,
  rectangle: false
};
export const FULLSCREEN_CONTROL_POSITION = 'topright';
export const LAYERS_CONTROL_POSITION = 'bottomright';
export const SHAPE_OPTIONS = {
  fillOpacity: 0.5,
  opacity: 0.5,
  weight: 2
};
export const ZOOM = 10;
export const ZOOM_MAX = 20;
