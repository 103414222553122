import { REDUCERS } from '../../constants/reducers';

import { TableConfigurationsService } from '../../services';

export const setTableConfigurations = value => ({
  type: REDUCERS.TABLE_CONFIGURATIONS.SET_CONFIGURATIONS,
  value
});

export const setTableConfigurationsLoading = value => ({
  type: REDUCERS.TABLE_CONFIGURATIONS.SET_CONFIGURATIONS_LOADING,
  value
});

export const getTableConfigurations = () => async dispatch => {
  dispatch(setTableConfigurationsLoading(true));

  try {
    const { data } = await TableConfigurationsService.getTableConfigs();

    dispatch(setTableConfigurations(data));

    return data;
  } finally {
    dispatch(setTableConfigurationsLoading(false));
  }
};

export const bulkUpdateTableConfigurations = tables => async dispatch => {
  dispatch(setTableConfigurationsLoading(true));

  try {
    await TableConfigurationsService.bulkUpdate(tables);

    await dispatch(getTableConfigurations());
  } finally {
    dispatch(setTableConfigurationsLoading(false));
  }
};

export const updateTableColumnConfigurations = (id, updatedColumns) => async dispatch => {
  dispatch(setTableConfigurationsLoading(true));

  try {
    await TableConfigurationsService.updateColumnConfigurations(id, updatedColumns);

    await dispatch(getTableConfigurations());
  } finally {
    dispatch(setTableConfigurationsLoading(false));
  }
};
