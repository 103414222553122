import { createSlice } from '@reduxjs/toolkit';

import {
  createGeofence,
  deleteGeofence,
  deleteGeofences,
  fetchGeofence,
  fetchGeofences,
  updateGeofence
} from '../actions/geofence';

const geofenceSlice = createSlice({
  name: 'geofence',
  initialState: {
    data: [],
    error: null,
    geofence: null,
    loading: false,
    mapData: {},
    selectedFilters: {}
  },
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setMapData: (state, action) => {
      state.mapData = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      // Create Geofence
      .addCase(createGeofence.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGeofence.fulfilled, (state, action) => {
        state.loading = false;
        state.geofence = action.payload;
      })
      .addCase(createGeofence.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch Geofences
      .addCase(fetchGeofences.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGeofences.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchGeofences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch Geofence
      .addCase(fetchGeofence.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGeofence.fulfilled, (state, action) => {
        state.loading = false;
        state.geofence = action.payload;
      })
      .addCase(fetchGeofence.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update Geofence
      .addCase(updateGeofence.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateGeofence.fulfilled, (state, action) => {
        state.loading = false;
        state.geofence = action.payload;
      })
      .addCase(updateGeofence.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete Geofences
      .addCase(deleteGeofences.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGeofences.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(record => !action.payload?.includes(record.id));
      })
      .addCase(deleteGeofences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete Geofence
      .addCase(deleteGeofence.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGeofence.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteGeofence.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setSelectedFilters, setMapData } = geofenceSlice.actions;
export default geofenceSlice.reducer;
