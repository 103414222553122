import { useEffect, useRef, useState } from 'react';
import { FeatureGroup, LayerGroup, LayersControl, MapContainer } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';

import * as turf from '@turf/turf';
import classnames from 'classnames';
import L from 'leaflet';
// import { OpenStreetMapProvider } from 'leaflet-geosearch';
import isEmpty from 'lodash.isempty';

import { reverseCoordinates } from '../../helpers/map';

import { GEOMETRY_TYPE } from '../../constants/map';

import EditButtons from './EditButtons';
import Feature from './Feature';
import MapBaseLayers from './MapBaseLayers';
import MapSiteFeature from './MapSiteFeature';
import Shape from './Shape';
import {
  EDIT_CONTROL_DRAW_OPTIONS,
  EDIT_CONTROL_POSITION,
  FULLSCREEN_CONTROL_POSITION,
  LAYERS_CONTROL_POSITION,
  SHAPE_OPTIONS,
  TYPE,
  ZOOM,
  ZOOM_MAX
} from './constants';
import './styles.scss';

const { Overlay } = LayersControl;
// const provider = new OpenStreetMapProvider();
const customizeButtonLabels = () => {
  L.drawLocal.edit.toolbar.actions.save.text = 'Finish';
};

export default function MapGeofence({
  color,
  editing,
  geofence,
  isNew,
  type,
  onEdit,
  handleShapeSizes,
  handleCancelEditing,
  handleCreate,
  handleDelete,
  handleSaveEditing
}) {
  const mapRef = useRef(null);
  const featureGroupRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [state, setState] = useState({
    bounds: [
      [-31.0, 13.0],
      [-31.0, 33.0]
    ],
    currentColor: color,
    currentGeofence: structuredClone(geofence),
    enableDraw: !!isNew,
    features: { features: [] },
    features_master: {},
    features_sites: { features: [] },
    geo_json: {},
    leaflet_id: null,
    position: [-29.132312, 26.057016]
  });

  const initializeFeatureGroup = () => {
    if (!featureGroupRef?.current) return;

    const fg = featureGroupRef.current;

    fg.clearLayers();

    if (isEmpty(geofence?.geo_json)) return;

    geofence.geo_json?.properties?.radius
      ? drawCircle(fg, geofence.geo_json?.geometry.coordinates, geofence.geo_json?.properties.radius)
      : drawPolygon(fg, geofence.geo_json);
  };

  const handleFullScreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement);
  };

  const clickEditButton = () => {
    const editButton = document.querySelector('a.leaflet-draw-edit-edit');

    if (editButton) editButton.click();
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (geofence) {
      setState(prevState => ({
        ...prevState,
        currentGeofence: { ...prevState.currentGeofence, geo_json: geofence.geo_json }
      }));

      customizeButtonLabels();
      initializeFeatureGroup();
      tick();
    }
  }, [geofence]);

  useEffect(() => {
    toggleEditMode();

    if (editing) {
      setTimeout(clickEditButton, 300);
    }
  }, [editing]);

  useEffect(() => {
    handleColorChange();
  }, [color]);

  function drawCircle(fg, coordinates, radius) {
    const [lat, lng] = [coordinates?.at(1), coordinates?.at(0)];
    const layer = L.circle([lat, lng], {
      ...SHAPE_OPTIONS,
      color: state.currentColor,
      fillColor: state.currentColor,
      radius
    });

    fg.addLayer(layer);
  }

  function drawPolygon(fg, geoJson) {
    L.geoJSON(geoJson, {
      style: {
        ...SHAPE_OPTIONS,
        color: state.currentColor,
        fillColor: state.currentColor
      }
    }).eachLayer(layer => fg.addLayer(layer));
  }

  function toggleEditMode() {
    if (!mapRef.current || state.editing === editing) return;

    const mapContainer = mapRef?.current?.getContainer();

    editing ? mapContainer.classList.add('editing') : mapContainer.classList.remove('editing');
  }

  function handleColorChange() {
    if (!featureGroupRef.current) return;

    if (state.currentColor !== color) {
      featureGroupRef.current.eachLayer(layer => {
        if (typeof layer.setStyle === 'function') {
          layer.setStyle({ color, fillColor: color, zIndex: 9999 });
        }
      });

      setState(prevState => ({ ...prevState, currentColor: color }));
    }
  }

  // async function calculateMapCenter() {
  //   const geofence = state.currentGeofence || geofence;
  //
  //   if (!geofence) return;
  //
  //   let position;
  //
  //   if (geofence.position) {
  //     position = geofence.position;
  //   }
  //
  //   try {
  //     if (!geofence.address) return;
  //
  //     const address = await provider.search({ query: geofence.address });
  //
  //     if (!address) return;
  //
  //     position = address.length ? [+address?.at(0)?.y, +address?.at(0)?.x] : state.position;
  //
  //     if (position && position.every(p => !Number.isNaN(p))) {
  //       setState(prevState => ({ ...prevState, position }));
  //
  //       if (mapRef?.current) {
  //         mapRef?.current?.setView(position, mapRef?.current?.getZoom());
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  //
  //   return position;
  // }

  function calculateBoundsForCircle(geoJSON) {
    let bounds;
    const { coordinates } = geoJSON.geometry;
    const radius = geoJSON.properties?.radius || 0;

    if (coordinates?.length === 2 && radius > 0) {
      const [lng, lat] = coordinates;
      const center = L.latLng(lat, lng);

      bounds = center.toBounds(radius * 2);

      return bounds;
    }
  }

  function calculateBoundsForPolygon(geoJson) {
    const group = new L.geoJson(geoJson);
    let bounds = group.getBounds();

    if (bounds.isValid()) {
      const corner2 = L.latLng(parseFloat(bounds._southWest?.lat), parseFloat(bounds._southWest?.lng));
      const corner1 = L.latLng(parseFloat(bounds._northEast?.lat), parseFloat(bounds._northEast?.lng));

      bounds = L.latLngBounds(corner1, corner2);

      return bounds;
    }
  }

  async function calculateMapBounds(geoData) {
    const source = geoData?.geometry ? geoData : geoData?.features?.at(0);

    if (!source) return;

    const bounds =
      source.geometry?.type === GEOMETRY_TYPE.POINT && source.properties?.radius
        ? calculateBoundsForCircle(source)
        : calculateBoundsForPolygon(source);

    setState(prevState => ({ ...prevState, bounds }));

    if (mapRef?.current) {
      const center = bounds.getCenter();
      mapRef?.current?.setView(center, Math.min(mapRef?.current?.getBoundsZoom(bounds), ZOOM_MAX));
    }
  }

  async function tick() {
    if (!geofence && !geofence?.farm_id) return;

    const geofenceClone = isNew ? null : structuredClone(geofence);

    if (!isNew && geofenceClone?.geo_json?.geometry?.type) {
      const coordinates = structuredClone(
        geofenceClone?.geo_json?.geometry?.type === GEOMETRY_TYPE.POINT
          ? geofenceClone?.geo_json?.geometry?.coordinates
          : geofence.geo_json?.geometry?.coordinates?.at(0)
      );

      if (geofenceClone?.geo_json?.geometry?.type === GEOMETRY_TYPE.POINT) {
        geofenceClone.geo_json.geometry.coordinates = reverseCoordinates(coordinates);
      } else {
        geofenceClone.geo_json.geometry.coordinates[0] = reverseCoordinates(coordinates);
      }
    }

    const features = { type: 'FeatureGroup', features: [] };
    const masterGeofences = { type: 'FeatureCollection', features: [] };
    const sites = { type: 'Feature', features: [] };

    const geofenceFeaturesClone = structuredClone(geofence?.features);
    const sitesClone = structuredClone(geofence?.sites);

    geofenceFeaturesClone?.forEach(f => {
      const target = f.is_master ? masterGeofences.features : features.features;
      target.push({ ...f.geo_json, color: f.color });
    });

    sitesClone?.forEach(s => {
      sites.features.push({ ...s.geo_json, color: s.color });
    });

    setState(prevState => ({
      ...prevState,
      features: { ...features, features: features.features.filter(f => f.properties?.id !== geofence.id) },
      features_master: masterGeofences,
      features_sites: sites,
      currentGeofence: geofenceClone ?? geofence
    }));

    const center = geofence?.geo_json?.type ? geofence?.geo_json : masterGeofences;
    await calculateMapBounds(center);
  }

  function calculateShapeSizes(geoJson) {
    const radius = geoJson?.properties?.radius;
    const area = radius ? (Math.PI * Math.pow(radius, 2)) / 10_000 : turf.area(geoJson) * 0.0001; // 1 square meter = 0.0001 hectares
    const perimeter = radius ? (2 * Math.PI * radius) / 1_000 : turf.length(geoJson, { units: 'kilometers' });

    handleShapeSizes({ size: area.toFixed(2), circumference: perimeter.toFixed(2) });
  }

  function handleCreateShape(e) {
    setState(prevState => ({ ...prevState, enableDraw: !!isNew, leaflet_id: e.layer._leaflet_id }));

    const layers = L.layerGroup().addLayer(e.layer);

    layers.eachLayer(layer => {
      if (handleCreate) {
        const geoJson = layer.toGeoJSON();

        geoJson.properties.radius = layer._mRadius ? layer._mRadius : layer._radius;

        if (handleShapeSizes && isNew) {
          calculateShapeSizes(geoJson);
        }

        handleCreate(geoJson);
      }
    });
  }

  function handleEditShape(layerGroup) {
    layerGroup.layers.eachLayer(layer => {
      if (onEdit && type === TYPE.GEOFENCE) {
        const geoJson = layer.toGeoJSON();

        if (layer._mRadius) {
          geoJson.properties.radius = layer._mRadius;
        } else if (layer._radius) {
          geoJson.properties.radius = layer._radius;
        }

        if (handleShapeSizes && isNew) {
          calculateShapeSizes(geoJson);
        }

        onEdit(geoJson);
      }
    });

    setState(prevState => ({ ...prevState, enableDraw: false }));
  }

  function handleDeleteShape() {
    setState(prevState => ({ ...prevState, enableDraw: true }));
    handleShapeSizes({ size: 0, circumference: 0 });

    if (handleDelete) handleDelete();
  }

  // function handleEditVertex(layerGroup) {
  //   if (!layerGroup.layers || type !== TYPE.GEOFENCE || isEmpty(geofence.geo_json)) return;
  //
  //   const geoJson = structuredClone(state.currentGeofence?.geo_json);
  //
  //   if (!geoJson?.geometry?.coordinates?.at(0)) return;
  //
  //   const updatedCoordinates = [];
  //
  //   layerGroup.layers.eachLayer(layer => {
  //     if (onEdit && layer._index !== undefined) {
  //       const geoJsonLayer = layer.toGeoJSON();
  //       updatedCoordinates[layer._index] = geoJsonLayer.geometry.coordinates;
  //     }
  //   });
  //
  //   if (updatedCoordinates.length > 0) {
  //     updatedCoordinates.push(updatedCoordinates?.at(0));
  //     geoJson.geometry.coordinates[0] = updatedCoordinates;
  //
  //     onEdit(geoJson);
  //   }
  // }

  function handleSave() {
    handleSaveEditing();
    exitFullScreenMode();
  }

  function exitFullScreenMode() {
    if (mapRef && mapRef?.current?.fullscreenControl) {
      document.exitFullscreen();
    }
  }

  return (
    <MapContainer
      className={classnames('map', { 'edit-controls-visible': isNew })}
      attributionControl={false}
      bounds={state.bounds || null}
      center={state.position}
      ref={mapRef}
      zoom={ZOOM}>
      <LayersControl collapsed={!L.Browser.mobile} position={LAYERS_CONTROL_POSITION}>
        <MapBaseLayers />

        <Overlay checked name="Geofence">
          <LayerGroup>
            {state.features_sites.features.length > 0 &&
              !isNew &&
              state.features_sites.features
                .filter(i => i.geometry != null && i.geometry.type != null)
                .map(i => (
                  <MapSiteFeature
                    key={JSON.stringify(i)}
                    color={i.color}
                    type={i.geometry.type}
                    geometry={i.geometry.coordinates}
                    properties={i.properties}
                  />
                ))}

            {state?.features_master?.features?.map((item, index) => (
              <Shape key={JSON.stringify({ ...item, index })} item={item} path="geofence" />
            ))}

            {state?.features?.features?.map((item, index) => (
              <Shape key={JSON.stringify({ ...item, index })} item={item} path="geofence" />
            ))}

            <FeatureGroup ref={featureGroupRef}>
              <EditControl
                draw={{
                  ...EDIT_CONTROL_DRAW_OPTIONS,
                  circle: editing &&
                    state.enableDraw && {
                      shapeOptions: { ...SHAPE_OPTIONS, color: state.currentColor, fillColor: state.currentColor }
                    },
                  polygon: editing &&
                    state.enableDraw && {
                      shapeOptions: { ...SHAPE_OPTIONS, color: state.currentColor, fillColor: state.currentColor }
                    }
                }}
                edit={{ featureGroup: featureGroupRef?.current, edit: true, remove: true }}
                position={EDIT_CONTROL_POSITION}
                onCreated={handleCreateShape}
                onDeleted={handleDeleteShape}
                onEdited={handleEditShape}
                // onEditVertex={handleEditVertex}
              />

              {state.currentGeofence?.geo_json?.type && !isNew && (
                <Feature
                  color={state.currentColor}
                  geometry={state.currentGeofence.geo_json.geometry.coordinates}
                  id={state.currentGeofence?.id}
                  type={state.currentGeofence.geo_json.geometry.type}
                />
              )}
            </FeatureGroup>
          </LayerGroup>

          {!L.Browser.mobile && <FullscreenControl position={FULLSCREEN_CONTROL_POSITION} />}
          {isFullScreen && editing && <EditButtons handleCancel={handleCancelEditing} handleReturn={handleSave} />}
        </Overlay>
      </LayersControl>
    </MapContainer>
  );
}
