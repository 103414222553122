import { memo } from 'react';

import { Input } from 'antd';

import { KEY_PRESS_CODE } from '../../constants/common';

import './styles.scss';

const { Search } = Input;

function AppSearch({ className, defaultValue, id, loading = false, placeholder, handleChange, handleKeyPress }) {
  return (
    <>
      <Search
        name="query"
        size="large"
        type="search"
        className={`head-search ${className}`}
        defaultValue={defaultValue}
        enterButton
        id={id}
        loading={loading}
        placeholder={placeholder}
        onChange={e => handleChange(e)}
        onKeyDown={() => handleKeyPress(KEY_PRESS_CODE)}
        onPressEnter={() => handleKeyPress(KEY_PRESS_CODE)}
        onSearch={() => handleKeyPress(KEY_PRESS_CODE)}
      />
    </>
  );
}

export default memo(AppSearch);
