export const PERMISSIONS = {
  ADDRESSES: {
    CREATE: 'addresses:create',
    VIEW: 'addresses:view',
    UPDATE: 'addresses:update',
    DELETE: 'addresses:delete'
  },
  ANIMALS_PHOTOS: {
    CREATE: 'animals_photos:create',
    VIEW: 'animals_photos:view',
    UPDATE: 'animals_photos:update',
    DELETE: 'animals_photos:delete'
  },
  ANIMALS: {
    CREATE: 'animals:create',
    VIEW_OWN: 'animals:view:own',
    UPDATE_OWN: 'animals:update:own',
    DELETE_OWN: 'animals:delete:own'
  },
  EVENTS: {
    CREATE: 'events:create',
    VIEW: 'events:view',
    UPDATE_OWN: 'events:update:own',
    DELETE: 'events:delete'
  },
  FARMS: {
    CREATE: 'farms:create',
    VIEW: 'farms:view',
    UPDATE_OWN: 'farms:update:own',
    DELETE_OWN: 'farms:delete:own'
  },
  GATEWAYS: {
    CREATE: 'gateways:create',
    VIEW_OWN: 'gateways:view:own',
    VIEW_ALL: 'gateways:view:all',
    UPDATE_OWN: 'gateways:update:own',
    UPDATE_ALL: 'gateways:update:all',
    DELETE_OWN: 'gateways:delete:own',
    DELETE_ALL: 'gateways:delete:all'
  },
  GEOFENCES: {
    CREATE: 'geofences:create',
    VIEW_OWN: 'geofences:view:own',
    UPDATE_OWN: 'geofences:update:own',
    DELETE_OWN: 'geofences:delete:own'
  },
  HERD_NUMBER: {
    CREATE: 'herd_number:create',
    VIEW: 'herd_number:view',
    UPDATE: 'herd_number:update',
    DELETE: 'herd_number:delete'
  },
  LABELS: {
    CREATE: 'labels:create',
    VIEW_OWN: 'labels:view:own',
    UPDATE_OWN: 'labels:update:own',
    DELETE_OWN: 'labels:delete:own'
  },
  LIVESTOCK_ACTIVITY: {
    CREATE: 'livestock_activity:create',
    VIEW: 'livestock_activity:view',
    UPDATE: 'livestock_activity:update',
    DELETE: 'livestock_activity:delete'
  },
  LIVESTOCK_BREEDING: {
    CREATE: 'livestock_breeding:create',
    VIEW_OWN: 'livestock_breeding:view:own',
    UPDATE_OWN: 'livestock_breeding:update:own',
    DELETE_OWN: 'livestock_breeding:delete:own'
  },
  LIVESTOCK_MEASURES: {
    CREATE: 'livestock_measures:create',
    VIEW_OWN: 'livestock_measures:view:own',
    UPDATE_OWN: 'livestock_measures:update:own',
    DELETE_OWN: 'livestock_measures:delete:own'
  },
  LIVESTOCK_TREATMENT: {
    CREATE: 'livestock_treatment:create',
    VIEW_OWN: 'livestock_treatment:view:own',
    UPDATE_OWN: 'livestock_treatment:update:own',
    DELETE_OWN: 'livestock_treatment:delete:own'
  },
  NOTIFICATIONS: {
    CREATE: 'notifications:create',
    VIEW_OWN: 'notifications:view:own',
    UPDATE_OWN: 'notifications:update:own',
    DELETE_OWN: 'notifications:delete:own'
  },
  NOTIFICATIONS_HAS_ACTIONS: {
    CREATE: 'notifications_has_actions:create',
    VIEW: 'notifications_has_actions:view',
    UPDATE: 'notifications_has_actions:update',
    DELETE: 'notifications_has_actions:delete'
  },
  RULES: {
    CREATE: 'rules:create',
    VIEW_OWN: 'rules:view:own',
    UPDATE_OWN: 'rules:update:own',
    DELETE_OWN: 'rules:delete:own'
  },
  SITES: {
    CREATE: 'sites:create',
    VIEW_OWN: 'sites:view:own',
    UPDATE_OWN: 'sites:update:own',
    DELETE_OWN: 'sites:delete:own'
  },
  TAGS: {
    CREATE: 'tags:create',
    VIEW_OWN: 'tags:view:own',
    VIEW_ALL: 'tags:view:all',
    UPDATE_OWN: 'tags:update:own',
    UPDATE_ALL: 'tags:update:all',
    DELETE_OWN: 'tags:delete:own',
    DELETE_ALL: 'tags:delete:all'
  },
  TAG_ANIMAL_LINK: {
    CREATE: 'tag_animal_link:create',
    VIEW: 'tag_animal_link:view',
    UPDATE_OWN: 'tag_animal_link:update:own',
    DELETE_OWN: 'tag_animal_link:delete:own'
  },
  TAG_DATA: {
    CREATE: 'tag_data:create',
    VIEW: 'tag_data:view',
    UPDATE: 'tag_data:update',
    DELETE: 'tag_data:delete'
  },
  TASKS: {
    CREATE: 'tasks:create',
    VIEW: 'tasks:view',
    UPDATE_OWN: 'tasks:update:own',
    DELETE_OWN: 'tasks:delete:own'
  },
  USER_HAS_PLANS: {
    CREATE: 'user_has_plans:create',
    VIEW: 'user_has_plans:view',
    UPDATE: 'user_has_plans:update',
    DELETE: 'user_has_plans:delete'
  },
  USERS: {
    CREATE: 'users:create',
    VIEW_OWN: 'users:view:own',
    VIEW_ALL: 'users:view:all',
    UPDATE_OWN: 'users:update:own',
    UPDATE_ALL: 'users:update:all',
    DELETE: 'users:delete'
  }
};
