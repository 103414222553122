import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { DownOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Dropdown, Flex, Space } from 'antd';

import './styles.scss';

function UserDropdown({ user, handleLogOut }) {
  const items = [
    {
      key: '1',
      label: (
        <div className="d-flex no-block align-items-center py-2 px-3 bg-primary text-white">
          <Avatar size={60} icon={<UserOutlined />} src={user.profile_picture_url} crossOrigin="anonymous" />
          <div className="ml-2">
            <h4 className="mb-0">
              {user?.first_name} {user?.last_name}
            </h4>
            <p className=" mb-0">{user?.email}</p>
          </div>
        </div>
      )
    },
    {
      key: '2',
      label: <Link to="/profile">My Profile</Link>,
      icon: <UserOutlined />
    },
    {
      key: '3',
      label: <Link to="/contact-us">Contact us</Link>,
      icon: <EditOutlined />
    }
  ];

  const contentStyle = {
    backgroundColor: '#fff',
    boxShadow:
      '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'
  };
  const menuStyle = {
    boxShadow: 'none'
  };

  return (
    <Dropdown
      overlayClassName="user-dropdown"
      placement="bottomRight"
      trigger="click"
      menu={{ items }}
      dropdownRender={menu => (
        <div style={contentStyle}>
          {React.cloneElement(menu, { style: menuStyle })}
          <Divider style={{ margin: 0 }} />
          <Space style={{ padding: '0.65rem 1rem' }}>
            <Button type="primary" onClick={handleLogOut}>
              Log Out
            </Button>
          </Space>
        </div>
      )}>
      <Flex className="trigger" gap="small" align="center" onClick={e => e.preventDefault()}>
        <Avatar size="large" icon={<UserOutlined />} src={user.profile_picture_url} crossOrigin="anonymous" />
        {user?.first_name} {user.last_name}
        <DownOutlined />
      </Flex>
    </Dropdown>
  );
}

export default memo(UserDropdown);
