import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Button, Select } from 'antd';
import debounce from 'lodash.debounce';
import moment from 'moment';
import * as XLSX from 'xlsx';

import axios from '../../axios';

import { capitalize, downloadCSV } from '../../helpers/common';
import { getFilterOptions, sortDates, sortNumbers, sortStrings } from '../../helpers/filter';
import { hasPermission } from '../../helpers/user';

import { DATE_FORMAT, EDIT_COMPONENT_TYPE, KEY_PRESS_CODE, SEARCH_DEBOUNCE_DELAY } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';
import messages from '../../constants/messages';
import { PERMISSIONS } from '../../constants/permissions';
import { TABLE_CONFIG_COLUMNS, TABLE_CONFIG_KEYS } from '../../constants/table-configurations';
import { MEASURES_CSV_TEMPLATE } from '../../constants/templates';

import {
  createMeasurement,
  createMeasurements,
  deleteMeasurements,
  fetchAnimalMeasurements,
  fetchMeasurements,
  updateMeasurement,
  updateMeasurementAndGetAll
} from '../../redux/actions/measurement';
import { setSelectedFilters } from '../../redux/actions/measurement';
import { fetchMeasurementTypes } from '../../redux/actions/measurement-type';

import AppModal from '../../components/AppModal';
import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import UploadModal from '../../components/Modals/UploadModal';

import { errorToastHandler } from '../action_notifier';
import CreateMeasurementsModal from './CreateMeasurementsModal';

function MeasurementsTable(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const { selectedFilters } = useSelector(state => state.measurement);
  const user = useSelector(state => state.user);
  const { data: measurements, loading: measurementsLoading } = useSelector(state => state.measurement);
  const { data: measurementTypes, loading: measurementTypesLoading } = useSelector(state => state.measurementType);
  const [tableData, setTableData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [state, setState] = useState({
    actions: [],
    props: props,
    bulk_modal: false,
    columnFilterOptions: {
      breeds: [],
      majorMeasurementNames: [],
      minorMeasurementNames: [],
      sex: []
    },
    data: [],
    newRows: [],
    measureTypes: {},
    selectedIds: [],
    isOpenSelectionPopover: false,
    sortedTableData: [],
    majorMinors: [],
    measureForIds: [],
    common_data: {
      major_measure_id: [],
      minor_measure_id: [],
      uom: '',
      measurement: '0',
      measurement_date: null,
      comments: ''
    },
    majorFilter: null,
    minorFilter: null,

    filters: {
      farms: [],
      geofences: [],
      labels: []
    },
    query: ''
  });

  useEffect(() => {
    initActions();
  }, [state.selectedIds, selectedFilters]);

  useEffect(() => {
    if (measurements) {
      setState(prevState => ({
        ...prevState,
        columnFilterOptions: {
          ...prevState.columnFilterOptions,
          breeds: getFilterOptions(measurements, 'breed'),
          majorMeasurementNames: getFilterOptions(measurements, 'major_measurement_name'),
          minorMeasurementNames: getFilterOptions(measurements, 'minor_measurement_name'),
          sex: getFilterOptions(measurements, 'sex')
        }
      }));
      setTableData([...measurements]);
    }
  }, [measurements, measurementTypes]);

  useEffect(() => {
    fetchData();
    initActions();
  }, []);

  async function fetchData() {
    try {
      await Promise.all([dispatch(fetchMeasurementTypes()), getMeasurements()]);
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
    }
  }

  async function getMeasurements() {
    params?.id
      ? dispatch(fetchAnimalMeasurements(+params?.id, { query: state.query }))
      : dispatch(
          fetchMeasurements({
            query: state.query,
            farm_ids: state.filters?.farms?.map(x => x.value),
            label_ids: state.filters?.labels?.map(x => x.value),
            geofence_ids: state.filters?.geofences?.map(x => x.value)
          })
        );
  }

  function initActions() {
    const availableActions = [];

    if (hasPermission([PERMISSIONS.LIVESTOCK_MEASURES.CREATE], user.permissions)) {
      availableActions.push({
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!params.id && !state.selectedIds?.length}
            onClick={() => toggleModal('bulk_modal')}>
            Add measurement
          </Button>
        )
      });
    }

    if (hasPermission([PERMISSIONS.LIVESTOCK_MEASURES.CREATE], user.permissions)) {
      availableActions.push({
        label: (
          <Button color="default" size="small" variant="link" onClick={() => toggleModal('csv_modal')}>
            Upload measurement
          </Button>
        )
      });
    }

    availableActions.push({
      label: (
        <Button
          color="default"
          size="small"
          variant="link"
          disabled={!state.selectedIds?.length}
          onClick={() => downloadCSVFile()}>
          Download measures list
        </Button>
      )
    });

    if (hasPermission([PERMISSIONS.LIVESTOCK_MEASURES.DELETE_OWN], user.permissions)) {
      availableActions.push({
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!state.selectedIds?.length}
            onClick={() => toggleModal('delete_modal')}>
            Archive measurement
          </Button>
        )
      });
    }

    availableActions.push({
      label: (
        <Button
          color="default"
          size="small"
          variant="link"
          disabled={!Object.values(selectedFilters)?.length}
          onClick={() => clearFilters()}>
          Clear all filters
        </Button>
      )
    });

    setState(prevState => ({
      ...prevState,
      actions: availableActions
    }));
  }

  const clearFilters = () => {
    setFilteredInfo({});
    dispatch(setSelectedFilters({}));
  };

  const setFilters = (pagination, filters) => {
    setFilteredInfo(filters);
    dispatch(setSelectedFilters(filters));
  };

  async function downloadCSVFile() {
    if (state?.selectedIds?.length) {
      try {
        const query = `measure_ids=${state?.selectedIds}`;
        const response = await axios.get(`/measures/export?${query}`, {
          responseType: 'blob'
        });

        downloadCSV(response.data, `Measures_${moment().format(DATE_FORMAT.DATE)}.xlsx`);
      } catch (error) {
        errorToastHandler(messages.FAILED_ON_DOWNLOAD_CSV);
      }
    }
  }

  async function uploadCSV() {
    try {
      let csv = state.csvfile;
      await axios.post('measures/uploadCsv', {
        csv: csv
      });
      toggleModal('csv_modal');
      await getMeasurements();
    } catch (error) {
      console.error(error);
    }
  }

  async function handleChangeTableData(updatedData) {
    const record = updatedData.at(0);
    const id = isNaN(record.measure_id) ? null : record.measure_id;
    const data = {
      id,
      comments: record.comments,
      livestock_id: parseInt(record.livestock_id),
      major_measure_id: parseInt(record.major_measure_id),
      measure_id: parseInt(record.measure_id),
      measurement: parseInt(record.measurement),
      measurement_date: moment(record.measurement_date).format(DATE_FORMAT.ISO_8601),
      minor_measure_id: parseInt(record.minor_measure_id)
    };

    params.id ? dispatch(updateMeasurement(id, data)) : dispatch(updateMeasurementAndGetAll(id, data));
  }

  async function handleChangeMinorMeasureType(newValue, record) {
    const id = isNaN(record.measure_id) ? null : record.measure_id;
    const data = {
      id,
      livestock_id: parseInt(record.livestock_id),
      major_measure_id: parseInt(record.major_measure_id),
      measure_id: parseInt(record.measure_id),
      measurement_date: moment(record.measurement_date).format(DATE_FORMAT.ISO_8601),
      minor_measure_id: parseInt(newValue)
    };

    params.id ? dispatch(updateMeasurement(id, data)) : dispatch(updateMeasurementAndGetAll(id, data));
  }

  const commonColumnProperties = {
    ellipsis: true,
    sortDirections: ['ascend', 'descend']
  };

  const columns = [
    {
      ...commonColumnProperties,
      title: 'Measure ID',
      dataIndex: 'measure_identifier',
      configColumnKey: TABLE_CONFIG_COLUMNS.MEASURE_ID,
      filteredValue: filteredInfo.measure_identifier || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortStrings(a.measure_identifier, b.measure_identifier)
    },
    {
      ...commonColumnProperties,
      title: 'Major Measure Type',
      dataIndex: 'major_measurement_name',
      configColumnKey: TABLE_CONFIG_COLUMNS.MAJOR_MEASURE_TYPE,
      filteredValue: filteredInfo.major_measurement_name || null,
      filters: state.columnFilterOptions?.majorMeasurementNames,
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.major_measurement_name : record?.major_measurement_name === value,
      sorter: (a, b) => sortStrings(a.major_measurement_name, b.major_measurement_name)
    },
    {
      ...commonColumnProperties,
      title: 'Minor Measure Type',
      dataIndex: 'minor_measurement_name',
      configColumnKey: TABLE_CONFIG_COLUMNS.MINOR_MEASURE_TYPE,
      filteredValue: filteredInfo.minor_measurement_name || null,
      filters: state.columnFilterOptions?.minorMeasurementNames,
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.minor_measurement_name : record?.minor_measurement_name === value,
      sorter: (a, b) => sortStrings(a.minor_measurement_name, b.minor_measurement_name),
      render: (value, record) => {
        const majorMeasurementName = record.major_measurement_name || record.major_measure_name;
        const minorMeasurementName = record.minor_measurement_name || record.minor_measure_name;

        if (majorMeasurementName && minorMeasurementName && measurementTypes?.length) {
          const majors = measurementTypes?.find(i => i.major_measurement_name === majorMeasurementName);
          const options = majors?.measure_minors?.map(i => ({
            label: i.minor_measurement_name,
            value: i.minor_measurement_id
          }));
          const currentVal = majors?.measure_minors?.find(m => m.minor_measurement_name === minorMeasurementName);
          const defaultVal = currentVal
            ? { label: currentVal.minor_measurement_name, value: currentVal.minor_measurement_name }
            : null;

          return (
            <Select
              size="small"
              variant="borderless"
              options={options}
              style={{ height: '21px', width: '100%' }}
              value={defaultVal}
              onChange={newValue => handleChangeMinorMeasureType(newValue, record)}
            />
          );
        }
      }
    },
    {
      ...commonColumnProperties,
      title: 'UOM',
      dataIndex: 'uom',
      configColumnKey: TABLE_CONFIG_COLUMNS.UOM,
      filteredValue: filteredInfo.uom || null,
      searchable: true,
      width: COLUMN_SIZE.XS,
      sorter: (a, b) => sortStrings(a.uom, b.uom)
    },
    {
      ...commonColumnProperties,
      title: 'Measurement',
      dataIndex: 'measurement',
      configColumnKey: TABLE_CONFIG_COLUMNS.MEASUREMENT,
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.measurement || null,
      rules: [{ required: true, message: 'Required.' }],
      searchable: true,
      width: COLUMN_SIZE.XS,
      onFilter: (value, record) => record.measurement === +value,
      sorter: (a, b) => sortNumbers(a.measurement, b.measurement)
    },
    {
      ...commonColumnProperties,
      title: 'Measurement Date',
      dataIndex: 'measurement_date',
      configColumnKey: TABLE_CONFIG_COLUMNS.MEASUREMENT_DATE,
      editConfig: { type: EDIT_COMPONENT_TYPE.DATE_PICKER },
      filteredValue: filteredInfo.measurement_date || null,
      rules: [{ required: true, message: 'Required.' }],
      searchable: true,
      width: COLUMN_SIZE.LG,
      render: (value, record) =>
        record.measurement_date ? moment(record.measurement_date).format(DATE_FORMAT.DATE) : null,
      sorter: (a, b) => sortDates(a.measurement_date, b.measurement_date)
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      configColumnKey: TABLE_CONFIG_COLUMNS.COMMENTS,
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.comments || null,
      searchable: true,
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortStrings(a.comments, b.comments)
    }
  ];

  const livestockColumns = [
    {
      ...commonColumnProperties,
      title: 'Livestock ID',
      dataIndex: 'identifier',
      configColumnKey: TABLE_CONFIG_COLUMNS.LIVESTOCK_ID,
      filteredValue: filteredInfo.identifier || null,
      fixed: 'left',
      searchable: true,
      width: COLUMN_SIZE.MD,
      render: (value, record) => <Link to={`animal/${record.id}`}>{record.identifier}</Link>,
      sorter: (a, b) => sortStrings(a.identifier, b.identifier)
    },
    {
      ...commonColumnProperties,
      title: 'Mgmt Tag ID',
      dataIndex: 'eartag_management_id',
      configColumnKey: TABLE_CONFIG_COLUMNS.MGMT_TAG_ID,
      filteredValue: filteredInfo.eartag_management_id || null,
      searchable: true,
      width: COLUMN_SIZE.XL,
      sorter: (a, b) => sortStrings(a.eartag_management_id, b.eartag_management_id)
    },
    {
      ...commonColumnProperties,
      title: 'Sex',
      dataIndex: 'sex',
      configColumnKey: TABLE_CONFIG_COLUMNS.SEX,
      filteredValue: filteredInfo.sex || null,
      filters: state.columnFilterOptions?.sex,
      width: COLUMN_SIZE.LG,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.sex : record?.sex === value),
      render: value => capitalize(value),
      sorter: (a, b) => sortStrings(a.sex, b.sex)
    },
    {
      ...commonColumnProperties,
      title: 'Colour',
      dataIndex: 'colour',
      configColumnKey: TABLE_CONFIG_COLUMNS.COLOUR,
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      },
      ellipsis: true,
      filteredValue: filteredInfo.colour || null,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortStrings(a.colour, b.colour)
    },
    {
      ...commonColumnProperties,
      title: 'Breed',
      dataIndex: 'breed',
      configColumnKey: TABLE_CONFIG_COLUMNS.BREED,
      width: COLUMN_SIZE.XL,
      filters: state.columnFilterOptions?.breeds,
      filteredValue: filteredInfo.breed || null,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.breed : record?.breed === value),
      sorter: (a, b) => sortStrings(a.breed, b.breed)
    }
  ];

  const tableColumns = params?.id ? [...columns] : [...livestockColumns, ...columns];

  const debounceSearch = useCallback(
    debounce(cb => {
      cb();
    }, SEARCH_DEBOUNCE_DELAY),
    []
  );

  function onChange(value, field) {
    setState(prevState => ({ ...prevState, [field]: value }));
  }

  async function handleKeyPress(charCode) {
    if (charCode === KEY_PRESS_CODE) {
      debounceSearch(getMeasurements);
    }
  }

  function toggleModal(modal) {
    setState(prevState => ({ ...prevState, [modal]: !prevState[modal] }));
  }

  function createMeasurementsForSelectedAnimals(data) {
    const records = measurements.filter(m => state.selectedIds.includes(m.id));
    const measures = records.map(record => ({
      comments: data.comments || '',
      id: isNaN(record.measure_id) ? null : record.measure_id,
      livestock_id: parseInt(record.livestock_id),
      major_measure_id: parseInt(data.majorMeasurementType),
      measure_id: parseInt(record.measure_id),
      measurement: parseFloat(record.measurement),
      measurement_date: moment(data.measurement_date).format(DATE_FORMAT.ISO_8601), // 'YYYY-MM-DD'
      minor_measure_id: parseInt(data.minorMeasurementType),
      uom: data.uom
    }));
    dispatch(createMeasurements({ measures }));
  }

  function createMeasurementForCurrentAnimal(data) {
    const measures = {
      comments: data.comments || '',
      livestock_id: parseInt(params.id),
      major_measure_id: parseInt(data.majorMeasurementType),
      measurement: parseFloat(data.measurement),
      measurement_date: moment(data.measurement_date).format(DATE_FORMAT.ISO_8601),
      minor_measure_id: parseInt(data.minorMeasurementType),
      uom: data.uom
    };
    dispatch(createMeasurement(+params.id, measures));
  }

  function handleCreateMeasurements(data) {
    if (!state.selectedIds?.length && !params.id) return;

    state.selectedIds?.length && !params.id
      ? createMeasurementsForSelectedAnimals(data)
      : createMeasurementForCurrentAnimal(data);

    toggleModal('bulk_modal');
  }

  function selectRecords(selected) {
    setState(prevState => ({
      ...prevState,
      selectedIds: selected,
      selectAll: prevState.selectedIds?.length === selected?.length
    }));
  }

  function handleCSVChange(event) {
    setState(prevState => ({ ...prevState, uploading: true }));

    let reader = new FileReader();
    const isXLSX = event.target.files?.[0]?.name?.includes('.xlsx');

    if (!isXLSX) reader.readAsText(event.target.files[0]);

    const rABS = !!reader.readAsBinaryString;

    reader.onload = function (e) {
      let data = '';
      if (isXLSX) {
        let wb = XLSX.read(e.target.result, {
          type: rABS ? 'binary' : 'array'
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        data = XLSX.utils.sheet_to_csv(ws, { strip: true });
      } else {
        data = reader.result;
      }
      setState(prevState => ({
        ...prevState,
        csvfile: data,
        uploading: false
      }));
    }.bind(this);
    if (isXLSX) {
      if (rABS) reader.readAsBinaryString(event.target.files[0]);
      else reader.readAsArrayBuffer(event.target.files[0]);
    }

    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  }

  function deleteRecords() {
    dispatch(deleteMeasurements(state.selectedIds));
    toggleModal('delete_modal');
  }

  const upload = () => uploadCSV();
  const handle = e => handleCSVChange(e);

  return (
    <>
      <div className={props.className}>
        <AppTable
          headerClass="py-2"
          searchable={props.searchable}
          breadcrumb={props.breadcrumb}
          tableConfigKey={TABLE_CONFIG_KEYS.LIVESTOCK_MEASURES}
          title={props.title}
          searchPlaceholder="Search measures"
          actions={state.actions}
          baseColumns={tableColumns}
          dataSource={tableData}
          loading={measurementsLoading || measurementTypesLoading}
          searchDefaultValue={state.query}
          handleOnChange={setFilters}
          handleOnSelectRecords={selectRecords}
          handleSearchChange={e => onChange(e.target.value, 'query')}
          handleSearchKeyPress={() => handleKeyPress(KEY_PRESS_CODE)}
          updateTableData={handleChangeTableData}
        />
      </div>

      <CreateMeasurementsModal
        isOpen={state.bulk_modal}
        handleCancel={() => toggleModal('bulk_modal')}
        handleConfirm={values => handleCreateMeasurements(values)}
      />

      <AppModal
        isOpen={state.delete_modal}
        confirmButtonColor="danger"
        confirmButtonText="Archive"
        title="Archive measurement"
        handleCancel={() => toggleModal('delete_modal')}
        handleConfirm={deleteRecords}>
        <div className="py-4">
          Are you sure to archive these {state.selectedIds?.length === 1 ? 'record' : 'records'}?
        </div>
      </AppModal>

      <UploadModal
        filename="measures-template.csv"
        isOpen={state.csv_modal}
        onUpload={upload}
        handleCSVChange={handle}
        template={MEASURES_CSV_TEMPLATE}
        onCancel={() => toggleModal('csv_modal')}
        description={
          <>
            <p>You can download the measures list to input new measures.</p>
            <p>Download and save the file, then choose this file to upload new measurements.</p>
          </>
        }
      />
    </>
  );
}

export default memo(MeasurementsTable);
