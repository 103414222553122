import axios from '../../axios';

const service = {
  getTableConfigs() {
    return axios.get('/table-configs');
  },

  bulkUpdate(tables) {
    return axios.put('/table-configs/bulk', { tables });
  },

  updateColumnConfigurations(tableId, columns) {
    return axios.put(`/table-configs/${tableId}/columns`, { columns });
  }
};

export default service;
