import messages from '../../../constants/messages';

export const FIELD_TYPE = {
  INPUT: 'INPUT',
  NUMBER: 'NUMBER',
  SELECT: 'SELECT',
  TEXTAREA: 'TEXTAREA'
};

const KEY_MAP = {
  color: 'Site Color',
  description: 'Site Description',
  geofence_ids: 'Site Geofences',
  identifier: 'Site ID',
  name: 'Site Name',
  rules: 'Site Rules',
  type: 'Site Type'
};

export const CREATE_SITE_BLACKLIST = ['identifier', 'geofence_ids', 'rules'];

export const SITE_FORM_FIELDS = [
  {
    id: 'identifier',
    editable: false,
    type: FIELD_TYPE.INPUT,
    name: KEY_MAP['identifier']
  },
  {
    id: 'name',
    editable: true,
    type: FIELD_TYPE.INPUT,
    name: KEY_MAP['name'],
    rules: [
      {
        required: true,
        message: messages.DATA_REQUIRED
      }
    ]
  },
  {
    id: 'type',
    editable: true,
    type: FIELD_TYPE.SELECT,
    name: KEY_MAP['type'],
    rules: [
      {
        required: true,
        message: messages.DATA_REQUIRED
      }
    ]
  },
  {
    id: 'geofence_ids',
    editable: false,
    type: FIELD_TYPE.SELECT,
    mode: 'multiple',
    name: KEY_MAP['geofence_ids']
  },
  {
    id: 'rules',
    editable: false,
    type: FIELD_TYPE.INPUT,
    name: KEY_MAP['rules']
  },
  {
    id: 'description',
    editable: true,
    type: FIELD_TYPE.TEXTAREA,
    name: KEY_MAP['description']
  }
];
