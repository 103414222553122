import { memo, useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import moment from 'moment';

import { capitalize } from '../../../helpers/common';
import { formatDate } from '../../../helpers/date';
import { getTaskNullState, getTaskStatus, validateTask } from '../../../helpers/task';
import { hasPermission } from '../../../helpers/user';

import { DATE_FORMAT } from '../../../constants/common';
import { RECURRING_TYPES, RELATION_TYPES } from '../../../constants/event';
import { PERMISSIONS } from '../../../constants/permissions';
import { TASK_MODAL_MODE } from '../../../constants/task';
import { TASK_PRIORITIES, TASK_STATUSES } from '../../../constants/task';

import {
  acceptTask,
  createNewTask,
  declineTask,
  deleteTask,
  getAllTasks,
  getTaskById,
  sendRelationQuery,
  updateTask
} from '../../../redux/actions/task';

import { errorToastHandler } from '../../action_notifier';
import ConfirmationModal from '../ConfirmationModal';
import './styles.scss';

const TaskModal = ({ isOpen, onCancel, onSubmit, mode = TASK_MODAL_MODE.CREATE }) => {
  const TASK_NULL_STATE = getTaskNullState();
  const dispatch = useDispatch();
  const taskState = useSelector(state => state.task);
  const userState = useSelector(state => state.user);

  useEffect(() => {
    if (mode !== TASK_MODAL_MODE.CREATE && taskState.currentTask.id && isOpen) {
      dispatch(getTaskById(taskState.currentTask.id));
    }
  }, [taskState?.currentTask?.id, isOpen]);

  const [modalMode, setModalMode] = useState(mode);
  const [isOpenConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [newTask, setNewTask] = useState(TASK_NULL_STATE);
  const [relationOptions, setRelationOptions] = useState([]);

  const getRelationOptions = relationType => {
    dispatch(sendRelationQuery(relationType)).then(data => {
      setRelationOptions(data);
    });
  };

  const onCancelClick = () => {
    setOpenConfirmationModal(false);
    setNewTask(TASK_NULL_STATE);
    onCancel();
    setModalMode(mode);
  };

  useEffect(() => {
    if (taskState.currentTask.is_owner === false) {
      setModalMode(TASK_MODAL_MODE.GUEST);
    }
  }, [taskState.currentTask.is_owner]);

  useEffect(() => {
    if (newTask.relationType) {
      getRelationOptions(newTask.relationType);
    }
  }, [newTask.relationType]);

  const onChange = (value, field) => {
    const formattedValue = value ? value : TASK_NULL_STATE[field];
    setNewTask(prevState => {
      return {
        ...prevState,
        [field]: formattedValue
      };
    });
  };

  const onChangeRelation = (values, field) => {
    if (values) {
      const { value, label } = values;
      setNewTask(prevState => {
        return {
          ...prevState,
          [field]: value,
          relationName: label
        };
      });
    } else {
      setNewTask(prevState => {
        return {
          ...prevState,
          [field]: null,
          relationName: null
        };
      });
    }
  };

  const onCreateClick = () => {
    const payload = {
      ...newTask
    };
    delete payload.userEmails;
    delete payload.relationName;
    const error = validateTask(payload);
    if (error) {
      errorToastHandler(error);
    } else {
      dispatch(createNewTask(payload)).then(res => {
        if (res) {
          dispatch(getAllTasks(taskState.queryParams));
          onSubmit();
        }
      });
    }
  };

  const onDeleteSubmit = () => {
    dispatch(deleteTask(taskState.currentTask.id)).then(() => {
      dispatch(getAllTasks(taskState.queryParams));
      setOpenConfirmationModal(false);
      setNewTask(TASK_NULL_STATE);
      onCancel();
    });
  };

  const onDeleteClick = () => {
    setOpenConfirmationModal(true);
  };

  const onEditClick = () => {
    const task = {
      ...taskState.currentTask,
      dueDate: moment(taskState.currentTask.due_date).format(DATE_FORMAT.DATETIME),
      remindDate: taskState.currentTask.remind_at
        ? moment(taskState.currentTask.remind_at).format(DATE_FORMAT.DATE)
        : null
    };
    setNewTask(task);
    setModalMode(TASK_MODAL_MODE.UPDATE);
  };

  const onUpdateClick = () => {
    const formattedTask = {
      title: newTask.title,
      description: newTask.description,
      status: newTask.status,
      priority: newTask.priority,
      dueDate: moment(newTask.dueDate, DATE_FORMAT.DATETIME).format(),
      remindDate: newTask.remindDate ? moment(newTask.remindDate, DATE_FORMAT.DATE).format() : null,
      // eslint-disable-next-line no-unsafe-optional-chaining
      userIds: [...newTask?.userIds],
      relationType: newTask.relationType || newTask.relation_type,
      relationId: newTask.relationId || newTask.relation_id
    };
    const error = validateTask(formattedTask);
    if (error) {
      errorToastHandler(error);
    } else {
      dispatch(updateTask(newTask.id, formattedTask)).then(res => {
        if (res) {
          dispatch(getAllTasks(taskState.queryParams));
          setNewTask(TASK_NULL_STATE);
          setModalMode(TASK_MODAL_MODE.VIEW);
          onCancel();
        }
      });
    }
  };

  const onDeclineClick = () => {
    dispatch(declineTask(taskState.currentTask.id)).then(res => {
      if (res) {
        dispatch(getAllTasks(taskState.queryParams));
        setNewTask(TASK_NULL_STATE);
        setModalMode(TASK_MODAL_MODE.GUEST);
        onCancel();
      }
    });
  };

  const onAcceptClick = () => {
    dispatch(acceptTask(taskState.currentTask.id)).then(res => {
      if (res) {
        dispatch(getAllTasks(taskState.queryParams));
        setNewTask(TASK_NULL_STATE);
        setModalMode(TASK_MODAL_MODE.GUEST);
        onCancel();
      }
    });
  };
  return (
    <div className="task-modal-component">
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        onCancel={() => setOpenConfirmationModal(false)}
        title={'Delete task'}
        description={'Are you sure you want to delete this task? This action cannot be undone.'}
        onSubmit={onDeleteSubmit}
      />

      <Modal size="lg" isOpen={isOpen} className="task-modal">
        <ModalBody>
          <br />
          <h5 className="text-center">
            <strong>{capitalize(modalMode)} task</strong>
          </h5>
          <br />
          {modalMode === TASK_MODAL_MODE.VIEW || modalMode === TASK_MODAL_MODE.GUEST ? (
            <div className="modal-view-mode">
              <Row>
                <Col sm="4">Title*</Col>
                <Col sm="8">{taskState.currentTask.title}</Col>
              </Row>
              <Row>
                <Col sm="4">Description</Col>
                <Col sm="8">{taskState.currentTask.description || '/'}</Col>
              </Row>
              <Row>
                <Col sm="4">Status*</Col>
                <Col sm="8">{taskState.currentTask.status || '/'}</Col>
              </Row>
              <Row>
                <Col sm="4">Priority*</Col>
                <Col sm="8">{taskState.currentTask.priority || '/'}</Col>
              </Row>
              <Row>
                <Col sm="4">Due date*</Col>
                <Col sm="8">{formatDate(taskState.currentTask.due_date)}</Col>
              </Row>
              <Row>
                <Col sm="4">Remind date</Col>
                <Col sm="8">
                  {taskState.currentTask.remind_at
                    ? moment(taskState.currentTask.remind_at).format(DATE_FORMAT.DATETIME)
                    : '/'}
                </Col>
              </Row>
              <Row>
                <Col sm="4">Relation</Col>
                <Col sm="8">{taskState.currentTask.relation_type || '/'}</Col>
              </Row>
              {taskState.currentTask.relation_type && taskState.currentTask.relation_type ? (
                <Row>
                  <Col sm="4">{capitalize(taskState.currentTask.relation_type)}</Col>
                  <Col sm="8">{taskState.currentTask.relation?.identifier || '/'}</Col>
                </Row>
              ) : null}
              <Row>
                <Col sm="4">Recurring type</Col>
                <Col sm="8">{capitalize(taskState.currentTask.recurring_type) || '/'}</Col>
              </Row>
              {/* {taskState.currentTask.recurring_type ? (
                <Row>
                  <Col sm="4">Recurring end date</Col>
                  <Col sm="8">
                    {taskState.currentTask.recurring_end || '/'}
                  </Col>
                </Row>
              ) : null} */}
              {!taskState.currentTask?.users?.length ? (
                <Row>
                  <Col sm="4">User</Col>
                  <Col sm="8">/</Col>
                </Row>
              ) : (
                taskState.currentTask?.users?.map((u, i) => {
                  if (i === 0) {
                    return (
                      <Row key={u.id}>
                        <Col sm="4">Users</Col>
                        <Col sm="8">
                          {u.email} -{' '}
                          <span className={getTaskStatus(u.status).toLowerCase()}>{getTaskStatus(u.status)}</span>
                        </Col>
                      </Row>
                    );
                  }
                  return (
                    <Row key={u.id}>
                      <Col sm="4"></Col>
                      <Col sm="8">
                        {u.email} -{' '}
                        <span className={getTaskStatus(u.status).toLowerCase()}>{getTaskStatus(u.status)}</span>
                      </Col>
                    </Row>
                  );
                })
              )}
            </div>
          ) : null}

          {modalMode === TASK_MODAL_MODE.CREATE || modalMode === TASK_MODAL_MODE.UPDATE ? (
            <Form>
              <FormGroup row>
                <Label sm="4">Title*</Label>
                <Col sm="8">
                  <Input type="text" value={newTask.title} onChange={e => onChange(e.target.value, 'title')} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Description</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    value={newTask.description}
                    onChange={e => onChange(e.target.value, 'description')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Status*</Label>
                <Col sm="8">
                  <Select
                    value={{ label: newTask.status }}
                    onChange={e => onChange(e?.value, 'status')}
                    options={TASK_STATUSES.map(type => {
                      return { value: type, label: type };
                    })}
                    isClearable={true}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Priority*</Label>
                <Col sm="8">
                  <Select
                    value={{ label: newTask.priority }}
                    onChange={e => onChange(e?.value, 'priority')}
                    options={TASK_PRIORITIES.map(type => {
                      return { value: type, label: type };
                    })}
                    isClearable={true}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Due date*</Label>
                <Col sm="8">
                  <Datetime value={newTask.dueDate} onChange={e => onChange(e, 'dueDate')} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Remind date</Label>
                <Col sm="8">
                  <Datetime timeFormat={false} value={newTask.remindDate} onChange={e => onChange(e, 'remindDate')} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Relation</Label>
                <Col sm="8">
                  <Select
                    value={{
                      value: newTask.relationType,
                      label: capitalize(newTask.relationType)
                    }}
                    onChange={e => onChange(e?.value, 'relationType')}
                    options={RELATION_TYPES.map(type => {
                      return { value: type, label: capitalize(type) };
                    })}
                    isClearable={true}
                  />
                </Col>
              </FormGroup>

              {newTask.relationType ? (
                <FormGroup row>
                  <Label sm="4">{capitalize(newTask.relationType)}</Label>
                  <Col sm="8">
                    <Select
                      value={{
                        value: newTask.relationId,
                        label: newTask.relationName
                      }}
                      onChange={e => onChangeRelation(e, 'relationId')}
                      options={relationOptions?.map(option => {
                        return {
                          value: option.id,
                          label: option.identifier + ' ' + option.name
                        };
                      })}
                      isClearable={true}
                    />
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup row>
                <Label sm="4">Recurring type</Label>
                <Col sm="8">
                  <Select
                    value={{ label: newTask.recurringType }}
                    onChange={e => onChange(e?.value, 'recurringType')}
                    options={RECURRING_TYPES.map(type => {
                      return { value: type, label: type };
                    })}
                    isClearable={true}
                  />
                </Col>
              </FormGroup>

              {newTask.recurringType ? (
                <FormGroup row>
                  <Label sm="4">Recurring end date</Label>
                  <Col sm="8">
                    <Datetime value={newTask.recurringEndDate} onChange={e => onChange(e, 'recurringEndDate')} />
                  </Col>
                </FormGroup>
              ) : null}

              <FormGroup row>
                <Label sm="4">User</Label>
                <Col sm="8">
                  <Select
                    value={newTask.userEmails.map(email => {
                      return { label: email };
                    })}
                    onChange={(e, value) => {
                      const removedValueLabel = value?.removedValue?.label;
                      const addedValue = e.value;
                      let userIds = [];
                      let userEmails = [];
                      if (removedValueLabel) {
                        const user = userState.users.find(u => u.email === removedValueLabel);
                        userIds = newTask?.userIds.filter(id => id !== user.id);
                        userEmails = newTask?.userEmails.filter(email => email !== user.email);
                      } else if (addedValue) {
                        userIds = [addedValue, ...newTask.userIds];
                        userEmails = [e.label, ...newTask.userEmails];
                      }
                      setNewTask(prevState => {
                        return {
                          ...prevState,
                          userIds: userIds,
                          userEmails: userEmails
                        };
                      });
                    }}
                    options={userState.users?.map(user => {
                      return { value: user.id, label: user.email };
                    })}
                    isSearchable={true}
                  />
                </Col>
              </FormGroup>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter style={{ display: 'block' }}>
          {modalMode === TASK_MODAL_MODE.VIEW ? (
            <Row>
              {hasPermission([PERMISSIONS.TASKS.DELETE_OWN], userState.permissions) && (
                <Col>
                  <Button color="danger" onClick={onDeleteClick}>
                    Delete
                  </Button>
                </Col>
              )}
              <Col style={{ textAlign: 'end' }}>
                <Button color="secondary" onClick={onCancelClick}>
                  Cancel
                </Button>{' '}
                {hasPermission([PERMISSIONS.TASKS.UPDATE_OWN], userState.permissions) && (
                  <Button color="primary" onClick={onEditClick}>
                    Edit
                  </Button>
                )}
              </Col>
            </Row>
          ) : null}
          {modalMode === TASK_MODAL_MODE.UPDATE ? (
            <Col style={{ textAlign: 'end' }}>
              <Button color="secondary" onClick={onCancelClick}>
                Cancel
              </Button>{' '}
              <Button color="primary" onClick={onUpdateClick}>
                Update
              </Button>
            </Col>
          ) : null}
          {modalMode === TASK_MODAL_MODE.CREATE ? (
            <Col style={{ textAlign: 'end' }}>
              <Button color="secondary" onClick={onCancelClick}>
                Cancel
              </Button>{' '}
              <Button color="primary" onClick={onCreateClick}>
                Create
              </Button>
            </Col>
          ) : null}
          {modalMode === TASK_MODAL_MODE.GUEST ? (
            <Row>
              <Col>
                <Button color="secondary" onClick={onCancelClick}>
                  Cancel
                </Button>
              </Col>
              <Col style={{ textAlign: 'end' }}>
                <Button color="danger" onClick={onDeclineClick}>
                  Decline
                </Button>{' '}
                <Button color="primary" onClick={onAcceptClick}>
                  Accept
                </Button>
              </Col>
            </Row>
          ) : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default memo(TaskModal);
