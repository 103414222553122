import { memo } from 'react';
import { Circle, GeoJSON } from 'react-leaflet';

import { GEOMETRY_TYPE } from '../../constants/map';

import MapPopup from './MapPopup';

const styles = {
  weight: 3,
  fillOpacity: 0.2,
  opacity: 0.8
};

function Shape({ item, path }) {
  return item.geometry?.type === GEOMETRY_TYPE.POLYGON ? (
    <GeoJSON data={item} style={{ ...styles, color: item.color }}>
      <MapPopup
        description={item.properties.description}
        link={`${path}/${item.properties?.id}`}
        name={item.properties.name}
      />
    </GeoJSON>
  ) : (
    <Circle
      center={[item.geometry.coordinates[1], item.geometry.coordinates[0]]}
      color={item.color}
      fillColor={item.color}
      fillOpacity={0.2}
      opacity={1}
      radius={!isNaN(item.properties.radius) ? item.properties.radius : 1}
      weight={2}>
      <MapPopup
        description={item.properties.description}
        link={`${path}/${item.properties?.id}`}
        name={item.properties.name}
      />
    </Circle>
  );
}

export default memo(Shape);
