import { createAsyncThunk } from '@reduxjs/toolkit';

import { SiteService } from '../../services';

export const createSite = createAsyncThunk('site/createSite', async (data, { rejectWithValue }) => {
  try {
    const response = await SiteService.createSite(data);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to create site');
  }
});

export const fetchSites = createAsyncThunk('site/fetchSites', async (params, { rejectWithValue }) => {
  try {
    const response = await SiteService.fetchSites(params);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch sites');
  }
});

export const updateSite = createAsyncThunk('site/updateSite', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await SiteService.updateSite(id, data);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to update site');
  }
});

export const deleteSites = createAsyncThunk('site/deleteSites', async (ids, { rejectWithValue }) => {
  try {
    await SiteService.deleteSites(ids);

    return ids;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to delete sites');
  }
});
