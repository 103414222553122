import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as turf from '@turf/turf';
import { Card, Flex, Form, Select, Typography } from 'antd';
import isEmpty from 'lodash.isempty';

import messages from '../../../constants/messages';

import { fetchFarms } from '../../../redux/actions/farm';
import { createGeofence } from '../../../redux/actions/geofence';

import CancelSaveButtons from '../../../components/CancelSaveButtons';
import GeofenceEditForm from '../../../components/Geofence/GeofenceEditForm';
import MapGeofence from '../../../components/Maps/MapGeofence';
import { DEFAULT_SHAPE_COLOR, TYPE } from '../../../components/Maps/constants';
import { errorToastHandler, successToastHandler, warnToastHandler } from '../../../components/action_notifier';

import AppBreadcrumb from '../../ui-components/AppBreadcrumb';

import './styles.css';

const { Title } = Typography;

function GeofenceNew({ history }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [geofenceSizes, setGeofenceSizes] = useState({ size: 0, circumference: 0 });
  const [submittable, setSubmittable] = useState(false);
  const { list: farms } = useSelector(state => state.farm);
  const [state, setState] = useState({
    editing: true,
    color: DEFAULT_SHAPE_COLOR,
    geofence: {
      farm_id: null,
      name: '',
      size: '',
      color: '',
      animals_count: '',
      description: '',
      circumference: '',
      days_grazed: '',
      address: '',
      geo_json: {},
      farms_org: [],
      dropdownOpen: false
    },
    farms: [],
    selected_farm: ''
  });

  useEffect(() => {
    //TODO: Get tag
    getFarms();
  }, []);

  useEffect(() => {
    form.setFieldsValue({ farm: state.selected_farm });
  }, [state.selected_farm]);

  useEffect(() => {
    if (farms) {
      const updatedFarms = farms.map(f => ({
        ...f,
        geofences: f.geofences,
        value: f.id,
        label: f.name,
        meta: {
          address: f.address.full_address,
          master_geofences: f.master_geofence
        }
      }));

      const farm = updatedFarms?.at(0);

      setState(prevState => ({
        ...prevState,
        farms: updatedFarms,
        farms_org: updatedFarms,
        selected_farm: farm,
        editing: true,
        geofence: {
          ...prevState.geofence,
          address: farm?.meta.address,
          circumference: farm?.circumference,
          color: farm?.master_geofence?.color,
          farm_id: farm?.value,
          features: farm?.geofences,
          size: farm?.size
        }
      }));
    }
  }, [farms]);

  const changeColor = useCallback(
    color => {
      setState(prevState => ({ ...prevState, color }));
    },
    [state.color]
  );

  function getFarms() {
    try {
      dispatch(fetchFarms({ with_details: true }));
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
    }
  }

  function onGeoJSONChange(value) {
    setState(prevState => ({
      ...prevState,
      geofence: {
        ...prevState.geofence,
        geo_json: value
      }
    }));
  }

  function setGeofenceGeoJson(geo_json) {
    setState(prevState => ({ ...prevState, geofence: { ...prevState.geofence, geo_json } }));
  }

  const multiselectChange = selectedFarmId => {
    let features = [];
    const farm = state.farms_org.find(f => f.value === +selectedFarmId);
    features = farm?.geofences;
    setState(prevState => ({
      ...prevState,
      selected_farm: farm,
      geofence: {
        ...prevState.geofence,
        farm_id: farm?.value,
        address: farm?.meta?.address,
        features: features
      },
      editing: true
    }));
  };

  function resetPage() {
    formRef?.current?.resetFields();

    setState({
      editing: true,
      color: DEFAULT_SHAPE_COLOR,
      geofence: {
        farm_id: null,
        name: '',
        size: '',
        color: '',
        animals_count: '',
        description: '',
        circumference: '',
        days_grazed: '',
        address: '',
        geo_json: {},
        farms_org: [],
        dropdownOpen: false
      },
      farms: [],
      selected_farm: ''
    });

    getFarms();
  }

  async function createNewGeofenceAndNavigate() {
    const boundary = state.selected_farm?.master_geofence?.geo_json;

    if (boundary && !turf.booleanContains(boundary, state.geofence?.geo_json)) {
      warnToastHandler(messages.SHAPE_OUTSIDE_BOUNDARIES);

      return;
    }

    await createNewGeofence();
    resetPage();
  }

  async function handleCreateNewGeofence() {
    const boundary = state.selected_farm?.master_geofence?.geo_json;

    if (boundary && !turf.booleanContains(boundary, state.geofence?.geo_json)) {
      warnToastHandler(messages.SHAPE_OUTSIDE_BOUNDARIES);

      return;
    }

    await createNewGeofence();
    navigateToGeofence();
  }

  async function createNewGeofence() {
    const geofenceNewValues = await formRef.current?.validateFields();
    const name = geofenceNewValues?.name;
    const description = geofenceNewValues?.description;

    try {
      await dispatch(
        createGeofence({ ...state.geofence, color: state.color, description, name, size: '', circumference: '' })
      ).unwrap();
      successToastHandler(messages.CREATED);
    } catch (error) {
      errorToastHandler(messages.FAILED_ON_CREATE_DATA('geofence'));
    }
  }

  const navigateToGeofence = () => {
    history.push('/maps?tab=geofence');
  };

  const handleShapeSizes = useCallback(
    sizes => {
      setGeofenceSizes(sizes);
    },
    [geofenceSizes]
  );

  const breadcrumbItems = [
    {
      title: <Link to="maps?tab=geofence">List of Geofences</Link>
    },
    {
      title: 'Create New Geofence'
    }
  ];

  return (
    <div className="geofence-new">
      <Flex gap="small" vertical={false} justify="space-between" wrap>
        <Flex vertical={true}>
          <Title level={4}>New Geofence</Title>

          <AppBreadcrumb items={breadcrumbItems} />
        </Flex>
        <Flex>
          {state.editing && (
            <CancelSaveButtons
              disabled={!submittable || (submittable && isEmpty(state.geofence?.geo_json))}
              handleCancel={navigateToGeofence}
              handleSave={handleCreateNewGeofence}
              handleSaveAndNavigate={createNewGeofenceAndNavigate}
            />
          )}
        </Flex>
      </Flex>

      <Card>
        <Form form={form} layout="inline">
          <Form.Item className="farm-form" label="Farm" name="farm" colon={false} style={{ width: '100%' }}>
            <Select
              name="farm"
              style={{ width: '100%' }}
              options={state.farms}
              value={state.selected_farm}
              onChange={multiselectChange}
            />
          </Form.Item>
        </Form>
      </Card>

      <MapGeofence
        color={state.color}
        editing={state.editing}
        geofence={state.geofence}
        isNew={true}
        type={TYPE.GEOFENCE}
        handleCancelEditing={navigateToGeofence}
        handleCreate={setGeofenceGeoJson}
        handleSaveEditing={handleCreateNewGeofence}
        handleShapeSizes={handleShapeSizes}
        onEdit={onGeoJSONChange}
      />

      <GeofenceEditForm
        circumference={geofenceSizes?.circumference}
        color={state.selected_farm?.master_geofence?.color}
        farmId={state.selected_farm?.name}
        formRef={formRef}
        size={geofenceSizes?.size}
        handleColorChange={changeColor}
        setSubmittable={setSubmittable}
      />

      <Flex justify="end" className="mb-2">
        <CancelSaveButtons
          disabled={!submittable || (submittable && isEmpty(state.geofence?.geo_json))}
          handleCancel={navigateToGeofence}
          handleSave={handleCreateNewGeofence}
          handleSaveAndNavigate={createNewGeofenceAndNavigate}
        />
      </Flex>
    </div>
  );
}

export default memo(GeofenceNew);
