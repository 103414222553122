import { memo } from 'react';
import { MapContainer } from 'react-leaflet';

import MapTrackingLayers from './MapTrackingLayers';
import { ZOOM } from './constants';
import './styles.scss';

function MapTracking({
  activeTab,
  animal,
  farm,
  farms,
  recordsWithGeoData,
  tracking,
  isFullScreen = false,
  onAnimalClick
}) {
  const bounds = [
    [-31.0, 13.0],
    [-31.0, 33.0]
  ];

  return (
    <MapContainer attributionControl={false} key={JSON.stringify(tracking)} bounds={bounds} className="map" zoom={ZOOM}>
      <MapTrackingLayers
        activeTab={activeTab}
        animal={animal}
        farm={farm}
        farms={farms}
        recordsWithGeoData={recordsWithGeoData}
        tracking={tracking}
        isFullScreen={isFullScreen}
        onAnimalClick={onAnimalClick}
      />
    </MapContainer>
  );
}

export default memo(MapTracking);
