import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from 'antd';
import moment from 'moment/moment';

import { capitalize } from '../../helpers/common';
import { sortDates, sortNumbers, sortStrings } from '../../helpers/filter';

import { DATE_FORMAT } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';

import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import Priority from '../../components/PriorityTag';

const { Title } = Typography;

const commonColumnProperties = {
  ellipsis: true,
  sortDirections: ['ascend', 'descend']
};

function LastNotifications({
  isNotificationsLoading,
  notificationPriorities,
  notificationTypes,
  notifications,
  clearNotification,
  snoozeNotification
}) {
  const notificationsColumns = useMemo(
    () => [
      {
        ...commonColumnProperties,
        title: 'Notification ID',
        dataIndex: 'id',
        width: COLUMN_SIZE.SM,
        render: (value, record) => <Link to={`notification/${record.id}`}>{record.identifier}</Link>,
        sorter: (a, b) => sortStrings(a.identifier, b.identifier)
      },
      {
        ...commonColumnProperties,
        title: 'Notification Priority',
        dataIndex: 'priority',
        filters: notificationPriorities,
        width: COLUMN_SIZE.MD,
        onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.priority : record?.priority === value),
        render: (value, record) => <Priority priority={record.priority} isClear={record.cleared_at}></Priority>,
        sorter: (a, b) => sortNumbers(a.priority_index, b.priority_index)
      },
      {
        ...commonColumnProperties,
        title: 'Type',
        dataIndex: 'type',
        filters: notificationTypes,
        width: COLUMN_SIZE.MD,
        onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.type : record?.type === value),
        render: value => capitalize(value),
        sorter: (a, b) => sortStrings(a.type, b.type)
      },
      {
        ...commonColumnProperties,
        title: 'Rule Name',
        dataIndex: 'ruleName',
        width: COLUMN_SIZE.XL,
        render: (value, record) => record?.rule?.name,
        sorter: (a, b) => sortStrings(a?.rule?.name, b?.rule?.name)
      },
      {
        ...commonColumnProperties,
        title: 'Date',
        dataIndex: 'created_at',
        width: COLUMN_SIZE.MD,
        render: (value, record) => moment(value).format(DATE_FORMAT.DATETIME),
        sorter: (a, b) => sortDates(a?.created_at, b?.created_at)
      },
      {
        title: 'Snooze',
        dataIndex: 'snooze',
        align: 'center',
        ellipsis: true,
        noPadding: true,
        width: COLUMN_SIZE.XS,
        render: (value, record) => (
          <i onClick={() => snoozeNotification(record.id)} className={'not-action mdi mdi-alarm ' + record.status}></i>
        )
      },
      {
        title: 'Clear',
        dataIndex: 'clear',
        align: 'center',
        ellipsis: true,
        noPadding: true,
        width: COLUMN_SIZE.XS,
        render: (value, record) => (
          <i
            onClick={() => clearNotification(record.id)}
            className={'not-action clear mdi mdi-check-circle-outline ' + !!record.cleared_at}></i>
        )
      }
    ],
    [notifications]
  );

  return (
    <>
      <div className="heading-with-icons">
        <Title level={4}>Last 10 Notifications</Title>
        <div className="close-full-btns">
          <i className="fa fa-window-maximize" aria-hidden="true"></i>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>

      <div className="mx-2">
        <AppTable
          headerClass="py-2"
          baseColumns={notificationsColumns}
          dataSource={notifications}
          loading={isNotificationsLoading}
          pageSize={10}
          rowClassName={record => (record.cleared_at ? 'row-secondary' : '')}
          rowsSelectable={false}
          searchable={false}
          settings={false}
        />
      </div>
    </>
  );
}

export default memo(LastNotifications);
