import { createSlice } from '@reduxjs/toolkit';

import { deleteLabels, fetchLabels } from '../actions/label';

const labelSlice = createSlice({
  name: 'label',
  initialState: {
    error: null,
    data: [],
    loading: false,
    options: [],
    selectedFilters: {}
  },
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      // Fetch Labels
      .addCase(fetchLabels.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.options = action.payload.options;
        state.filterOptions = action.payload.filterOptions;
      })
      .addCase(fetchLabels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete Labels
      .addCase(deleteLabels.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteLabels.fulfilled, (state, action) => {
        state.loading = false;
        state.list = state.list.filter(record => !action.payload?.includes(record.id));
      })
      .addCase(deleteLabels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setSelectedFilters } = labelSlice.actions;
export default labelSlice.reducer;
