import React, { Suspense, lazy, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Tabs, Typography } from 'antd';

import { hasPermission } from '../../helpers/user';

import { PERMISSIONS } from '../../constants/permissions';

import LoadingBar from '../../components/LoadingBar';

import AppBreadcrumb from '../ui-components/AppBreadcrumb';

const { Title } = Typography;

const GatewaysTable = lazy(() => import('../../components/Tables/GatewaysTable'));
const TagsTable = lazy(() => import('../../components/Tables/TagsTable'));

export default function Devices() {
  const { search } = useLocation();
  const user = useSelector(state => state.user);
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get('tab');

  const tabItems = useMemo(() => {
    const items = [];

    if (hasPermission([PERMISSIONS.TAGS.VIEW_OWN], user.permissions)) {
      items.push({
        key: 'tags',
        label: 'Tags',
        children: (
          <Suspense fallback={<LoadingBar />}>
            <TagsTable />
          </Suspense>
        )
      });
    }

    if (hasPermission([PERMISSIONS.GATEWAYS.VIEW_OWN], user.permissions)) {
      items.push({
        key: 'gateways',
        label: 'Gateways',
        children: (
          <Suspense fallback={<LoadingBar />}>
            <GatewaysTable />
          </Suspense>
        )
      });
    }

    return items;
  }, [user.permissions]);

  const breadcrumbItems = [
    {
      title: 'List of Kraal Tags and Gateways'
    }
  ];

  return (
    <div className="card p-2">
      <Title level={4}>Kraal Devices</Title>

      <AppBreadcrumb items={breadcrumbItems} />

      <Tabs defaultActiveKey={tab || tabItems[0]?.key} items={tabItems} />
    </div>
  );
}
