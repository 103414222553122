import { memo } from 'react';

import { Breadcrumb, ConfigProvider } from 'antd';

const AppBreadcrumb = ({ items }) => (
  <ConfigProvider
    theme={{
      components: {
        Breadcrumb: {
          lastItemColor: 'rgba(0, 0, 0, 0.45)',
          linkColor: '#51ae32',
          linkHoverColor: '#439029'
        }
      }
    }}>
    <Breadcrumb items={items} />
  </ConfigProvider>
);

export default memo(AppBreadcrumb);
