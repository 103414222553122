import { memo } from 'react';
import { Polygon } from 'react-leaflet';

import { GEOMETRY_TYPE } from '../../constants/map';

function Feature({ children, color, geometry, type, setAnimal }) {
  if (type === GEOMETRY_TYPE.POLYGON) {
    return (
      <Polygon
        pathOptions={{ color, fillColor: color }}
        fillOpacity={0.5}
        opacity={1}
        positions={geometry}
        onClick={() => setAnimal(type)}>
        {children}
      </Polygon>
    );
  }
}

export default memo(Feature);
