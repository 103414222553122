import { cloneDeep } from 'lodash';

import messages from '../../constants/messages';
import { REDUCERS } from '../../constants/reducers';

import { errorToastHandler, successToastHandler } from '../../components/action_notifier';

import { AuthService, UserService } from '../../services';

export const setUser = value => ({
  type: REDUCERS.USER.SET_USER,
  value
});

export const setBaseURL = value => ({
  type: REDUCERS.USER.SET_BASE_URL,
  value
});

export const setUsers = value => ({
  type: REDUCERS.USER.SET_USERS,
  value
});

export const setUserState = value => ({
  type: REDUCERS.USER.SET_USER_STATE,
  value
});

export const setUserTableSettings = value => ({
  type: REDUCERS.USER.SET_TABLE_SETTINGS,
  value
});

export const setUserTablePreferences = value => ({
  type: REDUCERS.USER.SET_TABLE_PREFERENCES,
  value
});

export const getUserReq = () => {
  return async dispatch => {
    try {
      const { data } = await UserService.getUser();
      const payload = {
        user: data,
        permissions: (data?.roles || []).flatMap(role => role.permissions.map(({ name }) => name)),
        isAdmin: !!data?.roles?.find(r => r.name === 'admin')
      };
      dispatch(setUserState(payload));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAllUsers = () => {
  return async dispatch => {
    try {
      const { data } = await UserService.getAllUsers();
      dispatch(setUsers(data));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUsers = params => {
  return async dispatch => {
    try {
      const { data } = await UserService.getUsers(params);
      dispatch(setUsers(data));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const createNewUser = payload => {
  return async () => {
    try {
      const { data } = await UserService.createNewUser(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const sendSetPassword = payload => {
  return async () => {
    try {
      const { data } = await AuthService.sendSetPassword(payload);

      if (data.failedEmails?.length) {
        errorToastHandler(messages.SET_PASSWORD_EMAIL_FAILED(data.failedEmails));
      } else {
        successToastHandler(messages.SET_PASSWORD_EMAIL_SENT);
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const sendContactUs = payload => {
  return async () => {
    try {
      const { data } = await UserService.contactUs(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const verifyToken = payload => {
  return async () => {
    try {
      const { data } = await AuthService.verifyToken(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const setPassword = payload => {
  return async () => {
    try {
      const { data } = await AuthService.setPassword(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const archiveUsers = payload => {
  return async () => {
    try {
      const { data } = await UserService.archiveUsers(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteUsers = payload => {
  return async () => {
    try {
      const { data } = await UserService.deleteUsers(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const subscribeUser = payload => {
  return async () => {
    try {
      const { data } = await UserService.subscribe(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateSubscription = payload => {
  return async () => {
    try {
      const { data } = await UserService.updateSubscription(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUserTablePreferences = () => {
  return async dispatch => {
    try {
      const { data } = await UserService.getTablePreferences();

      dispatch(setUserTablePreferences(data));

      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateUserTablePreferences = (tableId, columns) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await UserService.updateTablePreferences(tableId, columns);

      const stateTablePreferences = cloneDeep(getState().user.tablePreferences);
      const updatedTablePreferences = stateTablePreferences.find(({ id }) => id === tableId);
      updatedTablePreferences.columns.map(column => {
        const columnIndex = columns.findIndex(({ id }) => id === column.id);

        return Object.assign(column, { ...columns[columnIndex], order: columnIndex });
      });

      dispatch(setUserTablePreferences(stateTablePreferences));

      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const resetUserTablePreferences = tableId => {
  return async dispatch => {
    await UserService.resetTablePreferences(tableId);

    await dispatch(getUserTablePreferences());
  };
};
