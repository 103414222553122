import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { cloneDeep } from 'lodash';

import { updateTableColumnConfigurations } from '../../../../redux/actions/table-configurations';

const EditableTableColumnsModal = ({ tableId, onClose }) => {
  if (!tableId) {
    return null;
  }

  const dispatch = useDispatch();
  const configurations = useSelector(state => state.tableConfigurations.configurations);

  const initialColumns = configurations.find(({ id }) => id === tableId)?.columns || [];
  const [columnsState, setColumnsState] = useState(() => cloneDeep(initialColumns).sort((a, b) => a.order - b.order));

  const handleEdit = (index, key, value) => {
    setColumnsState(prevState => prevState.map((col, i) => (i === index ? { ...col, [key]: value } : col)));
  };

  const moveColumn = (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= columnsState.length) return;

    const updatedColumns = [...columnsState];

    [updatedColumns[index].order, updatedColumns[newIndex].order] = [
      updatedColumns[newIndex].order,
      updatedColumns[index].order
    ];

    updatedColumns.sort((a, b) => a.order - b.order);
    setColumnsState(updatedColumns);
  };

  const handleSave = () => {
    dispatch(
      updateTableColumnConfigurations(
        tableId,
        columnsState.map(({ id, title, description, is_visible }) => ({
          id,
          title,
          description,
          is_visible: !!is_visible
        }))
      )
    );
    onClose();
  };

  return (
    <Modal title="Edit Columns" open={true} onCancel={onClose} onOk={handleSave} width={600}>
      <div>
        {columnsState.map((col, index) => {
          return (
            <div key={col.id} className="mb-3 p-2 border border-gray-300 rounded flex items-center justify-between">
              <div>
                <Input
                  maxLength={255}
                  className="mb-2"
                  value={col.title}
                  onChange={e => handleEdit(index, 'title', e.target.value)}
                />
                <TextArea
                  maxLength={1024}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  className="mb-2"
                  value={col.description}
                  onChange={e => handleEdit(index, 'description', e.target.value)}
                />
                <Checkbox
                  className="mb-2"
                  checked={col.is_visible}
                  onChange={e => handleEdit(index, 'is_visible', e.target.checked)}>
                  Visible
                </Checkbox>
                <div>
                  <span className="mr-2">Order</span>
                  <Button
                    size="small"
                    className="mr-2"
                    icon={<ArrowUpOutlined />}
                    disabled={index === 0}
                    onClick={() => moveColumn(index, -1)}
                  />
                  <Button
                    size="small"
                    icon={<ArrowDownOutlined />}
                    disabled={index === columnsState.length - 1}
                    onClick={() => moveColumn(index, 1)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default EditableTableColumnsModal;
