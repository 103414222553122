import { TABLE_COLUMNS } from '../configs/subscriptions';

export const getTableColumns = (columns, planType, featureName) => {
  return TABLE_COLUMNS[featureName]?.[planType]?.length
    ? columns?.filter(column => TABLE_COLUMNS[featureName]?.[planType]?.includes(column.id))
    : columns;
};

export const isLivestockFieldAvailable = (fieldId, planType, featureName) => {
  if (TABLE_COLUMNS[featureName]?.[planType]?.length) {
    return TABLE_COLUMNS[featureName]?.[planType].includes(fieldId);
  }
  return true;
};

export const isFieldAvailable = (fieldId, planType, featureName) => {
  return TABLE_COLUMNS[featureName]?.[planType]?.length
    ? TABLE_COLUMNS[featureName]?.[planType].includes(fieldId)
    : true;
};
