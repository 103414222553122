import { memo } from 'react';
import { Card, CardBody, CardTitle, Form, FormGroup, Input, Label } from 'reactstrap';

import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

function ProfileCard({ avatarUrl, editMode, file, user, onEditAnimalFileChange }) {
  return (
    <Card>
      <CardBody>
        <div className="text-center mt-4">
          <Avatar
            size={{ xs: 150, sm: 200, md: 200, lg: 130, xl: 150, xxl: 200 }}
            icon={<UserOutlined />}
            src={file || avatarUrl}
            crossOrigin="anonymous"
          />
          <CardTitle className="mt-2">{user.first_name + ' ' + user.last_name}</CardTitle>
          {editMode && (
            <Form>
              <FormGroup>
                <Label className="float-left">Avatar</Label>
                <Input type="file" onChange={onEditAnimalFileChange} accept=".jpg, .jpeg, .png" />
              </FormGroup>
            </Form>
          )}
        </div>
      </CardBody>
      <CardBody className="border-top">
        <div>
          <small className="text-muted">Email address </small>
          <h6>{user.email}</h6>
          <small className="text-muted">Backup Email address </small>
          <h6>{user.backup_email}</h6>
          <small className="text-muted pt-4 db">Work Phone</small>
          <h6>{user.work_phone}</h6>
          <small className="text-muted pt-4 db">Mobile Phone</small>
          <h6>{user.private_phone}</h6>
          <small className="text-muted pt-4 db">Address</small>
          <h6>{user.full_address}</h6>
        </div>
      </CardBody>
    </Card>
  );
}

export default memo(ProfileCard);
