export function reverseCoordinates(coordinates) {
  return Array.isArray(coordinates) && coordinates.every(item => Array.isArray(item))
    ? coordinates.map(c => [c?.at(1), c?.at(0)])
    : coordinates;
}

export function getRandomColor() {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;
}
