import axios from '../../axios';

const path = 'sites';

const service = {
  async createSite(data) {
    return axios({ method: 'post', url: path, data });
  },

  async fetchSites(params) {
    return axios({ method: 'get', url: path, params });
  },

  async updateSite(id, data) {
    return axios({ method: 'put', url: `${path}/${id}`, data });
  },

  async deleteSites(ids) {
    return axios({ method: 'delete', url: `${path}/bulk`, data: { ids } });
  }
};

export default service;
