import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import moment from 'moment';

import { capitalize } from '../../helpers/common';
import { getFilterOptions, sortDates, sortNumbers, sortStrings } from '../../helpers/filter';
import { hasPermission } from '../../helpers/user';

import { DATE_FORMAT, KEY_PRESS_CODE } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';
import { PERMISSIONS } from '../../constants/permissions';
import { RULE_PRIORITY_INDEX } from '../../constants/rule';
import { TABLE_CONFIG_COLUMNS, TABLE_CONFIG_KEYS } from '../../constants/table-configurations';
import { TASK_MODAL_MODE } from '../../constants/task';

import { getAllTasks, setCurrentTask, setTaskQueryParams } from '../../redux/actions/task';
import { setSelectedFilters } from '../../redux/actions/task';

import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';

import TaskModal from '../Modals/TaskModal';
import './styles.scss';

const commonColumnProperties = {
  ellipsis: true,
  searchable: true,
  sortDirections: ['ascend', 'descend']
};

const Tasks = ({
  isActions = true,
  queryParameters = {},
  rowsSelectable = false,
  searchPlaceholder = '',
  searchable = false,
  settings = false,
  tableMaxHeight,
  title = ''
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { tasks, selectedFilters, loading } = useSelector(state => state.task);
  const [actions, setActions] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [isViewModal, setOpenViewModal] = useState(false);
  const [isCreateModal, setOpenCreateModal] = useState(false);
  const [priorityFilterOptions, setPriorityFilterOptions] = useState([]);
  const [query, setSearchQuery] = useState('');
  const [statusFilterOptions, setStatusFilterOptions] = useState([]);

  useEffect(() => {
    dispatch(getAllTasks(queryParameters));
    dispatch(setTaskQueryParams(queryParameters));

    if (isActions) {
      initActions();
    }
  }, []);

  useEffect(() => {
    const priorityFilterOptions = getFilterOptions(tasks, 'priority');
    const statusFilterOptions = getFilterOptions(tasks, 'status');

    setPriorityFilterOptions(priorityFilterOptions);
    setStatusFilterOptions(statusFilterOptions);
  }, [tasks]);

  useEffect(() => {
    setFilteredInfo(selectedFilters);

    if (isActions) {
      initActions();
    }
  }, [selectedFilters]);

  const setFilters = (pagination, filters) => {
    setFilteredInfo(filters);
    dispatch(setSelectedFilters(filters));
  };

  const onSubmit = () => {
    setOpenCreateModal(false);
  };

  const onTaskClick = task => {
    dispatch(setCurrentTask(task));
    setOpenViewModal(true);
  };

  function initActions() {
    const availableActions = [];

    if (hasPermission([PERMISSIONS.TASKS.CREATE], user.permissions)) {
      availableActions.push({
        label: (
          <Button color="default" size="small" variant="link" onClick={onAddTaskClick}>
            Add task
          </Button>
        )
      });
    }

    availableActions.push({
      label: (
        <Button
          color="default"
          size="small"
          variant="link"
          disabled={!Object.values(selectedFilters)?.length}
          onClick={clearFilters}>
          Clear all filters
        </Button>
      )
    });

    setActions(availableActions);
  }

  const clearFilters = () => {
    dispatch(setSelectedFilters({}));
  };

  const onAddTaskClick = () => {
    setOpenCreateModal(true);
  };

  function onChange(value) {
    setSearchQuery(prevState => ({ ...prevState, query: value }));
  }

  async function handleKeyPress(charCode) {
    const params = {
      ...queryParameters,
      ...query
    };

    if (charCode === 13) dispatch(getAllTasks(params));
  }

  const columns = [
    {
      ...commonColumnProperties,
      title: 'Title',
      dataIndex: 'title',
      configColumnKey: TABLE_CONFIG_COLUMNS.TITLE,
      filteredValue: filteredInfo.title || null,
      width: COLUMN_SIZE.XL,
      render: (value, record) => (
        <span className="title-column" onClick={() => onTaskClick(record)}>
          {record?.title}
        </span>
      ),
      sorter: (a, b) => sortStrings(a.title, b.title)
    },
    {
      ...commonColumnProperties,
      title: 'Due date',
      dataIndex: 'due_date',
      configColumnKey: TABLE_CONFIG_COLUMNS.DUE_DATE,
      filteredValue: filteredInfo.due_date || null,
      width: COLUMN_SIZE.LG,
      render: (value, record) => moment(record.due_date).format(DATE_FORMAT.DATETIME),
      sorter: (a, b) => sortDates(a.due_date, b.due_date)
    },
    {
      ...commonColumnProperties,
      title: 'Priority',
      dataIndex: 'priority',
      configColumnKey: TABLE_CONFIG_COLUMNS.PRIORITY,
      filteredValue: filteredInfo.priority || null,
      filters: priorityFilterOptions,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.priority : record?.priority === value),
      render: (value, record) => capitalize(record?.priority),
      sorter: (a, b) => sortNumbers(RULE_PRIORITY_INDEX[a.priority], RULE_PRIORITY_INDEX[b.priority])
    },
    {
      ...commonColumnProperties,
      title: 'Status',
      dataIndex: 'status',
      configColumnKey: TABLE_CONFIG_COLUMNS.STATUS,
      filteredValue: filteredInfo.status || null,
      filters: statusFilterOptions,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.status : record?.status === value),
      render: (value, record) => capitalize(record.status),
      sorter: (a, b) => sortStrings(a.status, b.status)
    }
  ];

  return (
    <>
      <AppTable
        actions={isActions ? actions : null}
        baseColumns={columns}
        dataSource={tasks}
        tableConfigKey={TABLE_CONFIG_KEYS.TASKS}
        headerClass="py-2"
        loading={loading}
        pageSize={queryParameters?.limit}
        rowsSelectable={rowsSelectable}
        searchPlaceholder={searchPlaceholder}
        searchable={searchable}
        settings={settings}
        tableMaxHeight={tableMaxHeight}
        title={title}
        handleOnChange={setFilters}
        handleSearchChange={e => onChange(e.target.value)}
        handleSearchKeyPress={() => handleKeyPress(KEY_PRESS_CODE)}
      />

      <TaskModal
        isOpen={isViewModal}
        mode={TASK_MODAL_MODE.VIEW}
        onCancel={() => setOpenViewModal(false)}
        onSubmit={onSubmit}
      />

      <TaskModal
        isOpen={isCreateModal}
        mode={TASK_MODAL_MODE.CREATE}
        onCancel={() => setOpenCreateModal(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default memo(Tasks);
