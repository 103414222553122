import { createContext, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CaretDownOutlined, CaretUpOutlined, FilterFilled, SearchOutlined } from '@ant-design/icons';
import { DndContext, DragOverlay, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { ConfigProvider, DatePicker, Table } from 'antd';
import { createStyles } from 'antd-style';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment/moment';

import { getTablePreferences } from '../../helpers/table-configurations';

import { DATE_FORMAT, DEFAULT_PAGE_SIZE, EDIT_COMPONENT_TYPE } from '../../constants/common';

import { resetUserTablePreferences, updateUserTablePreferences } from '../../redux/actions/user';

import { FONT_SIZE, TABLE_SIZE } from '../../components/AppTable/constants';

import AppTableControls from './AppTableControls';
import AppTableFilterPopup from './AppTableFilterPopup';
import AppTableSearchPopup from './AppTableSearchPopup';
import AppTableSelect from './AppTableSelect';
import EditableRow from './EditableRow';
import TableBodyCell from './TableBodyCell';
import TableHeaderCell from './TableHeaderCell';
import './styles.scss';

dayjs.extend(customParseFormat);

const getSortIconStyles = sortOrder => ({ color: sortOrder ? '#000' : undefined, fontSize: '16px', cursor: 'pointer' });

const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: #eaeaea transparent;
            scrollbar-gutter: stable;
          }
        }
      }
    `
  };
});

export const DragIndexContext = createContext({ active: -1, over: -1 });
export const EditableContext = createContext(null);

const AppTable = ({
  actions,
  baseColumns,
  breadcrumb,
  dataSource,
  expandable,
  filterable,
  headerClass,
  loading,
  pageSize = DEFAULT_PAGE_SIZE,
  rowClassName,
  rowsSelectable = true,
  searchPlaceholder,
  searchable,
  selectedRows,
  settings,
  tableConfigKey,
  tableMaxHeight = '65vh',
  title,
  totalCount = 0,
  handleFilters,
  handleOnChange,
  handleOnSelectRecords,
  handleSearchChange,
  handleSearchKeyPress,
  updateTableData
}) => {
  const dispatch = useDispatch();

  const { tableSettings, tablePreferences } = useSelector(state => state.user);

  const [dragIndex, setDragIndex] = useState({ active: -1, over: -1 });
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const getColumnSearchProps = ({ dataIndex, title, onFilter }) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <AppTableSearchPopup
        selectedKeys={selectedKeys}
        title={title}
        handleOnChange={event => {
          const value = event.target.value;
          setSelectedKeys(value ? [value] : []);
          handleSearch([value], confirm, dataIndex);
        }}
        handleReset={() => {
          setSelectedKeys([]);
          handleSearch([], confirm, dataIndex);
          close();
        }}
        handleConfirm={close}
      />
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#000000' : undefined, fontSize: '14px' }} />,
    onFilter: onFilter
      ? onFilter
      : (value, record) => {
          if (dataIndex && record[dataIndex]) {
            return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
          }
        }
    // render: text =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   )
  });
  const tableColumnPreferences = useMemo(
    () => getTablePreferences(tableConfigKey, tablePreferences),
    [tablePreferences, tableConfigKey]
  );

  const getColumnFilterProps = ({ dataIndex, filters, title, onFilter }) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <AppTableFilterPopup
        dataIndex={dataIndex}
        filters={filters}
        selectedKeys={selectedKeys}
        title={title}
        handleOnChange={value => {
          setSelectedKeys(value);
          confirm(false);
        }}
        handleReset={() => {
          setSelectedKeys([]);
          confirm();
          close();
        }}
        handleConfirm={() => close()}
      />
    ),
    filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#000000' : undefined, fontSize: '12px' }} />,
    onFilter: onFilter
      ? onFilter
      : (value, record) => {
          if (dataIndex && record[dataIndex]) {
            return record[dataIndex].toString().toLowerCase() === value.toString().toLowerCase();
          }
        }
  });

  function handleChange(updatedRecord) {
    updateTableData([updatedRecord]);
  }

  const renderColumns = useCallback(() => {
    const columns = [];

    baseColumns?.forEach((column, i) => {
      const columnPreferences = tableColumnPreferences?.columns.find(({ key }) => key === column.configColumnKey);
      if (tableColumnPreferences?.columns && !columnPreferences) {
        return;
      }

      const col = {
        ...column,
        ...(column.searchable ? getColumnSearchProps(column) : {}),
        ...(column.filters ? getColumnFilterProps(column) : {}),
        title: columnPreferences?.title || column.title,
        key: column.dataIndex,
        id: i,
        order: columnPreferences?.order,
        columnReferenceId: columnPreferences?.id,
        hidden: columnPreferences ? !columnPreferences?.is_visible : undefined,
        onHeaderCell: () => ({
          id: i,
          style: {
            width: `${column.width}px`
          }
        }),
        onCell: record => {
          const style = { width: `${column.width}px` };

          if (column.noPadding === true) style.padding = 0;

          return { record, id: i, style };
        },
        sortIcon: ({ sortOrder }) =>
          sortOrder === 'ascend' ? (
            <CaretUpOutlined style={getSortIconStyles(sortOrder)} />
          ) : (
            <CaretDownOutlined style={getSortIconStyles(sortOrder)} />
          )
      };

      if (col.editConfig?.type === EDIT_COMPONENT_TYPE.INPUT) {
        col.onCell = record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: record[col.dataIndex],
          rules: col.rules,
          style: {
            width: `${column.width}px`
          },
          handleSave
        });
      }

      if (col.editConfig?.type === EDIT_COMPONENT_TYPE.SELECT) {
        const ogRender = col.render;

        col.render = (value, record) => {
          const disabled = col.editConfig?.disabledToEdit?.(record) ?? false;

          return (
            <AppTableSelect
              col={col}
              value={ogRender ? ogRender(value, record) : value}
              record={record}
              handleChange={handleChange}
              disabled={disabled}
            />
          );
        };
      }

      if (col.editConfig?.type === EDIT_COMPONENT_TYPE.DATE_PICKER) {
        col.render = (value, record) => {
          const disabled = col.editConfig?.disabledToEdit?.(record) ?? false;
          const dateFormat = DATE_FORMAT.DATE;
          const selected = moment(value).format(dateFormat);
          const dateValue = value ? dayjs(selected, dateFormat) : null;
          let minDate =
            col.editConfig.minDate && record[col.editConfig.minDate]
              ? dayjs(record[col.editConfig.minDate], DATE_FORMAT.ISO_8601)
              : null;
          const handleOnChange = newValue => {
            minDate =
              col.editConfig.minDate && record[col.editConfig.minDate]
                ? dayjs(record[col.editConfig.minDate], DATE_FORMAT.ISO_8601)
                : null;
            handleChange({ ...record, [col.dataIndex]: newValue });
          };

          return (
            <DatePicker
              size="small"
              variant="borderless"
              allowClear={!!col.editConfig?.allowClear}
              defaultValue={dateValue}
              disabled={disabled}
              disabledDate={col.editConfig.disabledDate}
              format={dateFormat}
              minDate={minDate}
              style={{ width: '100%' }}
              onChange={handleOnChange}
            />
          );
        };
      }

      columns.push(col);
    });

    return columns.sort((a, b) => a.order - b.order);
  }, [dataSource, baseColumns, tableColumnPreferences]);

  const [columns, setColumns] = useState(renderColumns());

  useEffect(() => {
    const updatedColumns = renderColumns();

    if (updatedColumns?.length) {
      setColumns(updatedColumns);
    }
  }, [dataSource, baseColumns, tableColumnPreferences]);

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
    handleOnSelectRecords(newSelectedRowKeys);
  };

  const rowSelection = {
    columnWidth: '40px',
    fixed: true,
    selectedRowKeys: selectedRows || selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      {
        key: 'all-page',
        text: 'Select all data on this page',
        onSelect: changeableRowKeys => {
          setSelectedRowKeys(changeableRowKeys);
          onSelectChange(changeableRowKeys);
        }
      },
      {
        key: 'all',
        text: 'Select all data',
        onSelect: () => {
          const data = [...dataSource];
          const newSelectedRowKeys = data.map(item => item.id);

          setSelectedRowKeys(newSelectedRowKeys);
          onSelectChange(newSelectedRowKeys);
        }
      },
      Table.SELECTION_NONE
    ]
  };

  const [size, setSize] = useState(tableSettings.size);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1
      }
    })
  );
  const { styles } = useStyle();

  const handleSearch = (value, confirm, dataIndex) => {
    confirm(false);
    setSearchText(value);
    setSearchedColumn(dataIndex);
  };

  const handleSave = updatedRecord => {
    updateTableData([updatedRecord]);
  };

  const composeColumnsToSave = updatedColumns =>
    updatedColumns.map(({ columnReferenceId, hidden }) => ({ id: columnReferenceId, is_visible: !hidden }));

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setColumns(prevState => {
        const activeIndex = prevState.findIndex(i => i.id === active?.id);
        const overIndex = prevState.findIndex(i => i.id === over?.id);

        const movedColumns = arrayMove(prevState, activeIndex, overIndex);

        if (tableColumnPreferences?.id) {
          dispatch(updateUserTablePreferences(tableColumnPreferences.id, composeColumnsToSave(movedColumns)));
        }

        return movedColumns;
      });
    }

    setDragIndex({ active: -1, over: -1 });
  };

  const onDragOver = ({ active, over }) => {
    const activeIndex = columns.findIndex(i => i.id === active.id);
    const overIndex = columns.findIndex(i => i.id === over?.id);

    setDragIndex({
      active: active.id,
      over: over?.id,
      direction: overIndex > activeIndex ? 'right' : 'left'
    });
  };

  function handleCheckedList(checkedList) {
    const newColumns = columns.map(item => ({
      ...item,
      hidden: !checkedList.includes(item.key)
    }));

    if (tableColumnPreferences) {
      dispatch(updateUserTablePreferences(tableColumnPreferences.id, composeColumnsToSave(newColumns)));
    }

    setColumns(newColumns);
  }

  const handleResetSettings = async () => {
    if (!tableColumnPreferences?.id) {
      return;
    }

    dispatch(resetUserTablePreferences(tableColumnPreferences?.id));
  };

  return (
    <>
      <AppTableControls
        actions={actions}
        breadcrumb={tableColumnPreferences ? tableColumnPreferences.description : breadcrumb}
        headerClass={headerClass}
        columns={columns}
        filterable={filterable}
        loading={loading}
        searchPlaceholder={searchPlaceholder}
        searchable={searchable}
        settings={settings}
        title={title}
        tableId={tableColumnPreferences?.id}
        handleTableSize={size => setSize(size)}
        handleCheckedList={handleCheckedList}
        handleFilters={handleFilters}
        handleSearchChange={handleSearchChange}
        handleSearchKeyPress={handleSearchKeyPress}
        handleResetSettings={handleResetSettings}
      />

      <DndContext
        sensors={sensors}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
        collisionDetection={closestCenter}>
        <DragIndexContext.Provider value={dragIndex}>
          <SortableContext items={columns.map(i => i.id)} strategy={horizontalListSortingStrategy}>
            <ConfigProvider
              theme={{
                token: {
                  fontSize: size === TABLE_SIZE.SM ? FONT_SIZE.SM : size === TABLE_SIZE.LG ? FONT_SIZE.LG : FONT_SIZE.MD
                },
                components: {
                  Select: {
                    optionFontSize:
                      size === TABLE_SIZE.SM ? FONT_SIZE.SM : size === TABLE_SIZE.LG ? FONT_SIZE.LG : FONT_SIZE.MD
                  }
                }
              }}>
              <Table
                expandable={expandable}
                rowSelection={rowsSelectable ? rowSelection : null}
                selections={false}
                columns={columns}
                dataSource={dataSource}
                components={{
                  header: { cell: TableHeaderCell },
                  body: { cell: TableBodyCell, row: EditableRow }
                }}
                rowKey="id"
                rowClassName={rowClassName}
                bordered
                loading={loading}
                className={`app-table ${styles.customTable}`}
                size={size}
                scroll={{ x: 200, y: tableMaxHeight, scrollToFirstRowOnChange: true }}
                showSorterTooltip={{ target: 'sorter-icon' }}
                pagination={{
                  defaultPageSize: pageSize,
                  total: totalCount,
                  showTotal: (total, range) => `${range[1]} of ${total}`
                }}
                onChange={handleOnChange}
              />
            </ConfigProvider>
          </SortableContext>
        </DragIndexContext.Provider>

        <DragOverlay>
          <th style={{ backgroundColor: 'gray', padding: 16 }}>
            {columns[columns.findIndex(i => i.id === dragIndex.active)]?.title}
          </th>
        </DragOverlay>
      </DndContext>
    </>
  );
};

export default memo(AppTable);
