import axios from '../../axios';

const service = {
  async fetchLabels(params) {
    return axios({
      method: 'get',
      url: 'labels',
      params
    });
  },

  async deleteLabel(ids) {
    return axios({
      method: 'delete',
      url: '/labels/balk',
      data: { ids }
    });
  }
};

export default service;
