import { createAsyncThunk } from '@reduxjs/toolkit';

import { formatArrayToFilterOptions, formatArrayToOptions } from '../../helpers/common';

import { LabelService } from '../../services';

export const fetchLabels = createAsyncThunk('label/fetchLabels', async (params, { rejectWithValue }) => {
  try {
    const { data } = await LabelService.fetchLabels(params);
    const payload = {
      data,
      options: formatArrayToOptions(data, 'name', 'id'),
      filterOptions: formatArrayToFilterOptions(data, 'name', 'id')
    };
    return payload;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch labels');
  }
});

export const deleteLabels = createAsyncThunk('label/deleteLabels', async (ids, { rejectWithValue }) => {
  try {
    await LabelService.deleteLabels(ids);

    return ids;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to delete labels');
  }
});
