import { memo } from 'react';
import { LayersControl } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import { GOOGLE_LAYER } from './constants';

const { BaseLayer } = LayersControl;

const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

function MapBaseLayers() {
  return (
    <>
      <BaseLayer checked name="Google Maps Satellite">
        <ReactLeafletGoogleLayer apiKey={key} type={GOOGLE_LAYER.HYBRID} />
      </BaseLayer>

      <BaseLayer name="Google Maps Terrain">
        <ReactLeafletGoogleLayer apiKey={key} type={GOOGLE_LAYER.TERRAIN} />
      </BaseLayer>
    </>
  );
}

export default memo(MapBaseLayers);
