import { memo, useContext, useEffect, useRef, useState } from 'react';

import { Form, Input } from 'antd';

import { DragIndexContext, EditableContext } from './index';

const dragActiveStyle = (dragState, id) => {
  const { active, over, direction } = dragState;
  let style = {};

  if (active && active === id) {
    style = { backgroundColor: 'gray', opacity: 0.5 };
  } else if (over && id === over && active !== over) {
    style =
      direction === 'right'
        ? {
            borderRight: '1px dashed gray'
          }
        : {
            borderLeft: '1px dashed gray'
          };
  }

  return style;
};

function TableBodyCell({ editable, children, dataIndex, record, rules, width, handleSave, ...props }) {
  const dragState = useContext(DragIndexContext);
  const form = useContext(EditableContext);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    setEditing(prev => !prev);
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      if (record[dataIndex] === values[dataIndex]) return;

      handleSave({ ...record, ...values });
      toggleEdit();
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex} rules={rules}>
        <Input size="small" ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable_cell"
        style={{ paddingInlineEnd: 24, textOverflow: 'ellipsis', overflow: 'hidden', width }}
        onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return (
    <td {...props} style={{ ...props.style, ...dragActiveStyle(dragState, props.id) }}>
      {childNode}
    </td>
  );
}

export default memo(TableBodyCell);
