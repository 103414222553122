import { memo } from 'react';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider/lib/Slider';

import { SLIDER, SLIDER_DEFAULT_TIME_FRAME, SLIDER_DEFAULT_TIME_INTERVAL } from './constants';

function TimeSliders({ sliderHandler }) {
  const timeFrameSlots = {};
  const timeIntervalSlots = {};

  for (const i in SLIDER.timeFrameSlots) {
    const index = SLIDER.timeFrameSlots[i].index;
    timeFrameSlots[index] = SLIDER.timeFrameSlots[i].label;
  }

  for (const i in SLIDER.timeIntervalSlots) {
    const index = SLIDER.timeIntervalSlots[i].index;
    timeIntervalSlots[index] = SLIDER.timeIntervalSlots[i].label;
  }

  return (
    <>
      <div className="time-slider card px-2 py-4 mb-1">
        <div className="inner">
          <p className="label">Time Frame</p>
          <Slider
            step={null}
            marks={timeFrameSlots}
            onChangeComplete={e => sliderHandler(e, 'time_frame')}
            defaultValue={SLIDER_DEFAULT_TIME_FRAME}
          />
        </div>

        <div className="inner">
          <p className="label">Time Interval</p>
          <Slider
            step={null}
            marks={timeIntervalSlots}
            onChangeComplete={e => sliderHandler(e, 'time_interval')}
            defaultValue={SLIDER_DEFAULT_TIME_INTERVAL}
          />
        </div>
      </div>
    </>
  );
}

export default memo(TimeSliders);
