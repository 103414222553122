import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Table } from 'reactstrap';

import { Button, Flex, Typography } from 'antd';
import moment from 'moment';

import axios from '../../axios';

import { capitalize } from '../../helpers/common';

import { DATE_FORMAT } from '../../constants/common';

import { setNotificationList } from '../../redux/actions/notification';

import AppTag from '../../components/AppTag';
import PrevNextButtons from '../../components/PrevNextButtons';
import Priority from '../../components/PriorityTag';

import AppBreadcrumb from '../ui-components/AppBreadcrumb';
import AppDropdown from '../ui-components/AppDropdown';

const { Title } = Typography;

class NotificationPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      actions: [],
      activeTab: '1',
      add_modal: false,
      delete_modal: false,
      livestock_opts: [],
      new_label: {
        color: '',
        livestock: [],
        name: '',
        rules: []
      },
      notificationStatus: {
        snoozable: 'New',
        snoozed: 'Snoozed',
        unsnoozable: 'Cleared'
      },
      notification: {
        date: '',
        description: '',
        gateways: [],
        id: null,
        name: '',
        priority: '',
        rule: {},
        tags: [],
        type: ''
      },
      query: '',
      rules_opts: [],
      selected_labels: [],
      selected_livestock: [],
      selected_rules: []
    };

    this.getLivestock = this.getLivestock.bind(this);
    this.getNotification = this.getNotification.bind(this);
    this.getRules = this.getRules.bind(this);
    this.initActions = this.initActions.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    await this.getLivestock();
    await this.getNotification();

    let that = this;
    this.setState({
      ...that.state,
      notification: {
        ...this.state.notification,
        age: this.ageformat(that.state.notification.created_at)
      }
    });
    setTimeout(function () {
      that.setState({
        ...that.state,
        notification: {
          ...that.state.notification,
          age: that.ageformat(that.state.notification.created_at)
        }
      });
    }, 60000);

    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: (
            <Button color="default" size="small" variant="link" onClick={() => this.clearNotifications()}>
              Clear notifications
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={this.state.notificationStatus[this.state.notification.status] === 'Cleared'}
              onClick={() => this.snoozeNotification()}>
              Snooze notification
            </Button>
          )
        }
      ]
    });
  }

  onChange = (value, field) => {
    this.setState(state => ({ ...state, [field]: value }));
  };

  ageformat(created_at) {
    if (created_at) {
      const b = moment(created_at);
      const a = moment();
      const years = a.diff(b, 'year');
      b.add(years, 'years');
      const months = a.diff(b, 'months');
      b.add(months, 'months');
      const days = a.diff(b, 'days');
      b.add(days, 'days');
      const hours = a.diff(b, 'hours');
      b.add(hours, 'hours');
      const mins = a.diff(b, 'minutes');

      return years + 'y ' + months + 'm ' + days + 'd ' + hours + 'h ' + mins + 'm';
    } else {
      return '';
    }
  }

  async getNotification() {
    const response = await axios.get('notifications/' + this.props.match.params.id, {
      query: this.state.query
    });

    if (response.status === 200) {
      await new Promise(resolve => this.setState({ notification: response.data }, resolve));
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  toggleModal(modal) {
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  async getRules() {
    const response = await axios.get('rules');

    this.setState({ rules_opts: response.data });
  }

  async getLivestock() {
    const response = await axios.get('animals');

    this.setState({ livestock_opts: response.data });
  }

  async snoozeNotification() {
    await axios.put('notifications/' + this.props.match.params.id + '/snooze');
    await this.getNotification();
  }

  async clearNotification() {
    const id = this.props.match.params.id;
    const response = await axios.put('notifications/' + id + '/clear');

    if (response.status === 200) {
      const updatedNotificationList = this.props.notification.list.filter(notif => notif.id !== id);

      this.props.setNotificationList(updatedNotificationList);
    }

    await this.getNotification();
  }

  render() {
    const breadcrumbItems = [
      {
        title: <Link to={`/notifications`}>List of Notifications</Link>
      },
      {
        title: `Notification ${this.state.notification.identifier}`
      }
    ];

    return (
      <>
        <Flex gap="small" vertical={false} justify="space-between" wrap>
          <Flex vertical={true}>
            <Title level={4}>Notification {this.state.notification.identifier}</Title>

            <AppBreadcrumb items={breadcrumbItems} />
          </Flex>
          <Flex gap="small">
            <AppDropdown label="Actions" items={this.state.actions} />

            <PrevNextButtons
              nextId={this.state.notification.next_id}
              prevId={this.state.notification.prev_id}
              handlePrev={() => this.props.history.push(`/notification/${this.state.notification.prev_id}`)}
              handleNext={() => this.props.history.push(`/notification/${this.state.notification.next_id}`)}
            />
          </Flex>
        </Flex>

        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row className="pad-15 global-heading-tag">
                <Col xs="12" md="12" lg="6">
                  <h5 className="details-title">Notification {this.state.notification.identifier} details</h5>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Notification ID</b>
                        </td>
                        <td>{this.state.notification.identifier}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Notification Message</b>
                        </td>
                        <td>{this.state.notification.message}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Notification Priority</b>
                        </td>
                        <td>
                          <Priority priority={this.state.notification.priority} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Notification Status</b>
                        </td>
                        <td>
                          <AppTag style={{ background: 'gray' }}>{capitalize(this.state.notification.status)}</AppTag>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Notification Type</b>
                        </td>
                        <td className="capitalize">{this.state.notification.type}</td>
                      </tr>

                      <tr>
                        <td>
                          <b>Notification Trigger Time</b>
                        </td>
                        <td>{moment(this.state.notification.created_at).format(DATE_FORMAT.DATETIME)}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Notification Age</b>
                        </td>
                        <td>{this.state.notification.age}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>User Actions</b>
                        </td>
                        <td>
                          <>
                            Snoozed on : &nbsp;
                            {this.state.notification.snooze_history &&
                              this.state.notification.snooze_history
                                .map(x => moment(x.created_at).format(DATE_FORMAT.DATETIME))
                                .join(', ')}
                            <br></br>
                            Cleared on : &nbsp;
                            {this.state.notification.clear_history &&
                              this.state.notification.clear_history
                                .map(x => moment(x.created_at).format(DATE_FORMAT.DATETIME))
                                .join(', ')}
                          </>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule ID</b>
                        </td>
                        <td>
                          {this.state.notification.rule && (
                            <Link to={`/rule/${this.state.notification.rule.id}`}>
                              {this.state.notification.rule.identifier}
                            </Link>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Livestock Count</b>
                        </td>
                        <td>
                          <div>{this.state.notification?.livestocks?.length ?? 0}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Kraal Tag ID</b>
                        </td>
                        <td>
                          {this.state.notification.tags &&
                            this.state.notification.tags.length > 0 &&
                            this.state.notification.type !== 'tag' &&
                            this.state.notification.tags.map(x => x.diagri_id).join(', ')}
                          {this.state.notification.tags &&
                            this.state.notification.tags.length > 0 &&
                            this.state.notification.type === 'tag' &&
                            this.state.notification.tags
                              .map(j => (
                                <Link key={j.id} to={`/tag/${j.id}`}>
                                  {j.diagri_id}
                                </Link>
                              ))
                              .reduce((prev, curr) => [prev, ', ', curr])}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Livestock ID&apos;s</b>
                        </td>
                        <td>
                          {this.state.notification.livestocks &&
                            this.state.notification.livestocks.length > 0 &&
                            this.state.notification.livestocks
                              .map(x => (
                                <Link
                                  key={x.id}
                                  to={
                                    this.state.notification.type === 'geolocation'
                                      ? `/tracking/${x.id}`
                                      : `/animal/${x.id}`
                                  }>
                                  {x.identifier}
                                </Link>
                              ))
                              .reduce((prev, curr) => [prev, ', ', curr])}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Mgmt Tag ID</b>
                        </td>
                        <td>
                          {this.state.notification.livestocks &&
                            this.state.notification.livestocks.length > 0 &&
                            this.state.notification.livestocks.map(x => x.management_tag_id).join(', ')}
                        </td>
                      </tr>

                      {this.state.notification.type === 'gateway' && (
                        <tr>
                          <td>
                            <b>Gateway ID</b>
                          </td>
                          <td>
                            {this.state.notification.gateways &&
                              this.state.notification.gateways.length > 0 &&
                              this.state.notification.gateways
                                .map(i => (
                                  <Link key={i.id} to={`/gateway/${i.id}`}>
                                    {i.identifier}
                                  </Link>
                                ))
                                .reduce((prev, curr) => [prev, ', ', curr])}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default connect(
  state => state,
  dispatch => ({
    setNotificationList: data => {
      dispatch(setNotificationList(data));
    }
  })
)(NotificationPage);
