export const TABLE_CONFIG_KEYS = {
  TASKS: 'tasks',
  NOTIFICATIONS: 'notifications',
  LIVESTOCK: 'livestock',
  LIVESTOCK_MEASURES: 'livestockMeasures',
  LIVESTOCK_BREEDING: 'livestockBreeding',
  LIVESTOCK_TREATMENTS: 'livestockTreatments',
  FARMS: 'farms',
  GEOFENCES: 'geofences',
  SITES: 'sites',
  GROUPS: 'groups',
  RULES: 'rules',
  TAGS: 'tags',
  GATEWAYS: 'gateways',
  TRACK_LIVESTOCK: 'trackLivestock'
};

export const TABLE_CONFIG_COLUMNS = {
  CONNECTED_KRAAL_TAGS: 'connectedKraalTags',
  LINKED_STATUS: 'linkedStatus',
  BATTERY_STATUS: 'batteryStatus',
  LOCATION: 'location',
  LIVESTOCK_ID: 'livestockId',
  MGMT_TAG_ID: 'mgmtTagId',
  SEX: 'sex',
  COLOUR: 'colour',
  BREED: 'breed',
  KRAAL_TAG_ID: 'kraalTagId',
  BRAND: 'brand',
  FARM: 'farm',
  ASSIGNED_GEOFENCE: 'assignedGeofence',
  LAST_READING: 'lastReading',
  CONNECTION_STATUS: 'connectionStatus',
  TITLE: 'title',
  DUE_DATE: 'dueDate',
  PRIORITY: 'priority',
  STATUS: 'status',
  ID: 'id',
  TYPE: 'type',
  RULE: 'rule',
  TRIGGERS: 'triggers',
  OBJECTS: 'objects',
  SNOOZE: 'snooze',
  LIVESTOCK_COUNT: 'livestockCount',
  NOTIFICATION_TRIGGER_DATE: 'notificationTriggerDate',
  CLEAR: 'clear',
  LIVESTOCK_TYPE: 'livestockType',
  AGE: 'age',
  WEIGHT: 'weight',
  BREEDING_STATUS: 'breedingStatus',
  CALF_ID: 'calfId',
  CALF_MGMT_TAG_ID: 'calfMgmtTagId',
  CALF_AGE: 'calfAge',
  OFFICIAL_TAG_ID: 'officialTagId',
  EAR_MARK: 'earMark',
  DOB: 'dob',
  POLLED: 'polled',
  DESCRIPTION: 'description',
  SIRE_ID: 'sireId',
  DAM_ID: 'damId',
  NUM_OF_CALVES: 'numOfCalves',
  NUM_OF_LIVESTOCK: 'numOfLivestock',
  PURCHASE_DATE: 'purchaseDate',
  PURCHASE_FROM: 'purchaseFrom',
  PURCHASE_WHERE: 'purchaseWhere',
  PURCHASE_NOTES: 'purchaseNotes',
  DATE_SOLD: 'dateSold',
  SOLD_TO: 'soldTo',
  LOSS_DATE: 'lossDate',
  LOSS_REASON: 'lossReason',
  COMMENTS: 'comments',
  MEASURE_ID: 'measureId',
  MAJOR_MEASURE_TYPE: 'majorMeasureType',
  MINOR_MEASURE_TYPE: 'minorMeasureType',
  UOM: 'uom',
  MEASUREMENT: 'measurement',
  MEASUREMENT_DATE: 'measurementDate',
  EVENT_ID: 'eventId',
  EVENT_TYPE: 'eventType',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  EXPECTED_CALVING_DATE: 'expectedCalvingDate',
  EVENT_DESCRIPTION: 'eventDescription',
  IS_PREGNANT: 'isPregnant',
  MATING_TYPE: 'matingType',
  STRAWS: 'straws',
  STRAW_REF: 'strawRef',
  COST: 'cost',
  CALVING_PERFORMANCE: 'calvingPerformance',
  MATERNITY_PERFORMANCE: 'maternityPerformance',
  BREEDING_PLAN: 'breedingPlan',
  TREATMENT_ID: 'treatmentId',
  TREATMENT_TYPE: 'treatmentType',
  TREATMENT_DATE: 'treatmentDate',
  SYMPTOMS: 'symptoms',
  IS_POSITIVE: 'isPositive',
  TREATMENT: 'treatment',
  MEDICATION: 'medication',
  DOSAGE_UOM: 'dosageUom',
  DOSAGE_AMOUNT: 'dosageAmount',
  VET: 'vet',
  REPEAT_DATE: 'repeatDate',
  REVIEW_DATE: 'reviewDate',
  RESULT: 'result',
  NAME: 'name',
  FARM_ADDRESS: 'address',
  SIZE: 'size',
  PERIMETER: 'perimeter',
  HERD: 'herd',
  ASSIGNED_LIVESTOCK: 'assignedLivestock',
  EXPECTED_TRACKED_LIVESTOCK: 'expectedTrackedLivestock',
  ACTUAL_TRACKED_LIVESTOCK: 'actualTrackedLivestock',
  NUMBER: 'number',
  SPECIE: 'specie',
  ASSIGNED_GROUPS: 'assignedGroups',
  GEOFENCE: 'geofence',
  SITE_RULES: 'siteRules'
};
