import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, Tag } from 'antd';
import dayjs from 'dayjs';

import { EDIT_COMPONENT_TYPE } from '../../constants/common';
import { ROLES } from '../../constants/role';

import { useModal } from '../../hooks/useModal';

import { fetchFarms } from '../../redux/actions/farm';
import { archiveInvitations, getInvitations, updateInvitation } from '../../redux/actions/invitations';

import AppTable from '../AppTable';
import CreateInvitationModal from '../CreateInvitationModal';

const Invitations = () => {
  const dispatch = useDispatch();
  const { invitations, loading } = useSelector(state => state.invitation);

  const [actions, setActions] = useState([]);
  const [selectedInvitations, setSelectedInvitations] = useState([]);
  const [archiveModalVisible, openArchiveModal, closeArchiveModal] = useModal();
  const [addUserModalVisible, openAddUserModal, closeAddUserModal] = useModal();

  useEffect(() => {
    dispatch(getInvitations());
    dispatch(fetchFarms());
  }, []);

  useEffect(() => {
    initActions();
  }, [selectedInvitations]);

  const initActions = useCallback(
    () =>
      setActions([
        {
          label: (
            <Button color="default" size="small" variant="link" style={{ padding: 0 }} onClick={openAddUserModal}>
              Add user
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!selectedInvitations?.length}
              style={{ padding: 0 }}
              onClick={openArchiveModal}>
              Archive account
            </Button>
          )
        }
      ]),
    [selectedInvitations]
  );

  const selectRecords = useCallback(recordIds => {
    setSelectedInvitations(recordIds);
  }, []);

  const handleUpdateInvitation = useCallback(invitations => {
    const invitation = invitations[0];

    const updatedData = {
      role: invitation.roleName?.value || invitation.role.name,
      expires_date: invitation.expires_date?.format?.('YYYY-MM-DD') || invitation.expires_date
    };

    dispatch(updateInvitation(invitation.id, updatedData));
  }, []);

  const columns = [
    {
      title: 'Email',
      render: (_, record) => record.invitee.email
    },
    {
      title: 'Farm',
      render: (_, record) => record.farm.name
    },
    {
      title: 'Role',
      dataIndex: 'roleName',
      render: (_, record) => record.role.name,
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: [ROLES.OWNER, ROLES.VETERINARY, ROLES.CONSULTANT, ROLES.EMPLOYEE],
        disabledToEdit: record => !record.is_active
      }
    },
    {
      title: 'Expiration date',
      dataIndex: 'expires_date',
      render: (_, record) => dayjs(record.expires_date).format('DD/MM/YYYY'),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.DATE_PICKER,
        disabledDate: current => current && current < dayjs().endOf('day'),
        disabledToEdit: record => !record.is_active
      }
    },
    {
      title: 'Archived',
      render: (_, record) => (record.is_active ? <Tag color="green">Active</Tag> : <Tag color="red">Archived</Tag>)
    }
  ];

  const handleArchiveInvitations = useCallback(() => {
    dispatch(archiveInvitations(selectedInvitations));
    closeArchiveModal();
  }, [selectedInvitations]);

  return (
    <>
      <AppTable
        headerClass="py-2"
        baseColumns={columns}
        dataSource={invitations}
        filterable={false}
        loading={loading}
        searchable={false}
        handleOnSelectRecords={selectRecords}
        actions={actions}
        updateTableData={handleUpdateInvitation}
      />
      <Modal
        open={archiveModalVisible}
        title="Archive a linked accounts"
        onOk={handleArchiveInvitations}
        onCancel={closeArchiveModal}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}>
        <p>Are you sure you want to archive selected linked accounts? This action cannot be undone.</p>
      </Modal>
      <CreateInvitationModal visible={addUserModalVisible} closeModal={closeAddUserModal} />
    </>
  );
};

export default memo(Invitations);
