import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import * as turf from '@turf/turf';
import { Button, Card, Flex, Form, Select, Typography } from 'antd';

import axios from '../../../axios';

import { hasPermission } from '../../../helpers/user';

import messages from '../../../constants/messages';
import { PERMISSIONS } from '../../../constants/permissions';

import { fetchFarms } from '../../../redux/actions/farm';
import { createSite, updateSite } from '../../../redux/actions/site';

import AppModal from '../../../components/AppModal';
import CancelSaveButtons from '../../../components/CancelSaveButtons';
import LoadingBar from '../../../components/LoadingBar';
import MapSite from '../../../components/Maps/MapSite';
import { DEFAULT_SHAPE_COLOR } from '../../../components/Maps/constants';
import PrevNextButtons from '../../../components/PrevNextButtons';
import { errorToastHandler, successToastHandler, warnToastHandler } from '../../../components/action_notifier';

import AppBreadcrumb from '../../ui-components/AppBreadcrumb';
import AppDropdown from '../../ui-components/AppDropdown';

import SiteForm from './SiteForm';
import SiteHistory from './SiteHistory';
import './styles.css';

const { Title } = Typography;

function Site(props) {
  const dispatch = useDispatch();
  const [farmForm] = Form.useForm();
  const formRef = useRef(null);
  const { id } = useParams();
  const userState = useSelector(state => state.user);
  const { list: farms, loading } = useSelector(state => state.farm);
  const [submittable, setSubmittable] = useState(false);
  const [state, setState] = useState({
    actions: [],
    color: DEFAULT_SHAPE_COLOR,
    delete_modal: false,
    edit: false,
    farms_org: [],
    geofences: [],
    new: id === 'new',
    query: '',
    selectedFarmGeofences: [],
    selectedGeofence: null,
    selected_farm: {},
    selected_geofences: [],
    selected_type: {},
    site_types: [],
    site: {
      geo_json: {},
      geofences: [],
      identifier: '',
      name: '',
      rules: []
    }
  });

  useEffect(() => {
    getSiteTypes();
    getFarms();
    initActions();
  }, []);

  useEffect(() => {
    if (farms?.length) {
      mapFarmsData();
      getSites(id);
    }
  }, [farms]);

  useEffect(() => {
    if (id === 'new') {
      setState(prevState => ({ ...prevState, new: true, edit: true }));
    }
  }, [id]);

  useEffect(() => {
    if (state.selected_farm) {
      setState(prevState => ({
        ...prevState,
        selectedGeofence: null
      }));
      farmForm?.setFieldsValue({
        selected_farm: state.selected_farm,
        selected_geofence: state.selectedFarmGeofences.find(geofence => geofence.is_master)
      });
    }
  }, [state.selected_farm]);

  const navigateToThePrevPage = useCallback(() => {
    props.history.push(`/site/${state.site?.prev_id}`);
  }, [state.site?.prev_id]);

  const navigateToTheNextPage = useCallback(() => {
    props.history.push(`/site/${state.site?.next_id}`);
  }, [state.site?.next_id]);

  const handleCancel = useCallback(async () => {
    if (state.new) {
      props.history.push('/map?tab=site');
    }

    if (state.edit) {
      setState(prevState => ({ ...prevState, edit: false }));
      await getSites(+id);
    }
  }, [state.edit, state.new]);

  const toggleDeleteModal = useCallback(() => {
    toggleModal('delete_modal');
  }, [state.delete_modal]);

  const setColor = useCallback(
    color => {
      setState(prevState => ({ ...prevState, color }));
    },
    [state.color]
  );

  const setSiteGeoJson = useCallback(
    geo_json => {
      setState(prevState => ({ ...prevState, site: { ...prevState.site, geo_json } }));
    },
    [state.site?.geo_json]
  );

  const setSite = useCallback(
    changedValues => {
      setState(prevState => ({ ...prevState, site: { ...prevState.site, ...changedValues } }));
    },
    [state.site]
  );

  async function getFarms() {
    try {
      dispatch(fetchFarms({ query: state.query, with_details: true }));
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
    }
  }

  function mapFarmsData() {
    if (!farms?.length) return;

    const updatedFarms = farms?.map(f => ({
      value: f.id,
      label: f.name,
      meta: {
        address: f.address.full_address,
        master_geofences: f.master_geofence,
        features: f.geofences,
        sites: f.sites
      }
    }));

    const farm = updatedFarms?.at(0);

    setState(prevState => ({
      ...prevState,
      farms: updatedFarms,
      farms_org: farms,
      geofences: farm?.meta?.features?.map(feature => ({
        label: feature.name,
        value: feature.id
      })),
      selected_farm: farm,
      selectedFarmGeofences: farm?.meta?.features?.map(feature => ({
        label: feature?.geo_json?.properties?.name,
        value: feature?.geo_json?.properties?.id,
        is_master: feature?.geo_json?.properties?.is_master
      })),
      site: {
        ...prevState.site,
        farm_id: farm.value,
        address: farm.meta.address,
        features: farm.meta.features
      }
    }));
  }

  function initActions() {
    const availableActions = [];

    if (hasPermission([PERMISSIONS.SITES.UPDATE_OWN], userState?.permissions)) {
      availableActions.push({
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            onClick={() => setState(prevState => ({ ...prevState, edit: true }))}>
            Edit site
          </Button>
        )
      });
    }

    if (hasPermission([PERMISSIONS.SITES.DELETE_OWN], userState?.permissions)) {
      availableActions.push({
        label: (
          <Button color="default" size="small" variant="link" onClick={() => toggleModal('delete_modal')}>
            Archive site
          </Button>
        )
      });
    }

    setState(prevState => ({
      ...prevState,
      actions: availableActions
    }));
  }

  async function getSites(id = null) {
    if (state.new && id === 'new') return;

    const response = await axios.get('sites/' + (id !== null ? id : id), {
      query: state.query
    });

    if (response.status === 200) {
      setState(prevState => ({
        ...prevState,
        site: response.data,
        color: response.data.color,
        selected_farm: farms?.filter(x => x.value === response.data.farm_id)[0],
        selected_geofences: response.data.geofences.map(x => ({ label: x.name, value: x.id })),
        selected_type: state.site_types.filter(x => x.value === response.data.type)
      }));
    }
  }

  async function getSiteTypes() {
    const response = await axios.get('/sites/types');

    if (response.status === 200) setState(prevState => ({ ...prevState, site_types: response.data }));
  }

  async function createNewSite(data) {
    return dispatch(createSite(data)).unwrap();
  }

  async function updateCurrentSite(data) {
    try {
      await dispatch(updateSite({ id, data })).unwrap();
      successToastHandler(messages.UPDATED);
    } catch (error) {
      errorToastHandler(messages.FAILED_ON_UPDATE_DATA);
    }
  }

  async function handleSaveSite() {
    if (state.new) {
      const boundary = state.selectedGeofence?.geo_json;

      if (boundary && !turf.booleanContains(boundary, state.site?.geo_json)) {
        warnToastHandler(messages.SHAPE_OUTSIDE_BOUNDARIES);

        return;
      }

      try {
        const response = await createNewSite({ ...state.site, color: state.color });

        setState(prevState => ({ ...prevState, new: false }));
        props.history.push(`/site/${response?.id}`);
        successToastHandler(messages.CREATED);
      } catch (error) {
        errorToastHandler(messages.FAILED_ON_CREATE_DATA('site'));
      }
    } else {
      await updateCurrentSite({ ...state.site, color: state.color });
      setState(prevState => ({ ...prevState, edit: false }));
      successToastHandler(messages.UPDATED);
      await getSites(id);
    }
  }

  async function handleSaveSiteAndAddNew() {
    if (state.new) {
      const boundary = state.selectedGeofence?.geo_json;

      if (boundary && !turf.booleanContains(boundary, state.site?.geo_json)) {
        warnToastHandler(messages.SHAPE_OUTSIDE_BOUNDARIES);

        return;
      }

      try {
        await createNewSite({ ...state.site, color: state.color });
        await resetPage();

        successToastHandler(messages.CREATED);
      } catch (error) {
        errorToastHandler(messages.FAILED_ON_CREATE_DATA('site'));
      }
    } else {
      await updateCurrentSite({ ...state.site, color: state.color });
      setState(prevState => ({ ...prevState, edit: false }));
      props.history.push('/site/new');
      successToastHandler(messages.UPDATED);
    }
  }

  async function resetPage() {
    formRef?.current?.resetFields();

    setState(prevState => ({
      ...prevState,
      color: DEFAULT_SHAPE_COLOR,
      farms_org: [],
      new: true,
      edit: false,
      selected_farm: {},
      selected_geofences: [],
      selected_type: {},
      site_types: [],
      site: {
        geo_json: null,
        geofences: [],
        identifier: '',
        name: '',
        rules: []
      }
    }));

    await getSiteTypes();
    await getFarms();
  }

  async function deleteSite() {
    const response = await axios.delete('sites/' + id);

    if (response.status === 200) props.history.push('/sites');
  }

  function toggleModal(modal) {
    setState(prevState => ({ ...prevState, [modal]: !state[modal] }));
  }

  const setSelectedFarm = selectedFarmId => {
    const selectedFarm = state.farms?.find(f => f.value === selectedFarmId);

    setState(prevState => ({
      ...prevState,
      selected_farm: selectedFarm,
      selectedFarmGeofences: selectedFarm?.meta?.features?.map(feature => ({
        label: feature?.geo_json?.properties?.name,
        value: feature?.geo_json?.properties?.id,
        is_master: feature?.geo_json?.properties?.is_master
      })),
      site: {
        ...prevState.site,
        farm_id: selectedFarm.value,
        address: selectedFarm.meta.address,
        features: selectedFarm.meta.features
      },
      editing: true
    }));
  };

  const setSelectedGeofence = selectedGeofenceId => {
    const selectedGeofence = state.selected_farm?.meta?.features?.find(f => f.id === selectedGeofenceId);

    setState(prevState => ({
      ...prevState,
      site: {
        ...prevState.site,
        geofence_ids: [selectedGeofence.id]
      },
      selectedGeofence
    }));
  };

  const breadcrumbItems = [
    {
      title: <Link to="/maps?tab=site">List of Sites</Link>
    },
    {
      title: `${
        state.edit && !state.new
          ? `Edit Site ${state.site?.name}`
          : state.new
          ? 'Create New Site'
          : `Site ${state.site?.name}`
      }`
    }
  ];

  return (
    <>
      {loading && !farms?.length && !state.selected_farm?.meta?.features?.length ? (
        <LoadingBar />
      ) : (
        <>
          <Flex gap="small" vertical={false} justify="space-between" wrap>
            <Flex vertical={true}>
              <Title level={4}>
                {state.edit && !state.new
                  ? `Edit Site ${state.site?.name}`
                  : state.new
                  ? 'Create New Site'
                  : `Site ${state.site?.name}`}
              </Title>

              <AppBreadcrumb items={breadcrumbItems} />
            </Flex>
            <Flex gap="small">
              {!state.edit && !state.new && !!state.actions?.length && (
                <AppDropdown className="float-right" label="Actions" items={state.actions} />
              )}

              {(state.edit || state.new) && (
                <CancelSaveButtons
                  disabled={!submittable || !state.site?.geo_json?.geometry}
                  handleCancel={handleCancel}
                  handleSave={handleSaveSite}
                  handleSaveAndNavigate={state.new ? handleSaveSiteAndAddNew : undefined}
                />
              )}

              {!state.new && (
                <PrevNextButtons
                  nextId={state.site?.next_id}
                  prevId={state.site?.prev_id}
                  handlePrev={navigateToThePrevPage}
                  handleNext={navigateToTheNextPage}
                />
              )}
            </Flex>
          </Flex>

          {state.new && (
            <Card>
              <Form className="farm-form" labelWrap form={farmForm} layout="horizontal" labelCol={{ span: 2 }}>
                <Flex vertical={true} gap="small">
                  <Form.Item
                    labelAlign="left"
                    label="Farm"
                    name="selected_farm"
                    colon={false}
                    style={{ width: '100%' }}>
                    <Select style={{ width: '100%' }} options={state.farms} onChange={setSelectedFarm} />
                  </Form.Item>
                  <Form.Item
                    labelAlign="left"
                    label="Geofence"
                    name="selected_geofence"
                    colon={false}
                    style={{ width: '100%' }}>
                    <Select
                      disabled={!state.selected_farm}
                      style={{ width: '100%' }}
                      options={state.selectedFarmGeofences}
                      onChange={setSelectedGeofence}
                    />
                  </Form.Item>
                </Flex>
              </Form>
            </Card>
          )}

          <MapSite
            color={state.color}
            editing={state.edit || state.new}
            farm={state.selected_farm}
            selected={state.selectedGeofence}
            features={state.site?.features}
            new={state.new || state.edit}
            site={{
              geo_json: state.site?.geo_json,
              features: state.site?.features
            }}
            handleCancelEditing={handleCancel}
            handleSaveEditing={handleSaveSite}
            onCreate={setSiteGeoJson}
            onEdit={setSiteGeoJson}
          />

          <SiteForm
            color={state.color}
            editing={state.edit}
            formRef={formRef}
            geofences={state.geofences}
            isNew={state.new}
            site={state.site}
            types={state.site_types}
            handleChangeFormValues={setSite}
            handleColorChange={setColor}
            setSubmittable={setSubmittable}
          />

          {(state.edit || state.new) && (
            <Flex justify="end" className="mb-2">
              <CancelSaveButtons
                disabled={!submittable || !state.site?.geo_json?.geometry}
                handleCancel={handleCancel}
                handleSave={handleSaveSite}
                handleSaveAndNavigate={state.new ? handleSaveSiteAndAddNew : undefined}
              />
            </Flex>
          )}

          {id !== 'new' && <SiteHistory siteId={id} />}
        </>
      )}

      <AppModal
        isOpen={state.delete_modal}
        confirmButtonColor="danger"
        confirmButtonText="Archive"
        title="Archive site"
        handleCancel={toggleDeleteModal}
        handleConfirm={deleteSite}>
        <div className="py-4">Are you sure you want to archive this site? This action cannot be undone.</div>
      </AppModal>
    </>
  );
}

export default memo(Site);
