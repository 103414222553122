import { memo, useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';
import { useSelector } from 'react-redux';

import { Card, ConfigProvider, Flex, Form, Input, InputNumber, Select } from 'antd';
import classnames from 'classnames';

import { capitalize } from '../../../helpers/common';
import { isFieldAvailable } from '../../../helpers/subscription';

import { FEATURE_LIST } from '../../../constants/subscription';

import { CREATE_SITE_BLACKLIST, FIELD_TYPE } from './constants';
import { SITE_FORM_FIELDS } from './constants';
import './styles.css';

const { TextArea } = Input;

function filterObjectsById(identifiers, objects) {
  return objects.filter(obj => !identifiers.includes(obj.id));
}

function SiteForm({
  color,
  editing,
  formRef,
  geofences,
  isNew,
  site,
  types,
  handleChangeFormValues,
  handleColorChange,
  setSubmittable
}) {
  if (!site) return;
  const { myPlan } = useSelector(state => state.subscription);
  const [form] = Form.useForm();
  const [currentColor, setCurrentColor] = useState();

  if (formRef) formRef.current = form;

  const values = Form.useWatch([], form);

  const availableFields = isNew ? filterObjectsById(CREATE_SITE_BLACKLIST, SITE_FORM_FIELDS) : SITE_FORM_FIELDS;
  const formattedOrder = availableFields.filter(field =>
    isFieldAvailable(field.id, myPlan?.type, FEATURE_LIST.SITE_DETAILS_FIELDS)
  );

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [values]);

  useEffect(() => {
    if (color) setCurrentColor(color);
  }, [color]);

  useEffect(() => {
    if (site && !editing) {
      form.setFieldsValue(prepareFormValues());
    }
  }, [site, editing]);

  function prepareFormValues() {
    const initValues = {};

    for (let field of formattedOrder) {
      let value;

      switch (field.id) {
        case 'geofence_ids':
          value = site.geofences?.map(x => ({ name: x.name, value: x.id }));
          break;
        case 'rules':
          value = site.rules?.map(x => x.name).join(', ') || 'No rules';
          break;
        case 'type':
          value = capitalize(site.type) || '';
          break;
        default:
          value = site?.[field.id] ? site?.[field.id] : field.type === FIELD_TYPE.NUMBER ? 0 : '';
      }

      initValues[field.id] = value;
    }

    return initValues;
  }

  function changeCurrentColor({ hex }) {
    setCurrentColor(hex);
    handleColorChange(hex);
  }

  function handleChange(changedValues) {
    if (!changedValues?.color) handleChangeFormValues(changedValues);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextDisabled: 'rgba(0,0,0,0.88)'
        },
        components: {
          Select: {
            multipleSelectorBgDisabled: 'transparent',
            multipleItemBg: 'white'
          }
        }
      }}>
      <Card title={`Site ${site.name} Details`} className="mb-2">
        <Form
          className="site-edit-form"
          name="site"
          form={form}
          labelCol={{ span: 5 }}
          requiredMark={false}
          autoComplete="off"
          onValuesChange={handleChange}>
          <Flex vertical={true} gap="small">
            {formattedOrder?.map(field => {
              return (
                <Form.Item
                  labelWrap
                  key={field.id}
                  label={field.name}
                  name={field.id}
                  colon={false}
                  labelAlign="left"
                  rules={field.rules}>
                  {field.type === FIELD_TYPE.SELECT ? (
                    <Select
                      className={classnames('select', { 'hidden-arrow': (!editing || !field.editable) && !isNew })}
                      disabled={(!editing || !field.editable) && !isNew}
                      mode={field.mode}
                      options={field.id === 'type' ? types : geofences}
                      variant={(editing || isNew) && field.editable ? 'outlined' : 'borderless'}
                    />
                  ) : field.type === FIELD_TYPE.TEXTAREA ? (
                    <TextArea
                      className="textarea"
                      disabled={!editing && !isNew}
                      variant={editing || isNew ? 'outlined' : 'borderless'}
                    />
                  ) : field.type === FIELD_TYPE.NUMBER ? (
                    <InputNumber
                      changeOnWheel
                      className="full-width"
                      disabled={(!editing || !field.editable) && !isNew}
                      min={0}
                      variant={(editing || isNew) && field.editable ? 'outlined' : 'borderless'}
                    />
                  ) : (
                    <Input
                      disabled={(!editing || !field.editable) && !isNew}
                      variant={(editing || isNew) && field.editable ? 'outlined' : 'borderless'}
                    />
                  )}
                </Form.Item>
              );
            })}

            {(editing || isNew) && (
              <Form.Item label="Geofence Color" labelAlign="left" name="color" colon={false}>
                <CompactPicker name="colour" color={currentColor} onChange={changeCurrentColor} />
              </Form.Item>
            )}
          </Flex>
        </Form>
      </Card>
    </ConfigProvider>
  );
}

export default memo(SiteForm);
