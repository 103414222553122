import { memo } from 'react';
import { useSelector } from 'react-redux';

import { SUBSCRIPTION_FEATURES } from '../../configs/subscriptions';

const FeatureProvider = ({ children, name }) => {
  const userState = useSelector(state => state.user);
  const subscriptionState = useSelector(state => state.subscription);
  const myFeatureGroups = subscriptionState?.myFeatureGroups;

  const isFeatureAvailable = () => {
    return userState.isAdmin ? true : myFeatureGroups.some(group => !!SUBSCRIPTION_FEATURES?.[group]?.includes(name));
  };

  return isFeatureAvailable() ? children : null;
};

export default memo(FeatureProvider);
