import axios from '../../axios';

const path = 'farms';

const service = {
  async createFarm(data) {
    return axios({ method: 'post', url: path, data });
  },

  async fetchFarms(params) {
    return axios({ method: 'get', url: path, params });
  },

  async fetchFarm(id) {
    return axios({ method: 'get', url: `/${path}/${id}` });
  },

  async updateFarm(id, data) {
    return axios({ method: 'put', url: `/${path}/${id}`, data });
  },

  async deleteFarms(ids) {
    return axios({ method: 'delete', url: `${path}/bulk`, data: { ids } });
  },

  async deleteFarm(id) {
    return axios({ method: 'delete', url: `${path}/${id}` });
  }
};

export default service;
