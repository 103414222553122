export default {
  DEFAULT_ERROR: 'Something went wrong!',
  FAILED_ON_FETCH_DATA: 'Failed to fetch data. Please, try later.',
  FAILED_ON_CREATE_DATA: entity => `Failed to create ${entity}. Please, try later.`,
  FAILED_ON_DELETE_DATA: 'Failed to delete data. Please, try later.',
  FAILED_ON_UPDATE_DATA: 'Failed to update data. Please, try later.',
  FAILED_ON_DOWNLOAD_CSV: 'Failed to download CSV file. Please, try later.',
  FILL_REQUIRED_FIELDS: 'Please fill the required fields!',
  FILL_MANDATORY_FIELDS: 'Please fill all mandatory fields!',
  FIELD_REQUIRED: name => `${name} is required`,
  NEW_LIVESTOCK_FOR_CALF_RECORD: 'New Livestock Record for calf created',
  NEW_BREEDING_EVENT_BIRTH_FOR_CALF: 'New Breeding event - Birth for calf created',
  NEW_MEASUREMENT_EVENT_CALVING_WEIGHT_FOR_CALF: 'New Measurement event - Calving weight for calf created',
  NEW_MEASUREMENT_EVENT_POST_CALVING_WEIGHT_FOR_CALF: 'New Measurement event - Post calving weight for calf created',
  NEW_MEASUREMENT_EVENT_CALVING_EASE_FOR_COW: 'New Measurement event - Calving Ease for cow created',
  NEW_TREATMENT_FOR_COW: 'New Treatment event for cow created',
  NEW_TREATMENT_FOR_CALF: 'New Treatment event for calf created',

  SELECTED_ITEM_REQUIRED: 'Please select at least one record.',

  EVENT_TITLE_REQUIRED: 'Title is required',
  EVENT_START_DATE_REQUIRE: 'Start date is required',
  EVENT_END_DATE_REQUIRED: 'End date is required',
  EVENT_PROVIDE_RELATION: 'Provide please relation',
  EVENT_PROVIDE_RECURRING_DATE: 'Provide please recurring date',
  EVENT_START_DATE_AFTER_END_DATE: "Start date can't be after end date",
  EVENT_REMIND_DATE_IN_PAST: "Remind date can't be in the past",
  EVENT_REMIND_DATE_AFTER_START_DATE: "Remind date can't be after start date",

  TASK_PROVIDE_RELATION: 'Provide please relation',
  TASK_PROVIDE_RECURRING_DATE: 'Provide please recurring date',
  TASK_REMIND_DATE_IN_PAST: "Remind date can't be in the past",
  TASK_REMIND_DATE_AFTER_DUE_DATE: "Remind date can't be after due date",

  ACCEPT_TERMS_AND_CONDITIONS: 'You can only proceed if you accept the terms and conditions. Your account is archived.',
  ADD_USER_EMAIL_REQUIRED: 'Email is required',
  ADD_USER_ROLE_REQUIRED: 'Role is required',

  ANIMAL_UNLINK_TAG_REQUIRED: 'Please unlink the Kraal Tag before making this change',
  ANIMAL_FARM_REQUIRED: 'No Farm exists. Please create at least one Farm to add Livestock.',
  MASTER_GEOFENCE_REQUIRED: 'You need to add a master geofence',

  MEASUREMENT_NOT_FOUND: 'No existing measurement record was found to edit. Please add a new measurement record.',

  NOTIFICATIONS_LIMIT: 'Only the most recent 100 records will be listed',
  NO_LOCATION_DATA_AVAILABLE_FOR_TIME_FRAME: 'No location data is available for the selected time frame',
  PARTIAL_LOCATION_DATA_AVAILABLE_FOR_PART_TIME: 'Only partial location data is available for the selected time frame',

  ARCHIVED: 'Archived successfully!',
  CREATED: 'Created successfully!',
  UPDATED: 'Updated successfully!',

  SET_PASSWORD_EMAIL_SENT: 'Set password email sent',
  SET_PASSWORD_EMAIL_FAILED: (failedEmails = []) => `Set password email was not sent for ${failedEmails.join(', ')}.`,

  DATA_REQUIRED: 'Please enter all the details before saving.',
  SHAPE_OUTSIDE_BOUNDARIES: 'You have created or placed an object outside the farm geofence.'
};
