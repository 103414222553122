import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from '../../axios';

import { TRIAL_PLAN } from '../../constants/subscription';

import { getMyPlan } from '../../redux/actions/subscription';
import { subscribeUser, updateSubscription } from '../../redux/actions/user';

import ConfirmationModal from '../Modals/ConfirmationModal';
import SubmitSubscriptionModal from '../Modals/SubmitSubscriptionModal';
import ButtonRow from './ButtonRow';
import FeatureRow from './FeatureRow';
import PlanRow from './PlanRow';
import './styles.scss';

const SubscriptionTable = () => {
  const dispatch = useDispatch();
  const subscriptionState = useSelector(state => state.subscription);

  const [selectedPlan, setSelectedPlan] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpenSubmitSubscriptionModal, setOpenSubmitSubscriptionModal] = useState(false);
  const [isOpenConfirmationModal, setOpenConfirmationModal] = useState(false);

  const onSelectPlan = useCallback(
    async (plan, isUpdate) => {
      let actualPlan = plan;
      if (subscriptionState?.myPlan.status && plan.id === subscriptionState?.myPlan.id) {
        if (subscriptionState?.myPlan.status === 'active' || subscriptionState?.myPlan.status === 'inactive') {
          actualPlan = subscriptionState?.myPlan;
        }

        if (subscriptionState?.myPlan.status === 'pending') {
          const resp = await axios.get(`subscription/user/${subscriptionState?.myPlan?.user_plan_id}`);
          actualPlan = resp?.data?.plan;
        }
      }

      setSelectedPlan(actualPlan);
      setIsUpdate(isUpdate);
      if (plan.type === TRIAL_PLAN) {
        setOpenConfirmationModal(true);
        return;
      }
      setOpenSubmitSubscriptionModal(true);
    },
    [subscriptionState]
  );

  const onCancelSubmitSubscriptionModal = useCallback(() => {
    setOpenSubmitSubscriptionModal(false);
  }, []);

  const onSubmitSubscriptionModal = useCallback(
    async (period = 1, limits, isUpdate) => {
      const { feature_options } = selectedPlan;
      let payload = {};

      if (selectedPlan.type === TRIAL_PLAN) {
        payload = {
          period: 1,
          limits: [],
          plan_id: selectedPlan.id
        };
      } else {
        const limitOptions = Object.keys(limits).map(limitOption => {
          const featureOption = feature_options.find(item => {
            return item.type === limitOption;
          });
          return {
            feature_option_id: featureOption.id,
            count: limits[limitOption]
          };
        });
        payload = {
          period,
          limits: limitOptions,
          plan_id: selectedPlan.id
        };
      }

      if (isUpdate) {
        await dispatch(updateSubscription(payload));
      } else {
        await dispatch(subscribeUser(payload));
      }

      setOpenSubmitSubscriptionModal(false);
      setOpenConfirmationModal(false);
      setIsUpdate(false);
      await dispatch(getMyPlan());
    },
    [selectedPlan]
  );
  return (
    <div className="subscription-table">
      <PlanRow />

      {subscriptionState?.featureOptions.map((feature, index) => {
        return <FeatureRow feature={feature} key={feature.title + index} />;
      })}

      <ButtonRow onSelect={onSelectPlan} />

      <SubmitSubscriptionModal
        isUpdate={isUpdate}
        isOpen={isOpenSubmitSubscriptionModal}
        onCancel={onCancelSubmitSubscriptionModal}
        onSubmit={onSubmitSubscriptionModal}
        plan={selectedPlan}
      />

      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        onCancel={() => setOpenConfirmationModal(false)}
        title={'Trial plan'}
        description={'Are you sure you want to request trial plan for 1 month?'}
        onSubmit={onSubmitSubscriptionModal}
        submitText={'Request'}
        submitButtonType={'primary'}
      />
    </div>
  );
};

export default memo(SubscriptionTable);
