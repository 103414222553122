import { memo } from 'react';
import { Tooltip } from 'react-leaflet';

import { Typography } from 'antd';

const { Text } = Typography;

function MapTrackingFeatureTooltip({ data }) {
  return (
    <Tooltip>
      {data?.length &&
        data.map(d => {
          if (d.value) {
            return (
              <div key={d.id}>
                <Text>
                  <Text strong className="mr-2">
                    {d.title}:
                  </Text>
                  {d.value}
                </Text>
              </div>
            );
          }
        })}
    </Tooltip>
  );
}

export default memo(MapTrackingFeatureTooltip);
