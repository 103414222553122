import axios from '../../axios';

const service = {
  async getUser() {
    return await axios({
      method: 'get',
      url: '/users/me'
    });
  },

  async archiveUsers(payload) {
    return await axios({
      method: 'delete',
      url: '/users/archive',
      data: payload
    });
  },

  async deleteUsers(payload) {
    return await axios({
      method: 'delete',
      url: '/users',
      data: payload
    });
  },

  async getAllUsers() {
    return await axios({
      method: 'get',
      url: '/users/allUsers'
    });
  },

  async getUsers(params) {
    return await axios({
      method: 'get',
      url: '/users',
      params: params
    });
  },

  async createNewUser(payload) {
    return await axios({
      method: 'post',
      url: '/users/createNew',
      data: payload
    });
  },

  async sendSetPassword(payload) {
    return await axios({
      method: 'post',
      url: '/users/sendSetPassword',
      data: payload
    });
  },

  async contactUs(payload) {
    return await axios({
      method: 'post',
      url: '/users/contactUs',
      data: payload
    });
  },

  async subscribe(payload) {
    return await axios({
      method: 'post',
      url: '/subscription/user',
      data: payload
    });
  },

  async updateSubscription(payload) {
    return await axios({
      method: 'put',
      url: '/subscription/user',
      data: payload
    });
  },

  getTablePreferences() {
    return axios.get('/users/me/table-preferences');
  },

  updateTablePreferences(tableId, columns) {
    return axios.put(`/users/me/table-preferences/${tableId}`, { columns });
  },

  resetTablePreferences(tableId) {
    return axios.delete(`/users/me/table-preferences/${tableId}`);
  }
};

export default service;
