import { createSlice } from '@reduxjs/toolkit';

import { createFarm, deleteFarm, deleteFarms, fetchFarm, fetchFarms, updateFarm } from '../actions/farm';

const farmSlice = createSlice({
  name: 'farm',
  initialState: {
    error: null,
    farm: null,
    list: [],
    loading: false,
    mapData: {},
    options: [],
    selectedFilters: {}
  },
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setMapData: (state, action) => {
      state.mapData = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      // Create Farm
      .addCase(createFarm.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createFarm.fulfilled, (state, action) => {
        state.loading = false;
        state.farm = action.payload;
      })
      .addCase(createFarm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch Farms
      .addCase(fetchFarms.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFarms.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.list;
        state.options = action.payload.options;
        state.filterOptions = action.payload.filterOptions;
      })
      .addCase(fetchFarms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch Farm
      .addCase(fetchFarm.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFarm.fulfilled, (state, action) => {
        state.loading = false;
        state.farm = action.payload;
      })
      .addCase(fetchFarm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update Farm
      .addCase(updateFarm.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFarm.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateFarm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete Farms
      .addCase(deleteFarms.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFarms.fulfilled, (state, action) => {
        state.loading = false;
        state.list = state.list.filter(record => !action.payload?.includes(record.id));
      })
      .addCase(deleteFarms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete Farm
      .addCase(deleteFarm.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFarm.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteFarm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setSelectedFilters, setMapData } = farmSlice.actions;
export default farmSlice.reducer;
