import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Card, List, Typography } from 'antd';

import { numberWithCommas } from '../../../helpers/common';

import { SUBSCRIPTIONS } from '../../../constants/subscription';

import './styles.css';

const { Text } = Typography;

function GeofenceDetails({ geofence, description }) {
  const { myPlan } = useSelector(state => state.subscription);
  const geofenceArray = Array.isArray(geofence) ? geofence : [geofence];

  return (
    <Card title={`Geofence ${geofence.name} Details`} className="mb-2">
      <List
        className="details"
        size="small"
        dataSource={geofenceArray}
        renderItem={item => (
          <>
            <List.Item>
              <Text strong className="item-name">
                Geofence ID
              </Text>
              {item.identifier}
            </List.Item>
            <List.Item>
              <Text strong className="item-name">
                Geofence Name
              </Text>
              {item.name}
            </List.Item>
            <List.Item>
              <Text strong className="item-name">
                Geofence Size (ha)
              </Text>
              {numberWithCommas(Number(geofence?.size).toFixed(2))}
            </List.Item>
            <List.Item>
              <Text strong className="item-name">
                Geofence Perimeter (km)
              </Text>
              {numberWithCommas(Number(geofence?.circumference).toFixed(2))}
            </List.Item>

            {!SUBSCRIPTIONS.includes(myPlan.type) && (
              <>
                <List.Item>
                  <Text strong className="item-name">
                    Assigned Livestock
                  </Text>
                  {numberWithCommas(geofence?.active_livestock_count)}
                </List.Item>
                <List.Item>
                  <Text strong className="item-name">
                    Expected Tracked Livestock
                  </Text>
                  {numberWithCommas(geofence?.livestock_with_tag)}
                </List.Item>
                <List.Item>
                  <Text strong className="item-name">
                    Actual Tracked Livestock
                  </Text>
                  {numberWithCommas(geofence?.actual_livestock)}
                </List.Item>
              </>
            )}

            <List.Item>
              <Text strong className="item-name">
                Assigned Groups
              </Text>
              <Text>
                {geofence?.labels?.map((l, i) => (
                  <span key={l.id}>
                    {i !== 0 ? <span>{', '}</span> : null}
                    <Link to={`/group/${l.id}`}>{l.name}</Link>
                  </span>
                ))}
              </Text>
            </List.Item>
            <List.Item>
              <Text strong className="item-name">
                Geofence Description
              </Text>
              {description}
            </List.Item>
          </>
        )}
      />
    </Card>
  );
}

export default memo(GeofenceDetails);
