import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col, Modal, ModalBody, ModalFooter, Row, Table } from 'reactstrap';

import { Button, Flex, Typography } from 'antd';
import moment from 'moment';

import axios from '../../../axios';

import { hasPermission } from '../../../helpers/user';

import { DATE_FORMAT } from '../../../constants/common';
import { PERMISSIONS } from '../../../constants/permissions';

import PrevNextButtons from '../../../components/PrevNextButtons';
import Priority from '../../../components/PriorityTag';
import RuleSnoozeIcon from '../../../components/RuleSnoozeIcon';

import AppBreadcrumb from '../../ui-components/AppBreadcrumb';
import AppDropdown from '../../ui-components/AppDropdown';

import RuleHistory from './RuleHistory';

const { Title } = Typography;

class Rule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      alert_modal: false,
      delete_modal: false,
      dropdownOpen: false,
      modal_message: '',
      modal_title: '',
      modal_type: '',
      rule: {}
    };

    this.archiveRule = this.archiveRule.bind(this);
    this.editRule = this.editRule.bind(this);
    this.generateDefinition = this.generateDefinition.bind(this);
    this.getRule = this.getRule.bind(this);
    this.initActions = this.initActions.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    this.initActions();
    await this.getRule();
  }

  initActions() {
    console.log('INIT');
    const { rule } = this.state;

    this.setState({
      actions: [
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={rule.is_snoozed && !hasPermission([PERMISSIONS.RULES.UPDATE_OWN], this.props.user.permissions)}
              onClick={() => this.toggleModal('delete_modal', 'Snooze')}>
              Snooze rule
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={rule.is_snoozed && !hasPermission([PERMISSIONS.RULES.UPDATE_OWN], this.props.user.permissions)}
              onClick={() => this.toggleModal('delete_modal', 'Unsnooze')}>
              Unsnooze rule
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!hasPermission([PERMISSIONS.RULES.UPDATE_OWN], this.props.user.permissions)}
              onClick={() => this.editRule()}>
              Edit rule
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!hasPermission([PERMISSIONS.RULES.UPDATE_OWN], this.props.user.permissions)}
              onClick={() => this.archiveRule()}>
              Archive rule
            </Button>
          )
        }
      ]
    });
  }

  async getRule() {
    return new Promise(async resolve => {
      const response = await axios.get('rules/' + this.props.match.params.id, {
        query: this.state.query
      });

      if (response.status === 200) {
        response.data.isDefault = response.data.default_rule === 1;
        this.setState({ rule: response.data }, () => this.generateDefinition());

        return resolve();
      }
    });
  }

  editRule() {
    if (this.state.rule.isDefault) {
      const modal = 'alert_modal';
      const type = modal;
      const title = 'Alert';
      const message = 'This is a default system Rule which can not be edited or archived.';

      this.setState(state => ({
        ...state,
        [modal]: !state[modal],
        modal_type: type,
        modal_title: title,
        modal_message: message
      }));
    } else {
      this.props.history.push('/rules/builder/' + this.props.match.params.id);
    }
  }

  toggleModal(modal, type) {
    let title = '';
    let message = '';

    if (type === 'Archive') {
      title = 'Archive rule';
      message = 'Are you sure you want to archive this rule? This action cannot be undone.';
    } else if (type === 'Snooze') {
      title = 'Snooze rule';
      message = 'Are you sure you want to snooze this rule?';
    } else if (type === 'Alert') {
      title = 'Alert';
      message = 'This is a default system Rule which can not be edited or archived.';
    } else {
      title = 'Unsnooze rule';
      message = 'Are you sure you want to unsnooze this rule?';
    }

    this.setState(state => ({
      ...state,
      [modal]: !state[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  }

  generateDefinition() {
    const def = [];
    const data = this.state.rule.data;
    const type = this.state.rule.type.value || this.state.rule.type;
    const default_rule = this.state.rule.default_rule;
    const not_defined = <span>{'? '}</span>;

    if (type === 'intermediate_health') {
      def.push(<span>Any animal moves less than </span>);

      if (data.variables) {
        if (data.variables.percentage) {
          def.push(
            <span>
              <span className="bolded">{data.variables.percentage}%</span>
              {' of '}
            </span>
          );
        }

        if (data.variables.calculated_value) {
          def.push(
            <span>
              <span className="bolded">{data.variables.calculated_value.value}</span>{' '}
            </span>
          );
        }
      }

      if (data.action) {
        def.push(
          <span>
            {'. If rule breached '}
            {data.action.label}{' '}
          </span>
        );
      } else {
        def.push(not_defined);
      }

      this.setState({ rule: { ...this.state.rule, definition: def } });

      return;
    }

    if ((type === 'gateway_disconnected' || type === 'tag_disconnected') && default_rule === 1) {
      const selector = data.selector ? data.selector.label : '';
      const condition = data.condition ? data.condition.label : '';
      const action = data.action ? '. If rule breached ' + data.action.label : '';

      this.setState({ rule: { ...this.state.rule, definition: selector + ' ' + condition + action } });

      return;
    }

    if (data.selector) {
      def.push(
        <span>
          <span className="bolded">{data.selector.label}</span>
          {' livestock labeled '}
        </span>
      );
    } else {
      def.push(not_defined);
    }

    if (data.anchor) {
      def.push(
        <span>
          <span className="bolded">{data.anchor.label}</span>{' '}
        </span>
      );
    } else {
      def.push(not_defined);
    }

    if (data.condition) {
      def.push(<span>{data.condition.label} </span>);
    } else {
      def.push(not_defined);
    }

    if (type === 'site_proximity' || type === 'livestock_proximity' || type === 'basic_health') {
      if (data.variables) {
        if (data.variables.distance) {
          def.push(
            <span>
              <span className="bolded">{data.variables.distance} </span>
            </span>
          );
        } else {
          def.push(not_defined);
        }

        if (data.variables.distance_unit) {
          def.push(
            <span>
              <span className="bolded">
                {data.variables.distance_unit.label} {type !== 'basic_health' && ' of '}
              </span>
            </span>
          );
        } else {
          def.push(not_defined);
        }

        if (type === 'basic_health') {
          if (data.variables.timeframe) {
            def.push(
              <span>
                <span className="bolded">{data.variables.timeframe.label}</span>
              </span>
            );
          } else {
            def.push(not_defined);
          }
        }
      }
    }

    if (data.target) {
      def.push(
        <span>
          <span className="bolded">{data.target.label} </span>
        </span>
      );
    }

    if (type === 'site_frequency') {
      if (data.variables) {
        if (data.variables.frequency) {
          def.push(
            <span>
              <span className="bolded">{data.variables.frequency} </span> {' times '}{' '}
            </span>
          );
        } else {
          def.push(not_defined);
        }

        if (data.variables.timeframe) {
          def.push(
            <span>
              <span className="bolded">{data.variables.timeframe.label}</span>
            </span>
          );
        } else {
          def.push(not_defined);
        }
      }
    }

    if (data.action) {
      def.push(
        <span>
          {'. If rule breached '}
          {data.action.label}{' '}
        </span>
      );
    } else {
      def.push(not_defined);
    }

    this.setState({ rule: { ...this.state.rule, definition: def } });
  }

  archiveRule() {
    if (this.state.rule.isDefault) {
      this.toggleModal('alert_modal', 'Alert');
    } else {
      this.toggleModal('delete_modal', 'Archive');
    }
  }

  async deleteRule() {
    let response = {};

    if (this.state.modal_type === 'Archive') {
      response = await axios.delete('rules/' + this.props.match.params.id, {
        data: { force: true }
      });
    } else if (this.state.modal_type === 'Snooze') {
      response = await axios.put(`rules/${this.props.match.params.id}/snooze`, {
        seconds: 86400
      });
    } else {
      response = await axios.put(`rules/${this.props.match.params.id}/snooze`, {
        seconds: -86400
      });
    }

    if (response.status && response.status === 200) {
      this.props.history.push('/rules');
    }
  }

  render() {
    if (this.state.rule.id === null) return null;

    const rulePriority = {
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      priority: 'High',
      normal: 'Medium',
      record: 'Low'
    };

    const breadcrumbItems = [
      {
        title: <Link to="/rules">List of Rules</Link>
      },
      {
        title: `${this.state.rule?.name ? `Rule ${this.state.rule.name}` : 'Rule'}`
      }
    ];

    return (
      <>
        <Flex gap="small" vertical={false} justify="space-between" wrap>
          <Flex vertical={true}>
            <Title level={4}>Rule {this.state.rule.name}</Title>

            <AppBreadcrumb items={breadcrumbItems} />
          </Flex>
          <Flex gap="small">
            {this.state.actions?.length > 0 && <AppDropdown label="Actions" items={this.state.actions} />}

            <PrevNextButtons
              nextId={this.state.rule.next_id}
              prevId={this.state.rule.prev_id}
              handlePrev={() => this.props.history.push(`/rule/${this.state.rule.prev_id}`)}
              handleNext={() => this.props.history.push(`/rule/${this.state.rule.next_id}`)}
            />
          </Flex>
        </Flex>

        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row className="pad-15 global-heading-tag">
                <Col xs="12" md="12" lg="6">
                  <h4>Rule {this.state.rule.name || this.state.rule.identifier} details</h4>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Rule ID</b>
                        </td>
                        <td>
                          {this.state.rule.identifier} {this.state.rule.is_snoozed && <RuleSnoozeIcon></RuleSnoozeIcon>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Name</b>
                        </td>
                        <td>{this.state.rule.name}</td>
                      </tr>
                      {this.state.rule.is_snoozed && (
                        <tr>
                          <td>
                            <b>Rule Snoozed Until</b>
                          </td>
                          <td>{moment(this.state.rule.unsnooze_at).format(DATE_FORMAT.DATETIME)}</td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <b>Rule Type</b>
                        </td>
                        <td>{this.state.rule.type_display_name}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Priority</b>
                        </td>
                        <td>
                          {this.state.rule.data && this.state.rule.data.action && (
                            <Priority priority={rulePriority[this.state.rule.data.action.value]} />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Status</b>
                        </td>
                        <td>{this.state.rule.is_snoozed ? 'Snoozed' : 'Active'}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Created</b>
                        </td>
                        <td>{moment(this.state.rule.created_at).format(DATE_FORMAT.DATETIME)}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Definition</b>
                        </td>
                        <td>{this.state.rule.definition}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Action</b>
                        </td>
                        <td className="capitalize">
                          {this.state.rule.data && this.state.rule.data.action && (
                            <>{this.state.rule.data.action.label}</>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Objects</b>
                        </td>
                        <td>
                          {this.state.rule.objects_count} (Livestock: {this.state.rule.animals_count}, Sites:{' '}
                          {this.state.rule.sites_count}, Geofences: {this.state.rule.geofences_count}, Gateways:{' '}
                          {this.state.rule.gateway_count}, Tags: {this.state.rule.tag_count})
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Triggers</b>
                        </td>
                        <td>{this.state.rule.triggers_count}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>User Actions</b>
                        </td>
                        <td>
                          <>
                            Snoozed on{' '}
                            {this.state.rule.snooze_history &&
                              this.state.rule.snooze_history
                                .map(x => moment(x.created_at).format(DATE_FORMAT.DATETIME))
                                .join(', ')}
                            <br></br>
                          </>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Description</b>
                        </td>
                        <td>{this.state.rule.description}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Linked groups</strong>
                        </td>
                        <td>
                          {this.state.rule?.labels?.map((item, index) => {
                            let link = '';
                            if (index > 0) {
                              link = (
                                <>
                                  ,{' '}
                                  <Link key={item.identifier} to={`/group/${item.id}`}>
                                    {item.identifier}
                                  </Link>
                                </>
                              );
                            } else
                              link = (
                                <Link key={item.identifier} to={`/group/${item.id}`}>
                                  {item.identifier}
                                </Link>
                              );
                            return link;
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <RuleHistory ruleId={this.props.match.params.id} />

        <Modal isOpen={this.state.delete_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>{this.state.modal_title}</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.modal_message}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('delete_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteRule()}>
              {this.state.modal_type}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.alert_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>{this.state.modal_title}</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.modal_message}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('alert_modal')}>
              Close
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default connect(state => state)(Rule);
