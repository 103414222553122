import React, { memo } from 'react';
import { Card, Col, Row } from 'reactstrap';

import { Typography } from 'antd';

const { Title } = Typography;

const LayoutTemplate = ({ title, headerOptions, content }) => {
  return (
    <div className={'layout-template'}>
      <Row>
        <Col xs="12" md="12" lg="12">
          <Card>
            <Row className="pad-10">
              <Col xs="12" md="12" lg="6">
                <Row>
                  <Col xs="12" md="12" lg="6" className="listing-heading">
                    <Title level={4}>{title}</Title>
                  </Col>

                  <Col xs="12" md="12" lg="6"></Col>
                </Row>
              </Col>
              <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
                {headerOptions}
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={'12'} lg="12" className="content-block">
                {content}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default memo(LayoutTemplate);
