import React, { memo } from 'react';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';

import AppModal from '../../../components/AppModal';

const ChooseGroupsModal = ({ isOpen, onCancel, handleGroupsChange, onSave, values = [], options = [] }) => {
  return (
    <AppModal isOpen={isOpen} confirmButtonText="Save" title="Groups" handleCancel={onCancel} handleConfirm={onSave}>
      <div className="py-4">
        <FormGroup>
          <Select value={values} onChange={handleGroupsChange} options={options} isMulti={true} isSearchable={true} />
        </FormGroup>
      </div>
    </AppModal>
  );
};

export default memo(ChooseGroupsModal);
